import { parse, isValid, isAfter, isBefore } from 'date-fns';

export function validarData(dataStr) {
  try {
    // Primeiro, vamos garantir que a data esteja no formato correto
    const [day, month, year] = dataStr.split('/').map(Number);

    // Verifica se o mês é maior que 12 ou se o dia é maior que 31
    if (month > 12 || day > 31) {
      return false;
    }

    // Formata a data para o formato ISO (yyyy-mm-dd)
    const dataFormatada = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

    // Converte a data formatada para um objeto Date
    const data = parse(dataFormatada, 'yyyy-MM-dd', new Date());
    const dataAtual = new Date();
    const dataMinima = new Date('1900-01-01');

    // Valida a data
    return isValid(data) && !isAfter(data, dataAtual) && !isBefore(data, dataMinima);
  } catch (error) {

    return false;
  }
}


export function validarTelefone(telefone) {
  const numeroLimpo = telefone.replace(/\D/g, '');
  return numeroLimpo.length === 11 || numeroLimpo.length === 11;
}

export function validarCPF(cpf) {
  const numeroLimpo = cpf.replace(/[^\d]/g, '');
  return numeroLimpo.length === 11;
}
