import styled from 'styled-components';
import { Button, Input } from 'reactstrap';


export const LogoImage = styled.img`
  position: relative; /* Logo fixa na parte inferior */
  top: 10px; /* Distância da parte inferior */
  width: 220px; /* Tamanho inicial da logo */

  /* Responsividade para telas menores */
  @media (max-width: 768px) {
    width: 180px; /* Ajusta o tamanho da logo para telas menores */
  }

  @media (max-width: 480px) {
    width: 150px; /* Ajusta o tamanho da logo para telas muito pequenas */
  }
`;

export const ButtonLogin = styled(Button)`
  width: auto; /* Ajustar a largura para ser automática */
  padding: 10px 25px;
  border-radius: 40px;
  border: none;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 20px;
  height: 50px;
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  position: relative;
  margin-top: 20px; /* Espaço entre o botão e os campos de entrada */
    @media (max-width: 480px) {
font-weight: 600;
  font-size: 18px;
  }
`;

export const InputLogin = styled(Input)`
  height: 50px;
  width: 100%;
  border-radius: 40px;
  text-align: left;
  padding: 10px 25px;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;
`;
