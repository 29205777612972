import React, { useState, useEffect, useCallback } from 'react'; 
import { Form, FormGroup, Spinner } from 'reactstrap';
import { ButtonLogin, InputLogin, RegisterLink, ForgotPassLink, LinksContainer, FormContainer, EyeIcon, LogoImage } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/LogoPos2.png';
import { faAddressCard, faArrowRight, faKey } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
/* import Instruction from '../../components/Instruction/index.jsx';   */
import api from '../../services/client/api';
import cookie from '../../services/cookie/index';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [ip, setIp] = useState('');
  const [isMounted, setIsMounted] = useState(true);

  const [inProgress, setInProgress] = useState(false);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [timer, setTimer] = useState(0); // Estado para controlar o temporizador
 /*  const [showInstruction, setShowInstruction] = useState(false); */ // Controla a exibição do Instruction

  useEffect(() => {
    const savedIp = cookie().get("userIP"); 
    const blockedIp = cookie().get("blockedIP"); // Obtém o IP bloqueado do cookie
    if (savedIp) {
      setIp(savedIp); 
      if (blockedIp === savedIp) { // Verifica se o IP está bloqueado
        const blockTime = localStorage.getItem('blockTime');
        if (blockTime && new Date().getTime() < blockTime) {
          setIsBlocked(true);
          history.replace('/blocked-page'); // Redireciona para a página de bloqueio
        }
      }
    } else {
      history.replace('/search-ip'); // Redireciona para a página de busca de IP
    }
  }, [history]);

  const startBlockTimer = useCallback((lockoutTime) => {
    const now = new Date().getTime();
    const blockUntil = now + lockoutTime;
    localStorage.setItem('blockTime', blockUntil);  // Salva o tempo de bloqueio no localStorage
    cookie().set("blockedIP", ip);  // Salva o IP do usuário no cookie
    setIsBlocked(true);
    setTimer(lockoutTime / 1000);  // Define o temporizador em segundos
    history.replace('/blocked-page');  // Redireciona para a página de bloqueio
  }, [history, ip]);

  useEffect(() => {
    const checkBlock = () => {
      const blockTime = localStorage.getItem('blockTime');
      const now = new Date().getTime();
      if (blockTime && now < blockTime) {
        setIsBlocked(true);
        setTimer(Math.ceil((blockTime - now) / 1000));
        history.replace('/blocked-page');  // Redireciona para a página de bloqueio
      }
    };
    checkBlock();
    return () => {
      setIsMounted(false);
    };
  }, [history]);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer <= 1) {
            clearInterval(interval); // Limpa o intervalo
            localStorage.removeItem('blockTime'); // Limpa o tempo de bloqueio
            setIsBlocked(false); // Reinicia o bloqueio
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const login = async (e) => {
    e.preventDefault();

    // Se o usuário estiver bloqueado 
    if (isBlocked) {
      history.replace('/blocked-page');  // Redireciona para a página de bloqueio
    }

    setInProgress(true);
    let statusCode;
    let messageReturn
    
    try {


      const response = await api().user().login({ username, password });
      statusCode = response.status;
      messageReturn = response.message;
  
      if (isMounted) {
        if (response.status === 401) {
          const newLoginAttempts = loginAttempts + 1;
          setLoginAttempts(newLoginAttempts);
          
          alert('Nome de acesso ou senha incorretos. Tente novamente.');

          // Verifica se atingiu o limite de tentativas
          if (newLoginAttempts >= 8) {
            const lockoutTime = 1 * 60 * 1000; // 1 minuto
            startBlockTimer(lockoutTime); // Inicia o temporizador
       
            history.replace('/blocked-page'); // Redireciona para a página de bloqueio
        }else {
          
          }
        } else if (response.status === 428) {
          await cookie().set("username", username, 720 / 1440);
          alert('Bem vindo ao seu primeiro acesso, confirme algumas informações.');
          setInProgress(false);
          history.replace('/info-verify');
        } else if (response.status === 200 && response.output.user) {
          const forcePasswordChange = cookie().get("forcePasswordChange");
          if (forcePasswordChange) {
            const parsedCookie = JSON.parse(forcePasswordChange);
            if (parsedCookie.force === true && parsedCookie.username === username) {
              history.replace('/replace-pass');
              return;
            }
          }

          await cookie().set("username", username, 720 / 1440);
          await cookie().set("token", response.output.token, 720 / 1440);
          await cookie().set("Name", response.output.user.name, 720 / 1440);
          await cookie().set("role", response.output.user.role, 720 / 1440);
          await cookie().set("mail", response.output.user.email, 720 / 1440);
          await cookie().set("tel", response.output.user.tel, 720 / 1440);
     
          // Salva no localStorage em vez de no cookie
          localStorage.setItem("verification_form", JSON.stringify(response.output.user.verification_form));
          
          history.replace('/');
        } else {
   
        }
      }
    } catch (err) {
      statusCode = err.response ? err.response.status : 'Erro de rede';

    } finally {
      const logData = {
        laudo: `Login - Usuário: ${username}, Status: ${statusCode}, Resposta da requisição: ${messageReturn}`,
        ip: ip,
        login: username,
      };

      try {
        await api().log().saveLog(logData);
      } catch (logError) {

      }

      setInProgress(false);
    }
  };

/*   const handleShowInstruction = () => {
    setShowInstruction(true); // Mostra o componente Instruction
  };

  const handleCloseInstruction = () => {
    setShowInstruction(false); // Fecha o componente Instruction
  }; */
// Verifica se os campos têm pelo menos 6 caracteres
const isFormValid = username.length >= 6 && password.length >= 6;

// Condição para ocultar o botão, caso o formulário não seja válido
const shouldHideButton = !isFormValid || isBlocked || inProgress;

return (
  <>
    <FormContainer>
      <Form onSubmit={login}>
        <FormGroup>
          <span style={{
            display: 'block',
            marginBottom: '5px',
            fontWeight: 650,
            fontSize: '17px',
            color: '#6c757d',
            textAlign: 'left',
          }}>
            Login:
          </span>
          <InputLogin
            type="text"
            placeholder="Digite seu Login"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </FormGroup>

        <FormGroup>
          <span style={{
            display: 'block',
            marginBottom: '5px',
            fontWeight: 650,
            fontSize: '17px',
            color: '#6c757d',
            textAlign: 'left',
          }}>
            Senha:
          </span>
          <div style={{ position: 'relative' }}>
            <InputLogin
              type={showPassword ? "text" : "password"}
              placeholder="Digite sua senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <EyeIcon onClick={() => setShowPassword(!showPassword)} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </EyeIcon>
          </div>
        </FormGroup>

        {/* Verifica se deve esconder o botão */}
        {!shouldHideButton && (
          <ButtonLogin color="primary" type="submit" title="Para acessar você deve fornecer as credenciais de acesso 'nome de acesso' e 'senha'!">
            {inProgress ? <Spinner size="sm" color="light" /> : 'Acessar'}
            <FontAwesomeIcon icon={faArrowRight} />
          </ButtonLogin>
        )}
        
      </Form>
      </FormContainer>

      {/* Exibe o componente Instruction quando showInstruction for true */}
 {/*      {showInstruction && <Instruction onClose={handleCloseInstruction} />}
 */}
      <LinksContainer>
        <FormGroup>
          <RegisterLink onClick={() => history.push('/generate-pin')}>
              Criar conta
            <FontAwesomeIcon icon={faAddressCard} />
          </RegisterLink>
        </FormGroup>

        <FormGroup>
          <ForgotPassLink
            onClick={() => {
                setInProgress(true);
                (history.push('/forgot-pass'));
              }
            }
          >
            Recuperar Senha<FontAwesomeIcon icon={faKey} />
          </ForgotPassLink>

        </FormGroup>
    
      </LinksContainer>
      
      <LogoImage src={logo} alt="Logo CEDAP" />

    </>
  );
}

export default Login;
