import styled from 'styled-components';
import { Button, Input } from 'reactstrap';
export const Footer = styled.footer`
  background-color: #f8f9fa;
  padding: 15px;
  text-align: center;
  font-size: 14px;
  color: #6c757d;
  border-top: 1px solid #ddd;
  z-index: 1025;
  position: absolute; // Mantém o footer fixo por padrão
  bottom: 10;
  width: 100%;

  @media (max-width: 768px) {
    position: relative; // Altera para posição relativa em telas menores
    font-size: 12px;
    margin-top: 40px; // Adiciona um espaço acima do footer
  }
`;
// Container
export const Container = styled.div`
  h1, h2, h3, h4 {
    color: #0C2B6E;
    font-family: 'Raleway', sans-serif;
    margin: 10px 0;
    font-weight: 700;
  }
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 768px) {
    padding: 10px;

  }
`;

// Overlay
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.63);
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  z-index: 1000;
`;


export const InputCpf = styled(Input)`
  height: 50px;
  width: 90%;
  max-width: 350px;
  border-radius: 40px;
  text-align: left;
  padding: 10px 25px;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin: 20px auto;

  @media (max-width: 768px) {
    width: 80%; // Diminui a largura em telas menores
    font-size: 14px; // Ajusta o tamanho da fonte
  }

  @media (max-width: 480px) {
    width: 90%; // Diminui mais ainda a largura
    font-size: 12px; // Tamanho da fonte ainda menor em dispositivos menores
  }
`;

// ButtonNext
export const ButtonNext = styled(Button)`
  width: 55%;
  padding: 10px 25px;
  border-radius: 30px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 80%; // Aumenta o tamanho do botão em telas menores
    font-size: 16px;
  }

  @media (max-width: 480px) {
    width: 90%; // Botão quase inteiro em telas muito pequenas
    font-size: 14px;
    height: 50px; // Ajusta a altura do botão
  }
`;

export const LogoImage = styled.img`
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 220px;
  z-index: 1025;

  @media (max-width: 768px) {
    width: 180px; // Diminui o tamanho da logo em dispositivos menores
    top: 330px;
    position: relative; // Altera para posição relativa em telas menores
    font-size: 12px;
    width: 150px; // Diminui ainda mais a logo em telas muito pequenas
    display: none; // Faz a logo desaparecer em telas menores que 768px
  }
  
  @media (max-width: 480px) {
    display: none; // A logo some completamente em telas menores que 480px
  }
`;



export const BackLink = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 10%;
  margin: 10px;
  cursor: pointer;
  color: #0C2B6E;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  text-decoration: underline;

  @media (max-width: 768px) {
    font-size: 14px; // Ajusta o tamanho da fonte
  }

  @media (max-width: 480px) {
    font-size: 12px; // Diminui mais o tamanho da fonte em telas pequenas
  }
`;


export const ModalCpf = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 380px;
  max-width: 500px;
  height: auto;
  overflow-y: auto;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.42);
  z-index: 1026;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    width: 80%; // Aumenta a largura do modal em telas pequenas
    padding: 15px;
  }

  @media (max-width: 480px) {
    width: 90%; // Reduz ainda mais o tamanho em telas menores
  }
`;

