import React from 'react';
import { ButtonNext, StepContainer, ModalWrapper, StepText, CloseButton } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const ShowContacts = ({ onClose }) => {
  const whatsappNumber = "5547991865855"; // Número de WhatsApp para o link
  const whatsappLink = `https://wa.me/${whatsappNumber}`;
  const formattedNumber = "+55 47 99186-5855"; // Exibição amigável do número

  return (
    <ModalWrapper>
      <FontAwesomeIcon 
        icon={faQuestionCircle} 
        style={{ color: 'orange', fontSize: '50px', marginBottom: '0px' }} 
      />

      <StepContainer>
        <StepText>
          Para obter suporte, entre em contato pelo WhatsApp. Clique no botão abaixo para redirecionamento automático.
          Caso o link não funcione, você pode adicionar este número diretamente no WhatsApp: <span>  </span> <strong> <u>{formattedNumber}</u></strong>.
        </StepText>
      </StepContainer>

      <ButtonNext as="a" href={whatsappLink} target="_blank" rel="noopener noreferrer">
        Contato Suporte <FontAwesomeIcon style={{ marginLeft: 5, fontSize: '30px'}} icon={faWhatsapp} />
      </ButtonNext>

      {/* Botão de Fechar */}
      <CloseButton 
        onClick={() => {
          if (typeof onClose === "function") {
            onClose();  // Chama a função para fechar o componente
          }
        }}
      >
        Fechar <FontAwesomeIcon icon={faWindowClose} />
      </CloseButton>
    </ModalWrapper>
  );
};

export default ShowContacts;
