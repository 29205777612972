import cookie from '../services/cookie/index';

export const formatDate = (timestamp) => {
  if (timestamp === null) {
    return "00/00/0000";
  }

  let timestampSplited = timestamp.split("T");
  let dateSplited = timestampSplited[0].split("-")

  return dateSplited[2] + "/" + dateSplited[1] + "/" + dateSplited[0]
}

export const deleteCookies = async () => {
  await cookie().del("Name");
  await cookie().del("role");
  await cookie().del("token");
}

export const deleteAllCookies = async () => {
  await cookie().del("Name");
  await cookie().del("role");
  await cookie().del("token");
  await cookie().del("mail");
  await cookie().del("forcePasswordChange");
  await cookie().del("username");
  await cookie().del("user_data");
  await cookie().del("blockedIP");
}