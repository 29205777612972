import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Spinner } from 'reactstrap';
import api from '../../services/client/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { Container, Title, ButtonAuth, InputAuth, Form, LoginLink, RegisterLink, ButtonFind, LinksContainer, ButtonGroup, LogoImage, SpanText } from './styles';
import ShowContacts from '../../components/ShowContacts/index.jsx';  
import cookie from '../../services/cookie';
import useBlockedIP from '../../utils/useBlockedIP.js';
import logo from '../../assets/LogoPos2.png';

function ForgotPassView() { 
    const [username, setUsername] = useState('');
    const [accountInfo, setAccountInfo] = useState(null);
    const [error, setError] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [sendingCode, setSendingCode] = useState(false);
    const [code, setCode] = useState('');
    const [timer, setTimer] = useState(0);
    const [resendMessage, setResendMessage] = useState(''); 
    const [showContacts, setShowContacts] = useState(false); // Controla a exibição do Instruction
    const history = useHistory();
    const [ip, setIp] = useState('');
    const [isBlocked, setIsBlocked] = useState(false); // Estado para controle de bloqueio
    const [, setLoginAttempts] = useState(0); // 
    const [isMounted, setIsMounted] = useState(true);

    useBlockedIP(); // Verifica o IP bloqueado



    useEffect(() => {
      const savedIp = cookie().get("userIP"); 
      const blockedIp = cookie().get("blockedIP"); // Obtém o IP bloqueado do cookie
      if (savedIp) {
          setIp(savedIp); 
          if (blockedIp === savedIp) { // Verifica se o IP está bloqueado
              const blockTime = localStorage.getItem('blockTime');
              if (blockTime && new Date().getTime() < blockTime) {
                  setIsBlocked(true);
                  history.replace('/blocked-page'); // Redireciona para a página de bloqueio
              }
          }
      } else {
          history.replace('/search-ip'); // Redireciona para a página de busca de IP
      }
    }, [history]);

  
    const startBlockTimer = useCallback((lockoutTime) => {
      const now = new Date().getTime();
      const blockUntil = now + lockoutTime;
      localStorage.setItem('blockTime', blockUntil);  // Salva o tempo de bloqueio no localStorage
      cookie().set("blockedIP", ip);  // Salva o IP do usuário no cookie
      setIsBlocked(true);
      setTimer(lockoutTime / 1000);  // Define o temporizador em segundos
      history.replace('/blocked-page');  // Redireciona para a página de bloqueio
    }, [history, ip]);
  
    useEffect(() => {
      const checkBlock = () => {
        const blockTime = localStorage.getItem('blockTime');
        const now = new Date().getTime();
        if (blockTime && now < blockTime) {
          setIsBlocked(true);
          setTimer(Math.ceil((blockTime - now) / 1000));
          history.replace('/blocked-page');  // Redireciona para a página de bloqueio
        }
      };
      checkBlock();
      return () => {
        setIsMounted(false);
      };
    }, [history]);
  
    useEffect(() => {
      let interval;
      if (timer > 0) {
        interval = setInterval(() => {
          setTimer(prevTimer => {
            if (prevTimer <= 1) {
              clearInterval(interval); // Limpa o intervalo
              localStorage.removeItem('blockTime'); // Limpa o tempo de bloqueio
              setIsBlocked(false); // Reinicia o bloqueio
              setLoginAttempts(0)
              localStorage.clear()
              return 0;
            }
            return prevTimer - 1;
          });
        }, 1000);
      }
      return () => clearInterval(interval);
    }, [timer]);
  
  
    const handleUsernameChange = (e) => {
      setUsername(e.target.value);
    };
  
    const handleCodeChange = async (e) => {
      
      const value = e.target.value.toUpperCase();
      setCode(value);
  
      if (/^[A-Za-z0-9]{6}$/.test(value)) {
        try {
          const response = await api().user().checkCode(value);
          if (isMounted) {
          if (response.status === 200) {
            alert('E-mail verificado com sucesso!');
            cookie().set("username", username);
            cookie().set("forcePasswordChange", JSON.stringify({ username, force: true }), { path: '/' });
            history.replace('/replace-pass');
          } else {
            setError('Código inválido ou erro na resposta.');
          }
        }} catch (error) {
          setError('Erro ao validar, Código inválido.');
          const storedAttempts = Number(localStorage.getItem('loginAttempts')) || 0;
 
          const newLoginAttempts = storedAttempts + 1;
          localStorage.setItem('loginAttempts', newLoginAttempts); // Persiste no localStorage

          setLoginAttempts(newLoginAttempts); // Atualiza o estado de tentativas

          if (newLoginAttempts >= 12) {
            const lockoutTime = 1 * 60 * 1000; // 1 minuto
            startBlockTimer(lockoutTime); // Inicia o temporizador
            history.replace('/blocked-page'); // Redireciona para a página de bloqueio
          }
        }
      } else {
        setError('O código deve ter exatamente 6 caracteres (letras e números).');
      }
    };
  
  
    const findUser = async () => {
      let userIp = cookie().get('userIP'); 
  
      if (isBlocked) {
        history.replace('/blocked-page');  // Redireciona para a página de bloqueio
        return;
      }
    
    
      if (username.length >= 3) {
        setInProgress(true);
        setError('');
    
      
        let statusCode;

        let returnData;
    
        try {
          const data = await api().user().getUserByUserName(username);
          if (isMounted) {

          statusCode = data.status || 200; // Padrão 200
   
          returnData = data.output;
    
          // Se o status não for 200 ou dados estiverem faltando, retorne erro
          if (statusCode !== 200 || !returnData.user || !returnData.user.email || !returnData.user.id || !returnData.token || !returnData.user.role) {
            setError('Usuário não encontrado, função não definida ou dados incompletos.');
            setAccountInfo(null);
            return;
          }
    
          // Caso todos os dados estejam presentes e válidos
          setAccountInfo(returnData.user);
          cookie().set("token", returnData.token);
          cookie().set("role", returnData.user.role);
          cookie().set("username", username);
    
        }} catch (error) {
          statusCode = error.response ? error.response.status : 'Erro de rede';
          setError('Usuário não encontrado, por favor informe o nome de acesso válido.')
          const storedAttempts = Number(localStorage.getItem('loginAttempts')) || 0;
 
          const newLoginAttempts = storedAttempts + 1;
          localStorage.setItem('loginAttempts', newLoginAttempts); // Persiste no localStorage

          setLoginAttempts(newLoginAttempts); // Atualiza o estado de tentativas

          if (newLoginAttempts >= 10) {
            const lockoutTime = 1 * 60 * 1000; // 1 minuto
            startBlockTimer(lockoutTime); // Inicia o temporizador
            history.replace('/blocked-page'); // Redireciona para a página de bloqueio
          }
        } finally {
          // Sempre registrar o log, independentemente do resultado
          const logData = {
            laudo: `Busca de usuário por username para recuperação de senha - Status: ${statusCode}`,
            ip: userIp,
            login: username,
          };
    
          try {
            await api().log().saveLog(logData);
          } catch (logError) {

          }    
          setInProgress(false)
        }
      } else {
        setError('O nome de usuário deve ter pelo menos 5 caracteres.');
      }
    };
    const sendRecoveryCode = async () => {
      if (!accountInfo || !accountInfo.email) {
        setError('Email não encontrado ou informações da conta não estão presentes.');
        return;
      }
  
      setSendingCode(true);
      setError('');
  
      const ip = cookie().get("userIP");
      let statusCode;

      try {

        // Enviar código de recuperação por e-mail
        const response = await api().user().sendMailRecovery(accountInfo.email);
        
        statusCode = response.status || 200;
      
        setTimer(60); // Começar o timer para o tempo de bloqueio ou reenvio
        setResendMessage('Tente novamente.');
        alert('E-mail enviado com sucesso, verifique o código e insira abaixo.');

      } catch (error) {
        statusCode = error.response ? error.response.status : 'Erro de rede';
        const storedAttempts = Number(localStorage.getItem('loginAttempts')) || 0;
    
        const newLoginAttempts = storedAttempts + 1;
        localStorage.setItem('loginAttempts', newLoginAttempts); // Persiste no localStorage
    
        setLoginAttempts(newLoginAttempts); // Atualiza o estado de tentativas
    
        if (newLoginAttempts >= 6) {
          const lockoutTime = 1 * 60 * 1000; // 1 minuto
          startBlockTimer(lockoutTime); // Inicia o temporizador
          history.replace('/blocked-page'); // Redireciona para a página de bloqueio
        }
        setError('Erro ao enviar código de recuperação. Atualize a página e tente novamente.');
      } finally {
        // Sempre registrar o log, independentemente do resultado
        const logData = {
          laudo: `Envio de código de recuperação - Email: ${accountInfo.email}, Status: ${statusCode}}`,
          ip: ip,
          login: username,
        };
    
        try {
          await api().log().saveLog(logData);
        } catch (logError) {}
    
        setSendingCode(false);
      }
    };
    
    
    
    
      
    const maskEmail = (email) => {
      const [localPart, domain] = email.split('@');
      const maskedLocalPart = localPart.slice(0, 2) + '****' + localPart.slice(-1);
      return `${maskedLocalPart}@${domain}`;
    };
  

    const handleShowContacts = () => {
      setShowContacts(true); // Mostra o componente Instruction
    };
  
    const handleCloseContacts = () => {
      setShowContacts(false); // Fecha o componente Instruction
    };
  
    const isFindUserButtonDisabled = username.length < 3 || inProgress;
    const isSendCodeButtonDisabled = sendingCode || timer > 0 ;

    return (
        <Container>
            <Title><center>Recuperação de Acesso</center></Title>
            <Form>
                {!accountInfo && (
                    <>
                    <SpanText>Digite seu login</SpanText>
                        <InputAuth
                            type="text"
                            placeholder="Digite seu login"
                            value={username}
                            onChange={handleUsernameChange}
                        />
                        <ButtonFind
                            color="primary"
                            onClick={findUser}
                            disabled={isFindUserButtonDisabled}
                        >
                            {inProgress ? <Spinner size="sm" /> : 'Avançar'}
                            <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '12px' }} />
                        </ButtonFind>
                    </>
                )}

                {inProgress && <Spinner color="primary" />}

                {error && <Alert color="danger">{error}</Alert>}

                {accountInfo && (
                    <>
                     <ButtonGroup>
                        <div style={{ margin: '5px 0', fontSize: '16px', color: '#333' }}>
                            Enviar para o E-mail: {maskEmail(accountInfo.email)}
                        </div>
                        </ButtonGroup>

                        <ButtonGroup>
                            <ButtonAuth
                                color="primary"
                                onClick={sendRecoveryCode}
                                disabled={isSendCodeButtonDisabled}
                            >
                                Enviar Código
                            </ButtonAuth>
                        </ButtonGroup>
                                {/* Exibir a mensagem apenas após o primeiro clique */}
                        {resendMessage && (
                            <ButtonGroup>
                                <div style={{ marginTop: '5px', fontSize: '17px', color: '#333', textAlign: 'center' }}>
                                    
                                        {timer > 0
                                            ? `Caso não tenha recebido o código, aguarde `
                                            : resendMessage}
                                        {timer > 0 && <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{timer}</span>}
                                        {timer > 0 ? ` segundos para enviar novamente.` : ''}
                                            
                                </div>
                            </ButtonGroup>
                        )}


                        <InputAuth
                            maxLength="6"
                            type="text"
                            placeholder="Digite o código de verificação"
                            value={code}
                            onChange={handleCodeChange}
                            disabled={!accountInfo}
                        />
                    </>
                )}
            </Form>
            {showContacts && <ShowContacts onClose={handleCloseContacts} />}

            <span onClick={handleShowContacts} style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline', marginTop: '15px' }}>
          Está com problemas para acessar? Clique aqui.
        </span>

            <LinksContainer>
                <LoginLink onClick={() => history.push({ pathname: '/login' })}>
                    <FontAwesomeIcon icon={faArrowLeft} /> voltar
                </LoginLink>
                <RegisterLink
                    onClick={() => {
                        setInProgress(true);
                        history.push({ pathname: '/generate-pin' });
                    }}
                >
                    <FontAwesomeIcon icon={faAddressCard} /> Fazer cadastro
                </RegisterLink>
            </LinksContainer>
            <LogoImage src={logo} alt="Logo CEDAP" />
  
        </Container>
    );
}

export default ForgotPassView;
