import React, { } from 'react';
import {  ModalContainer, StepContainer, CloseButtonDown, ButtonContainer, ClickLiveness } from './styles';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Clickliveness from '../../assets/datavalid1.png'; // Substitua pelos caminhos das suas imagens

const StepLiveness1 = ({ onClose }) => {
 
  return (
    <ModalContainer>
        <StepContainer>
              <h4 style={{marginLeft:'10px'}}>
            Acesse o aplicativo Datavalid 
              </h4>  
              
            </StepContainer>
   
            <StepContainer>
           <h4> <li>Clique em PROVA DE VIDA</li></h4>
            </StepContainer>
          
      
            <StepContainer>
   
                  <ClickLiveness
                    src={Clickliveness}
                    alt="Clickliveness"
                  />
                </StepContainer>
                <ButtonContainer>
             
                    <CloseButtonDown onClick={onClose}>
                      Continuar
                      <FontAwesomeIcon icon={faArrowAltCircleRight} style={{ marginLeft: 5 }} />
                    </CloseButtonDown>
                  </ButtonContainer>
    </ModalContainer>
    
  );
};

export default StepLiveness1;
