import ApiConnection from './connection';


const registerUserPatient = async (userData, token) => {
    try {
      const response = await ApiConnection.post('user-patients/register', userData, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      return response.data; // Retorna os dados da resposta se bem-sucedido
    } catch (error) {
  
      const errorMessage = error.response?.data?.error || 'Erro ao cadastrar paciente';
      throw new Error(errorMessage); // Lança um erro com a mensagem apropriada
    }
  };
  
  
const VerificarCpf = async (cleanCpf) => {
    try {
      // Envia o CPF no formato correto
      const response = await ApiConnection.post('user-patients/get-by-cpf', { CPF: cleanCpf });
  
      if (response.status !== 200) {
        // Se a resposta não for bem-sucedida, lança erro
        throw new Error(response.data.message || 'Erro ao verificar o CPF');
      }
  
      return { output: response.data, status: response.status, message: response.data.message };
  
    } catch (error) {
     
      // Captura e trata o erro de reCAPTCHA inválido
  
        throw error; // Se for outro erro, repassa para ser tratado
      
    }
  };
  
  
export default function user() {
    return {
      registerUserPatient,
      VerificarCpf,
    };
  }