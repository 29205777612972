import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner, FormGroup, Form, Container, Row, Col } from 'reactstrap';
import { ButtonLogin, InputLogin, LogoImage } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/client/api';
import cookie from '../../services/cookie/index';
import { validarTelefone, validarData } from '../../utils/validation.js';
import logo from '../../assets/LogoPos2.png';


import moment from 'moment';
import { isCpfValid } from '../../utils/cpfValidate.js';
const InfoVerifyView = () => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [birthday_user, setDataNasc] = useState('');
    const [cpf_user, setCpf] = useState('');
    const [tel_user, setTelefone] = useState('');
    const [mother_name_user, setMotherName] = useState('');
    const [inProgress, setInProgress] = useState(false);

    const [ip, setIp] = useState('');
    
    const [loginAttempts, setLoginAttempts] = useState(0); // Contador de tentativas
    const maxAttempts = 7; // Número máximo de tentativas antes do bloqueio

    const isMountedRef = useRef(true);

    useEffect(() => {
        const storedIp = cookie().get("userIP");
        if (storedIp) {
            setIp(storedIp);
        } else {
            alert('IP não encontrado. Por favor, tente novamente.');
        }
        
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (loginAttempts >= maxAttempts) {
            // Redireciona para a página de bloqueio se o limite for atingido
            history.replace('/blocked-page');
            return;
        }

        setInProgress(true);
        let statusCode;

    
        if (!isCpfValid(cpf_user)) {
            alert('CPF inválido.');
            setInProgress(false);
            return;
        }

        if (!validarTelefone(tel_user)) {
            alert('Número de telefone inválido. Verifique e tente novamente.');
            setInProgress(false);
            return;
        }

        const dataNascFormatada = moment(birthday_user, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ssZ');
        if (!validarData(birthday_user)) {
            alert('Data inválida. Verifique o formato e os valores.');
            setInProgress(false);
            return;
        }

        if (!ip) {
            alert('IP não encontrado. Por favor, tente novamente.');
            setInProgress(false);
            return;
        }

        const username = cookie().get("username");

        const formData = {
            name,
            email,
            birthday_user: dataNascFormatada,
            mother_name_user,
            cpf_user: cpf_user.replace(/\D/g, ''),
            tel_user: tel_user.replace(/\D/g, ''),
            username
        };

        try {
            const response = await api().user().infoVerification(formData);
            statusCode = response.status || 200;

            if (response.message === 'User information verified successfully') {
                window.alert('Informações verificadas com sucesso. Por favor, Altere sua senha.');
                cookie().set("forcePasswordChange", JSON.stringify({ username, force: true }), { path: '/' });
                history.replace('/replace-pass');
            } else {
                // Incrementa o número de tentativas
                const newLoginAttempts = loginAttempts + 1;
                setLoginAttempts(newLoginAttempts);

                // Verifica se atingiu o limite de tentativas
                if (newLoginAttempts >= maxAttempts) {
                    history.replace('/blocked-page'); // Redireciona para a página de bloqueio
                } else {
                    alert('Erro ao verificar informações. Tente novamente.');
                }
                setInProgress(false);
            }
        } catch (error) {

            statusCode = error.response ? error.response.status : 'Erro de rede';
            alert('Tente novamente.');
            setInProgress(false); // Certifique-se de parar o progresso em caso de erro
        } finally {
            if (isMountedRef.current) {
                setInProgress(false);

                // Registro de log ao finalizar o processo
                const logData = {
                    laudo: `Verificação de informações - Username: ${username}, Status: ${statusCode}`,
                    ip: ip,
                    login: username
                };

                try {
                    await api().log().saveLog(logData);
                } catch (logError) {

                }
            }
        }
    };
        const handleDateChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 2) {
            value = `${value.slice(0, 2)}/${value.slice(2)}`;
        }
        if (value.length > 5) {
            value = `${value.slice(0, 5)}/${value.slice(5)}`;
        }
        setDataNasc(value);
    };

    const applyCpfMask = (value) => {
        const cleanedValue = value.replace(/\D/g, '');
        const match = cleanedValue.match(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/);
        if (match) {
            return `${match[1]}${match[2] ? '.' + match[2] : ''}${match[3] ? '.' + match[3] : ''}${match[4] ? '-' + match[4] : ''}`;
        }
        return value;
    };

    const applyPhoneMask = (value) => {
        const cleanedValue = value.replace(/\D/g, '');
        const match = cleanedValue.match(/^(\d{0,2})(\d{0,5})(\d{0,4})$/);
        if (match) {
            return `${match[1] ? '(' + match[1] : ''}${match[2] ? ') ' + match[2] : ''}${match[3] ? '-' + match[3] : ''}`;
        }
        return value;
    };

 

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };

    return (
        <div>
            <Container>
                <div style={{ marginTop: '20px', marginBottom: '20px', fontWeight: 'bold' }}>
                    Confirme algumas informações sobre você.
                </div>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <span style={{
                                    display: 'block',
                                    marginBottom: '0px',
                                    marginLeft: '20px',
                                    fontWeight: 650,
                                    fontSize: '15px',
                                    color: '#6c757d',
                                    textAlign: 'left',
                                }}>
                                    Digite seu nome completo:
                                </span>
                                <InputLogin 
                                    type="text"
                                    placeholder="Digite o nome"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    onKeyPress={handleKeyPress} // Adicionado aqui
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <span style={{
                                    display: 'block',
                                    marginBottom: '0px',
                                    marginLeft: '20px',
                                    fontWeight: 650,
                                    fontSize: '15px',
                                    color: '#6c757d',
                                    textAlign: 'left',
                                }}>
                                    Digite seu CPF:
                                </span>
                                <InputLogin 
                                    type="text"
                                    placeholder="Digite o CPF"
                                    value={applyCpfMask(cpf_user)}
                                    onChange={(e) => setCpf(e.target.value)}
                                    onKeyPress={handleKeyPress} // Adicionado aqui
                                    required
                                    maxLength="14"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <span style={{
                                    display: 'block',
                                    marginBottom: '0px',
                                    marginLeft: '20px',
                                    fontWeight: 650,
                                    fontSize: '15px',
                                    color: '#6c757d',
                                    textAlign: 'left',
                                }}>
                                    Digite o nome completo de sua mãe:
                                </span>
                                <InputLogin 
                                    type="text"
                                    placeholder="Nome da Mãe"
                                    value={mother_name_user}
                                    onChange={(e) => setMotherName(e.target.value)}
                                    onKeyPress={handleKeyPress} // Adicionado aqui
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <span style={{
                                    display: 'block',
                                    marginBottom: '0px',
                                    marginLeft: '20px',
                                    fontWeight: 650,
                                    fontSize: '15px',
                                    color: '#6c757d',
                                    textAlign: 'left',
                                }}>
                                    Digite sua data de nascimento:
                                </span>
                                <InputLogin
                                    type="text"
                                    placeholder="Data de nascimento"
                                    value={birthday_user}
                                    onChange={handleDateChange}
                                    onKeyPress={handleKeyPress} // Adicionado aqui
                                    maxLength="10"
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <span style={{
                                    display: 'block',
                                    marginBottom: '0px',
                                    marginLeft: '20px',
                                    fontWeight: 650,
                                    fontSize: '15px',
                                    color: '#6c757d',
                                    textAlign: 'left',
                                }}>
                                    Digite seu número de telefone (celular):
                                </span>
                                <InputLogin
                                    type="text"
                                    placeholder="Digite seu telefone"
                                    value={applyPhoneMask(tel_user)}
                                    onChange={(e) => setTelefone(e.target.value)}
                                    onKeyPress={handleKeyPress} // Adicionado aqui
                                    maxLength="15"
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <FormGroup>
                                <span style={{
                                    display: 'block',
                                    marginBottom: '0px',
                                    marginLeft: '20px',
                                    fontWeight: 650,
                                    fontSize: '15px',
                                    color: '#6c757d',
                                    textAlign: 'left',
                                }}>
                                    Digite seu endereço de e-mail:
                                </span>
                                <InputLogin 
                                    type="email"
                                    placeholder="Digite seu email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onKeyPress={handleKeyPress} // Adicionado aqui
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={12}>
                            <ButtonLogin type="submit" disabled={inProgress}>
                                {inProgress ? <Spinner size="sm" color="light" /> : 'Continuar'}
                                {!inProgress && <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '10px' }} />}
                            </ButtonLogin>
                        </Col>
                    </Row>
                </Form>
                      <Row>
                            <Col xs={12} md={12}>
                                 <LogoImage src={logo} alt="Logo CEDAP" />
                            </Col>
                      </Row>
                  
                       
            </Container>
        </div>
    );
};

export default InfoVerifyView;
