import React, { useEffect, useState, useCallback } from 'react'; 
import ListReports from './list.jsx';
import { Spinner } from 'reactstrap';
import api from '../../services/client/api';
import cookie from '../../services/cookie/index';
import { deleteCookies } from '../../utils/functions';
import { useHistory, useLocation } from 'react-router-dom';
import { faMicroscope, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UpdateContact from '../../components/UpdateContact/index.jsx';
import { ButtonUpdate } from './styles';

export default function Reports() {
  const [reportsByPatient, setReportsByPatient] = useState({});
  const [reportsByDependents, setReportsByDependents] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const location = useLocation();
  const [showUpdateContact, setShowUpdateContact] = useState(false);
  const [showButtonUpdate, setShowButtonUpdate] = useState(false);
  const [tel, setTel] = useState('');
  const [mail, setMail] = useState('');
  const [token, setToken] = useState('');
  
  const getDateToday = () => {
    let date = new Date();
    date.getFullMonth = function () {
      let month = this.getMonth() + 1;
      return month > 9 ? month : '0' + month;
    };
    date.getFullDay = function () {
      let day = this.getDate();
      return day > 9 ? day : '0' + day;
    };
    return date.getFullYear() + '-' + date.getFullMonth() + '-' + date.getFullDay();
  };
  const getReports = useCallback(async () => {
    const token = await cookie().get('token');
    const tel_user = await cookie().get('tel');
    const mail_user = await cookie().get('mail');
    setTel(tel_user);  // Salva o telefone no estado
    setMail(mail_user); // Salva o e-mail no estado
setToken(token)
   // Acessa o item do localStorage
const verification_form = JSON.parse(localStorage.getItem('verification_form'));


    // Verificação rigorosa dos valores
  
    
  
    try {
      const response = await api().report().getAll(token, '1900-01-01', getDateToday());
  
      if (response.status === 401) {
        await deleteCookies();
     
        return history.replace({ pathname: '/login' });
      }
  
      if (response.status === 200) {
        // Agrupar exames por categoria

        const groupedReportsByCategory1 = {};
        const groupedReportsByCategory2 = {};

        // Iterando sobre os exames retornados
        response.output.forEach(report => {
    
          // Agrupando por categoria 1
          if (report.id_categoria === 1) {
            if (!groupedReportsByCategory1[report.id_patient]) {
              groupedReportsByCategory1[report.id_patient] = { patientName: report.patient, exams: [] };
            }
            groupedReportsByCategory1[report.id_patient].exams.push(report);
          }
  
          // Agrupando por categoria 2
          if (report.id_categoria === 2) {
            if (!groupedReportsByCategory2[report.id_patient]) {
              groupedReportsByCategory2[report.id_patient] = { patientName: report.patient, exams: [] };
            }
            groupedReportsByCategory2[report.id_patient].exams.push(report);
          }
        });
  
        setReportsByPatient(groupedReportsByCategory1);
        setReportsByDependents(groupedReportsByCategory2);
        setIsLoading(false);
        setErrorMessage(''); 

      }else if (response.status === 404) {
        // Mensagem de erro com interpolação de variáveis de e-mail e telefone
        setShowButtonUpdate(true);
        setErrorMessage(
          `No momento, não conseguimos liberar o acesso aos seus exames. As informações disponíveis até agora não são suficientes para concluir a verificação. Estamos analisando sua solicitação e, assim que as informações forem atualizadas, a confirmação deverá ocorrer dentro de um prazo de até 72 horas.`
        );
        
        if (verification_form !== 1 && verification_form !== 2) {
          setShowButtonUpdate(true); // Exibe o botão
          setIsLoading(false);
        } else {
          setShowButtonUpdate(false); // Não exibe o botão
          setIsLoading(false);
        }
        
      }
      
      else {
        setErrorMessage('Ocorreu um erro ao carregar os exames. Tente novamente mais tarde.');
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Erro ao obter os exames:', error);  // Captura de erro adicional
      setErrorMessage('Ocorreu um erro ao carregar os exames. Tente novamente mais tarde.');
      setIsLoading(false);
    }
  }, [history]);
  

  useEffect(() => {
    if (isLoading) {
      getReports();
    }

    if (location.state?.successMessage) {
      alert(location.state.successMessage);
      history.replace({ pathname: location.pathname, state: {} });
    }
  }, [isLoading, getReports, location, history]);


  const handleCloseUpdateContact = () => {
    setShowUpdateContact(false);
    window.location.reload(); // Isso irá recarregar a página
  }
  
  const handleShowUpdateContact = () => {
    setShowUpdateContact(true);
  };


 return (
  <div>
    <h1>
      <FontAwesomeIcon icon={faMicroscope} /> Meus Exames
    </h1>
    {isLoading ? (
      <div>
        <Spinner color="primary" /> Carregando...
      </div>
    ): errorMessage ? (
      <div>
        <p>{errorMessage}</p>

        {/* Div para exibir as opções de contato se o erro for 404 */}
        {showUpdateContact && (
        
            <UpdateContact 
            onClose={handleCloseUpdateContact}
            tel={tel}
            mail={mail}
            token={token}
            />
       
        
          
     
        )}
        {/* Botão que abre o UpdateContact */}
        {showButtonUpdate && (
        <ButtonUpdate onClick={handleShowUpdateContact}>
          CONFIRME AS INFORMAÇÕES DE CONTATO
        </ButtonUpdate>
           )}
      </div>
    ) : (
      <>
        {/* Exibindo os exames da categoria 1 */}
        {Object.entries(reportsByPatient).length > 0 && (
          <div>
            {Object.entries(reportsByPatient).map(([idPatient, { patientName, exams }]) => (
              <div key={idPatient}>
                <ListReports reports={exams} />
                <hr />
              </div>
            ))}
          </div>
        )}

        {/* Exibindo os exames da categoria 2 */}
        {Object.entries(reportsByDependents).length > 0 && (
          <div>
          
            <h4 style={{color: '#0C2B6E', fontWeight: 650}}><FontAwesomeIcon icon={faUsers} style={{marginRight:5,}} />Exames de Dependentes</h4>
            {Object.entries(reportsByDependents).map(([idPatient, { patientName, exams }]) => (
              <div key={idPatient}>
              
                <ListReports reports={exams} />
                <hr />
              </div>
            ))}
          </div>
        )}
      </>
    )}
  </div>
);
}
