import React, { useState, useEffect } from 'react';
import { Alert, Spinner } from 'reactstrap';
import api from '../../services/client/api';
import { useHistory } from 'react-router-dom';
import cookie from '../../services/cookie';
import StepLiveness1 from '../../components/StepLiveness1/index.jsx'; // Importe o StepLiveness1
import StepLiveness2 from '../../components/StepLiveness2'; // Importe o StepLiveness2
import StepLiveness3 from '../../components/StepLiveness3'; // Importe o StepLiveness3
import StepLiveness4 from '../../components/StepLiveness4'; // Importe o StepLiveness4

function GeneratePinView() {
  const [cpf, setCpf] = useState('');
  const [pinError, setPinError] = useState('');
  const [inProgress, setInProgress] = useState(true); // Começa com o spinner ativado
  const [showStep1, setShowStep1] = useState(true); // Controla a exibição do StepLiveness1
  const [showStep2, setShowStep2] = useState(false); // Controla a exibição do StepLiveness2
  const [showStep3, setShowStep3] = useState(false); // Controla a exibição do StepLiveness2
  const [showStep4, setShowStep4] = useState(false); // Controla a exibição do StepLiveness2

  const history = useHistory();
  const [ip, setIp] = useState('');

  useEffect(() => {
    const userData = localStorage.getItem('user_data'); // Obtendo os dados do localStorage
    if (userData) {
      const parsedData = JSON.parse(userData); // Fazendo o parse dos dados
      setCpf(parsedData.cpf || ''); // Atribuindo o CPF ou uma string vazia
    } else {
      alert('Ocorreu um erro, atualize a página e tente novamente.');
      history.replace('/'); // Redireciona para a página inicial ou de erro
    }

    const savedIp = cookie().get('userIP');
    if (savedIp) {
      setIp(savedIp);
    } else {
      history.replace('/search-ip'); // Redireciona para a página de busca de IP
    }
  }, [history]);

  useEffect(() => {
    if (cpf && ip ) { // Verifica se o CPF, IP 
      let isMounted = true;
      

      const generatePin = async () => {
        setInProgress(true);

      

        const requestData = {
          cpf: cpf.replace(/\D/g, ''),
          
        };

        let response = null;

        try {
          response = await api().bioconnect().createPin(requestData);
          if (response && response.data?.pin) {
            if (isMounted) {
              setPinError(''); // Limpa o erro do PIN
              // Armazena os dados no localStorage
              localStorage.setItem('user_data', JSON.stringify({
                cpf: requestData.cpf, 
                pin: response.data.pin
              }));
              setShowStep1(true); // Atualiza o estado para mostrar o passo 1
            }
          }
        } catch (error) {
          if (isMounted) {
            setPinError(error.message || 'Erro desconhecido');
            alert(error.message || 'Erro desconhecido');
            history.replace('/login');
          }
        } finally {
          if (isMounted) {
            const ResponseMsg = response ? response.message : 'Sem resposta';
            const logData = {
              laudo: `Geração de PIN - CPF: ${requestData.cpf.replace(/\D/g, '')}, ${ResponseMsg}`,
              ip: ip,
              login: requestData.cpf,
            };

            try {
              await api().log().saveLog(logData);
            } catch (logError) {
              // Ignora erros de log
            }
          }
        }
      };

      generatePin();

      return () => {
        isMounted = false;
      };
    }
  }, [cpf, ip, history]);


  return (
    <>
      {pinError && <Alert color="danger">{pinError}</Alert>}
      
      {showStep1 && (
        <StepLiveness1 
          onClose={() => {
            setShowStep1(false); // Fecha StepLiveness1
            setShowStep2(true);  // Abre StepLiveness2
          }} 
          onBack={() => history.replace('/')} // Função para voltar
        />
      )}
      
      {showStep2 && (
        <StepLiveness2 
          onClose={() => {
            setShowStep2(false); // Fecha StepLiveness2
            setShowStep3(true);    // Abre ShowPin
          }} 
          onBack={() => {
            setShowStep2(false); // Fecha StepLiveness2
            setShowStep1(true);  // Abre StepLiveness1
          }}
        />
      )}
      
      
      {showStep3 && (
        <StepLiveness3 
         onClose={() => {
          setShowStep3(false); // Fecha StepLiveness2
          setShowStep4(true);    // Abre ShowPin
        }} 
          onBack={() => {
            setShowStep3(false); // Fecha StepLiveness2
            setShowStep2(true);  // Abre StepLiveness1
          }}
        />
      )}
    {showStep4 && (
        <StepLiveness4 
         onClose={() => {
          setShowStep4(false); // Fecha StepLiveness2
          history.replace('/liveness-result')
        }} 
          onBack={() => {
            setShowStep4(false); // Fecha StepLiveness2
            setShowStep3(true);  // Abre StepLiveness1
          }}
        />
      )}


    
      {inProgress && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: -1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Spinner size="lg" color="primary" />
        </div>
      )}
      
    </>
  );
}

export default GeneratePinView;