import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import cookie from '../services/cookie';

const useBlockedIP = () => {
  const history = useHistory();

  useEffect(() => {
    const blockedIP = cookie().get('blockedIP');
    const userIP = cookie().get('userIP');
    const blockTime = localStorage.getItem('blockTime');
    const now = new Date().getTime();

    if (blockedIP && blockedIP === userIP && blockTime && now <= blockTime) {
      history.replace('/blocked-page');
    }
  }, [history]);
};

export default useBlockedIP;
