
import { Container } from './styles.js';

import ReplacePassView from '../../views/ReplacePass/index.jsx';

function ReplacePass() {
  return (
      <Container>
          <ReplacePassView/>     
              
      </Container>
      
  );
}

export default ReplacePass;
