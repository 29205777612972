import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import cookie from '../../services/cookie/index';
import { useHistory } from 'react-router-dom';

const SearchIpView = ({ onIpFetched }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is still mounted

    const fetchIp = async () => {
      try {
        const response = await fetch("https://api.ipify.org/?format=json");
        if (!response.ok) throw new Error("Falha ao buscar IP");
        const data = await response.json();

        // Salva o IP no cookie por 1 dia
        cookie().set("userIP", data.ip, 1);
       
        // Notifica a página que o IP foi obtido
        if (isMounted) {
          onIpFetched();
        }

        // Redireciona para o login
        if (isMounted) {
          history.replace('/login');
        }
      } catch {
        if (isMounted) {
          setError("Não foi possível obter seu IP. Tente novamente.");
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    // Verifica se o IP já está salvo no cookie
    const cookies = cookie();
    const savedIp = cookies.get("userIP");
    if (savedIp) {
      onIpFetched(); // Notifica que o IP já existe
      history.replace('/login'); // Redireciona imediatamente
    } else {
      fetchIp(); // Caso contrário, busca o IP
    }

    // Cleanup function to set the flag to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, [onIpFetched, history]);

  if (loading) {
    return (
      <div className="loading-container">
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
      </div>
    );
  }

  return null; // Nenhum conteúdo após a obtenção ou redirecionamento
};

export default SearchIpView;
