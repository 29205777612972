import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  padding: 20px;
`;

export const Box = styled.div`
  background: #ffffff;
  padding: 40px;
  max-width: 400px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const Message = styled.p`
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  line-height: 1.5;
`;

export const Timer = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #e74c3c;
  margin-top: 20px;
`;
