import styled from 'styled-components';

export const Container = styled.div`
h1 {
    color: #0C2B6E;
    font-family: 'Raleway', sans-serif;
    margin: 50px 0;
    font-weight: 700;
  }
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f0f2f5;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
`;

export const CenterDiv = styled.div`
  background: #FFF;
  text-align: center;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
`;
export const CenterDiv2 = styled.div`
  background: #FFF;
  text-align: center;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
`;

export const ButtonLogin = styled.button`
  width: auto;
  padding: 10px 25px;
  border-radius: 40px;
  border: none;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 20px;
  height: 50px;
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  position: relative;
  margin-top: 20px;
`;

export const InputLogin = styled.input`
  height: 50px;
  width: 100%;
  border-radius: 40px;
  text-align: left;
  padding: 10px 25px;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;
`;

export const SelectLogin = styled.select`
  height: 50px;
  width: 100%;
  border-radius: 40px;
  padding: 10px 25px;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;
`;

export const LogoImage = styled.img`
  position: fixed;
  bottom: 20px;
  right: 20px;
`;
export const AdditionalLinks = styled.div`
  position: absolute;
  top: 20px; /* Ajuste conforme necessário */
  right: 20px; /* Ajuste conforme necessário */
`;

export const Link = styled.a`
  color: #0C2B6E; /* Cor do texto */
  text-decoration: none; /* Remover sublinhado */
  font-size: 16px; /* Tamanho do texto */
  font-weight: bold; /* Peso da fonte */
  /* Adicione mais estilos conforme necessário */
`;