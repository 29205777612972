// src/utils/cpfUtils.js
export const isCpfValid = (cpf) => {
    const cleanedCpf = cpf.replace(/\D/g, '');
    if (cleanedCpf.length !== 11 || /^(\d)\1+$/.test(cleanedCpf)) {
      return false;
    }
    const digits = cleanedCpf.split('').map(Number);
    const calculateDigit = (factor) => {
      const sum = digits.slice(0, factor - 1).reduce((acc, num, index) => acc + num * (factor - index), 0);
      const remainder = (sum * 10) % 11;
      return remainder === 10 ? 0 : remainder;
    };
    const firstCheckDigit = calculateDigit(10);
    const secondCheckDigit = calculateDigit(11);
    return digits[9] === firstCheckDigit && digits[10] === secondCheckDigit;
  };
  