import styled from 'styled-components';
import { Button } from 'reactstrap';
import phoneLayout from '../../assets/phoneLayout.png';

export const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width:650px; 
  min-height: 50vh;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 0px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 900;
  display: flex;
  flex-direction: column;
  align-items: center;

`;

export const QrCodeBox = styled.div`
  position: relative;
  width: 210px;  // Tamanho do "celular" para telas grandes
  height: 350px; // Altura ajustada para caber o botão inferior
  background-color: #fff;
  border-radius: 50px; // Borda mais arredondada para simular um celular
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2); // Sombra mais suave
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px 0; // Ajuste no padding para simular o contorno da tela
  margin: 5px auto;
  border: 2px solid #ddd;

  .screen {
    width: 180px; // Tamanho da "tela" do celular
    height: 320px; // Tamanho da "tela" do celular
    background-color: black; // Cor preta simulando a tela
    border-radius: 30px; // Borda arredondada para a tela
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    flex-direction: column;  // Garante que o conteúdo será alinhado verticalmente
  }

  .button {
    position: absolute;
    bottom: 20px;  // Posicionando o botão redondo na parte inferior
    width: 30px;
    height: 30px;
    border-radius: 50%;  // Tornando o botão redondo
    background-color: #d3d3d3; // Cor do botão
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); // Sombra para dar o efeito de profundidade
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    width: 180px; // Reduzindo o tamanho para telas menores
    height: 280px; // Ajuste na altura

    .screen {
      width: 160px;  // Ajustando a largura da tela para telas pequenas
      height: 250px; // Ajustando a altura da tela para telas pequenas
    }

    .button {
      bottom: 20px; // Ajuste da posição do botão em telas menores
      width: 25px; // Menor tamanho do botão
      height: 25px; // Menor tamanho do botão
    }
  }

  @media (max-width: 480px) {
    width: 170px; // Menor tamanho para telas muito pequenas
    height: 270px;

    .screen {
      width: 150px; // Ajuste na largura da tela para dispositivos muito pequenos
      height: 245px; // Ajuste na altura da tela
    }

    .button {
      bottom: 25px; // Ajuste na posição do botão
      width: 20px; // Reduzindo ainda mais o tamanho do botão
      height: 20px; // Reduzindo o tamanho do botão
    }
  }
`;


export const CellphoneImage = styled.div`
  position: relative;
  width: 220px; // Ajuste o tamanho da imagem conforme necessário
  height: 390px; // Ajuste a altura da imagem
  background-image: url(${phoneLayout});  /* Imagem importada */
  background-size: cover;
  background-position: center;
  border-radius: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  margin: 15px auto;

  /* Container para o conteúdo dentro da imagem do celular */
  .content {
    position: absolute;
    top: 25px;  /* Ajuste a posição da tela conforme necessário */
    width: 180px;/* normal */
    height: 320px;/* normal */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }


  @media (max-width: 765px) {
    
  width: 220px; // Ajuste o tamanho da imagem conforme necessário
  height: 390px; // Ajuste a altura da imagem
    .content {
      width: 180px;/* normal */
      height: 320px;/* normal */
    }

  }

  @media (max-width: 480px) {
    width: 200px;
    height: 360px;

    .content {
      width: 170px;
      height: 310px;
    }

    .button {
      bottom: 25px;
      width: 20px;
      height: 20px;
    }
  }
`;


export const AppleImg = styled.img`
  position: relative; // Certifique-se de que o posicionamento relativo está ativo
  top: -5px; // Ajuste o valor conforme necessário para mover a logo para cima
  right: 2px; // A posição à direita permanece a mesma
  width:150px;
  cursor: pointer;

    @media (max-width: 480px) {
      width:120px;
      heigth:120px;
  }
`;

export const GoogleImg = styled.img`
  position: relative; // Certifique-se de que o posicionamento relativo está ativo
  top: -5px; // Ajuste o valor conforme necessário para mover a logo para cima
  right: 2px; // A posição à direita permanece a mesma
  cursor: pointer;
  width:150px;
  heigth:150px;

   @media (max-width: 480px) {
      width:120px;
      heigth:120px;
    }
`;
export const WhiteText = styled.h5`
  margin: 5px;
  text-align: center;
   color:rgb(255, 255, 255);
`;


export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2px ;
`;


export const StepText = styled.h5`
  margin: 0px;
    margin-top: 25px;
  text-align: center;
  color: #333;
`;
export const ButtonInstall = styled(Button)`
  width: 40%;  // Ou ajuste conforme necessário
  padding: 12px;
  border-radius: 30px;
  border: none;
    font-size: 20px; // Ajuste de font-size com base na largura da tela
  font-weight: 600;
  background: linear-gradient(268.2deg, rgb(2, 124, 2) -5.28%, rgb(9, 204, 9) 100%, #228B22 100%);
  height: 6vh;  // Ajuste a altura com base na altura da tela
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 17px; // Para telas menores
    height: 45px;   // Ajuste a altura para não ficar muito pequena
    padding: 10px;
    width: 60%;
  }
`;

export const ButtonNo = styled(Button)`
  width: 35%;
  padding: 12px;
  border-radius: 30px;
  border: none;
    font-size: 20px;  // Ajuste de font-size com base na largura da tela
  font-weight: 600;
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  margin-top: 50px;
  height: 6vh;  // Ajuste a altura com base na altura da tela
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
      font-size: 20px;  // Para telas menores
    height: 45px;     // Ajuste a altura para não ficar muito pequena
    padding: 10px;
  }
`;

export const ButtonNext = styled(Button)`
  width: 35%;  // Ajustado para 'auto', assim os botões não irão quebrar linha
  padding: 12px;
  border-radius: 30px;
  border: none;
  font-weight: 600;
   font-size: 20px;  // Ajuste de font-size com base na largura da tela
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  margin-top: 50px;
  height: 6vh;  // Ajuste a altura com base na altura da tela
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 17px;  // Para telas menores
    height: 45px;     // Ajuste a altura para não ficar muito pequena
    padding: 10px;
  }
`;


export const ButtonInstalled = styled(Button)`
  width: 80%;
  padding: 12px;
  border-radius: 30px;
  border: none;
    font-weight: 600; 
  font-size: 18px;
    background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
       @media (max-width: 768px) {
    font-size: 17px; // Para telas menores
    height: 45px;   // Ajuste a altura para não ficar muito pequena
    padding: 10px;
    width: 60%px;
  }
`;




export const LoginLink = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #0C2B6E;
  font-weight: 700;
  text-decoration: underline;
  margin-top: 15px;

  svg {
    margin-right: 5px;
  }
`;



export const LogoDv = styled.img`
  position: relative; // Certifique-se de que o posicionamento relativo está ativo
  top: -5px; // Ajuste o valor conforme necessário para mover a logo para cima
  right: 2px; // A posição à direita permanece a mesma
`;




