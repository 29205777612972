import styled from 'styled-components';
import { Input, Button } from 'reactstrap';

export const FormWrapperForm = styled.div`
  h2 {
    color: #007DC3;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin: 30px 0;
    font-size: 24px;
  }

  label {
    font-weight: 600;
    margin: 0;
    text-align: left;
    display: block;
  }

  /* Adiciona a rolagem horizontal na tabela */
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;  /* Melhor para dispositivos móveis */
  }

  .table th, .table td {
    white-space: nowrap;  /* Evita que o conteúdo das células quebre */
  }

  /* Ajuste de largura para pequenas telas */
  @media (max-width: 768px) {
    .table th, .table td {
      font-size: 12px;
      padding: 8px;
    }
  }

  button[type="submit"] {
    background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
    border-radius: 40px;
    border: none;
    padding: 10px 50px;
    margin-top: 20px;
    font-size: 23px;
    text-align: left;
  }

  button:focus {
    outline: none;
  }

  @media (max-width: 990px) {
    button[type="submit"] {
      width: 100%;
      text-align: center;
      font-size: 18px;
    }
  }
`;

export const InputView = styled(Input)`
  ::placeholder {
    color: #ccc;
  }
`;

export const WrapperEmailList = styled.div`
  display: block;
  border-radius: .25rem;
  padding: 20px 10px;
  text-align: left;
  background: #f5f5f5;

  .badge {
    margin: 0 2px;
  }
`;

export const ButtonRedFlag = styled(Button)`
  background: linear-gradient(268.2deg, #FF7F32 -5.28%, #FF8C00 100%, #FF6600 100%);
  border-radius: 5px;
  border: none;
  padding: 5px 30px;
  font-size: 16px;
  text-align: left;

  &:focus {
    outline: none;
  }

  /* Responsividade para telas pequenas */
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    font-size: 16px;
    padding: 10px 20px;
  }

  /* Em telas médias e grandes */
  @media (min-width: 769px) {
    width: auto;
    padding: 10px 30px;
    font-size: 18px;
  }
`;
export const ButtonOrder = styled(Button)`
  color: white;
  padding: 8px 20px;
  border-radius: 25px;
  border: none;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 18px;
  background: linear-gradient(268.2deg, #00476E -5.28%, #0060A4 100%, #004F8A 100%);
  margin-right: 1px;
  margin-top: 10px;
  white-space: normal;  /* Permite quebra de linha no texto */
  line-height: 1.4; /* Espaço entre as linhas do texto */

  /* Garantir que o botão tenha altura mínima */
  min-height: 40px; /* Define altura mínima */
  
  /* Ajuste de padding para dar mais espaço conforme necessário */
  padding: 10px 20px;

  @media (max-width: 768px) {
    width: 100%; /* O botão vai ocupar 100% da largura da tela em dispositivos menores */
    font-size: 16px;
    min-height: 50px; /* Altura mínima maior em telas menores */
    padding: 12px 20px; /* Aumentar o padding para melhorar a aparência */
  }

  @media (max-width: 380px) {
    font-size: 15px; /* Ajustar ainda mais o tamanho da fonte em telas pequenas */
    min-height: 60px; /* Garantir que o botão fique suficientemente alto em telas muito pequenas */
    padding: 12px 20px;
  }
`;

export const ButtonShow = styled(Button)`
  color: white;
  padding: 8px 20px;
  border-radius: 25px;
  border: none;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 18px;
  background: linear-gradient(268.2deg, #003D61 -5.28%, #005594 100%, #004678 100%);
  margin-right: 1px;
  margin-top: 10px;
  white-space: normal;  /* Permite quebra de linha no texto */
  line-height: 1.4;  /* Ajusta o espaçamento entre as linhas */

  min-height: 50px;  /* Define a altura mínima */
  
  padding: 10px 20px;  /* Ajuste de padding para garantir mais espaço */

  /* Ajustes para telas menores */
  @media (max-width: 768px) {
    width: 100%;  /* Faz o botão ocupar toda a largura em telas menores */
    font-size: 16px;
    min-height: 60px;  /* Aumenta a altura mínima em telas menores */
    padding: 12px 20px;  /* Ajusta o padding para melhor aparência */
  }

  @media (max-width: 380px) {
    font-size: 15px;  /* Ajusta a fonte para telas pequenas */
    min-height: 70px;  /* Aumenta a altura mínima ainda mais */
    padding: 12px 20px;
  }
`;
export const ButtonUpdate = styled(Button)`
  color: white;
  padding: 8px 20px;
  border-radius: 5px;
  border: none;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 18px;
  background: linear-gradient(268.2deg, #003D61 -5.28%, #005594 100%, #004678 100%);
  margin-right: 1px;
  margin-top: 10px;
  white-space: normal;  /* Permite quebra de linha no texto */
  line-height: 1.4;  /* Ajusta o espaçamento entre as linhas */

  min-height: 50px;  /* Define a altura mínima */
  
  padding: 10px 20px;  /* Ajuste de padding para garantir mais espaço */

  /* Ajustes para telas menores */
  @media (max-width: 768px) {
    width: 100%;  /* Faz o botão ocupar toda a largura em telas menores */
    font-size: 16px;
    min-height: 60px;  /* Aumenta a altura mínima em telas menores */
    padding: 12px 20px;  /* Ajusta o padding para melhor aparência */
  }

  @media (max-width: 380px) {
    font-size: 15px;  /* Ajusta a fonte para telas pequenas */
    min-height: 70px;  /* Aumenta a altura mínima ainda mais */
    padding: 12px 20px;
  }
`;

