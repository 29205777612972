import React/* , { useEffect, useState } */ from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import cookie from './services/cookie/index.js';
import Home from './pages/Home/index.jsx';
import Login from './pages/Login/index.jsx';
import Register from './pages/Register/index.jsx';
import NotFoundPage from './pages/NotFound/index.jsx';
import Main from './components/Main.jsx';
import RegisterOtherUser from './pages/RegisterOtherUser/index.jsx';
import ForgotPass from './pages/ForgotPass/index.jsx';
import ReplacePass from './pages/ReplacePass/index.jsx';
import GeneratePin from './pages/GeneratePin/index.jsx';
import InfoVerify from './pages/InfoVerify/index.jsx';
import LivenessResult from './pages/LivenessResult/index.jsx';
import BlockedPage from './pages/BlockedPage/index.jsx';
import SearchIp from './pages/SearchIp/index.jsx';

export default function Router() {

  const token = cookie().get("token");


  return (
    <BrowserRouter>
      {/* Redireciona para a página de login se o token for null */}
      {token === null && <Redirect to="/login" />}
      
      <Switch>
        
    {/*     {!ipFound && <Redirect to="/search-ip" />} */}

        <Route path="/blocked-page" component={BlockedPage} />
        <Route path="/search-ip" component={SearchIp} />
        <Route path="/replace-pass" component={ReplacePass} />
        <Route path="/generate-pin" component={GeneratePin} />
        <Route path="/register" component={Register} />
        <Route path="/info-verify" component={InfoVerify} />
        <Route path="/liveness-result" component={LivenessResult} />
        <Route path="/forgot-pass" component={ForgotPass} />
        <Route path="/register-other-user" component={RegisterOtherUser} />
        <Route exact path="/" render={() => <Main Page={Home} />} />
        <Route path="/login" component={Login} />
        {/* Rota para página não encontrada */}
        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
}
