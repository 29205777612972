import ApiConnection from './connection';


  const saveLog = async (logData) => {
    try {
      const response = await ApiConnection.post('/logviews/save-logs', logData);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Erro ao salvar log');
    }
  };
  

export default function log() {
  return {
    saveLog,
  };
}