import ApiConnection from './connection';

const createPin = async (requestData) => {
  try {
    const response = await ApiConnection.post('/bioconnect/create-pin', requestData);
    return response.data; // Retorna a resposta da API
  } catch (error) {
    if (error.response) {
      // Erro retornado pela API
      const apiError = error.response.data.error || 'Erro desconhecido na API';
      throw new Error(apiError);
    } else if (error.request) {
      // Falha na comunicação com a API
      throw new Error('Erro de conexão com o servidor');
    } else {
      // Outros erros
      throw new Error(error.message || 'Erro desconhecido');
    }
  }
};


const getValidationResult = async (data) => {
  try {
    const response = await ApiConnection.post('/bioconnect/get-validation-result', data);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Erro ao recuperar o resultado da validação');
  }
};
const getLivenessResult = async (data) => {
  try {
    const response = await ApiConnection.post('/bioconnect/get-liveness-result', data);

    // Verificar se a resposta contém o código de erro e a mensagem
    

    return response.data;
  } catch (error) {
    // Aqui é onde você captura a mensagem de erro enviada pela API
    const errorMessage = error.response?.data?.message || 'Erro ao recuperar o resultado da validação. Por favor, tente novamente mais tarde.';

    // Garantir que a mensagem de erro específica seja propagada
    throw new Error(errorMessage);
  }
};






export default function bioconnect() {
  return {
    createPin,
    getValidationResult,
    getLivenessResult,

  };
}
