import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner, FormGroup, Form, Container, Row, Col } from 'reactstrap';
import { ButtonLogin, InputLogin, CloseButton, AlertBox, FormWrapper, SpanText, } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faWindowClose, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/client/api';
import cookie from '../../services/cookie/index';
import { validarTelefone, validarData } from '../../utils/validation.js';
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from 'react-input-mask';
import moment from 'moment';
import { isCpfValid } from '../../utils/cpfValidate.js';


const RegisterOtherView = ({ onClose }) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [birthday, setDataNasc] = useState('');
  const [procFile, setProcFile] = useState('');
  const [cpf, setCpf] = useState('');
  const [tel, setTelefone] = useState('');
  const [mother_name, setMotherName] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [ip, setIp] = useState('');
  const [username, setUsername] = useState('');
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [isBlocked, setIsBlocked] = useState(false);
  const [timer, setTimer] = useState(0);
  const [successAttempts, setSuccessAttempts] = useState(0); // Contador de sucessos consecutivos

  const isMountedRef = useRef(true);

  useEffect(() => {
    const storedIp = cookie().get('userIP');
    const storedUsername = cookie().get('username');

    if (storedIp) {
      setIp(storedIp);
    } else {
      window.location.reload();  // Isso vai recarregar a página
    }
    if (storedUsername) {
      setUsername(storedUsername);
    } else {
      window.location.reload();  // Isso vai recarregar a página
    }
    
    const blockTime = localStorage.getItem('blockTime');
    const now = new Date().getTime();
    if (blockTime && now < blockTime) {
      setIsBlocked(true);
      setTimer(Math.ceil((blockTime - now) / 1000));
      history.replace('/blocked-page');
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [history]);

  const startBlockTimer = useCallback((lockoutTime) => {
    const now = new Date().getTime();
    const blockUntil = now + lockoutTime;

    localStorage.setItem('blockTime', blockUntil);
    cookie().set("blockedIP", cookie().get('userIP'));
    setIsBlocked(true);
    setTimer(lockoutTime / 1000);
    history.replace('/blocked-page');
  }, [history]);
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            localStorage.removeItem('blockTime');
            setIsBlocked(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isBlocked) {
      history.replace('/blocked-page');
      return;
    }

    setInProgress(true);

    if (!recaptchaToken) {
      alert('Por favor, resolva o reCAPTCHA.');
      setInProgress(false);
      return;
    }

    if (!isCpfValid(cpf)) {
      alert('CPF inválido.');
      setInProgress(false);
      return;
    }

    if (!validarTelefone(tel)) {
      alert('Número de telefone inválido. Verifique e tente novamente.');
      setInProgress(false);
      return;
    }

    const dataNascFormatada = moment(birthday, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ssZ');

    if (!validarData(birthday)) {
      alert('Data inválida. Verifique o formato e os valores.');
      setInProgress(false);
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('birthday', dataNascFormatada);
    formData.append('mother_name', mother_name);
    formData.append('cpf', cpf);
    formData.append('tel', tel);
    formData.append('id_categoria', 2);
    

    if (procFile) {
      formData.append('procuracao', procFile);
    }
    formData.append('recaptchaToken', recaptchaToken);


    let statusCode;
    try {
      const token = cookie().get('token');
      const response = await api().userPatient().registerUserPatient(formData, token);
      statusCode = response.id_user ? 200 : "Erro de resposta da API";
   
      if (isMountedRef.current && response.id_user) {
        const newSuccessAttempts = successAttempts + 1;
        setSuccessAttempts(newSuccessAttempts);
    
        // Verifica se atingiu o limite de sucessos consecutivos
        if (newSuccessAttempts >= 5) {
          const lockoutTime = 5 * 60 * 1000; // 5 minutos de bloqueio
          startBlockTimer(lockoutTime);
        }
        onClose();
        window.alert('Solicitação cadastrada com sucesso!');
        history.replace('/');
      } else {
        alert('Erro ao cadastrar paciente: Resposta inválida da API');
        setInProgress(false);
        return;
      }
    } catch (error) {
      statusCode = error.response ? error.response.status : "Erro na rede";

     

      if (error.message === 'CPF already exists') {
        alert('Este CPF já foi cadastrado.');
      } else if (error.message === 'Invalid reCAPTCHA'){
        alert('reCAPTCHA expirou. Atualize a página e tente novamente.');
      }else if (error.message === 'Error retrieving file'){
        alert('Falha ao receber arquivo. Atualize a página e tente novamente.');
      }
      else {
        alert('Ocorreu um erro. Tente novamente mais tarde.');
     }
    
      const newLoginAttempts = loginAttempts + 1;
      setLoginAttempts(newLoginAttempts);
      setSuccessAttempts(0); // Reseta em caso de erro
    
      // Verifica se atingiu o limite de tentativas
      if (newLoginAttempts >= 10) {
        const lockoutTime = 1 * 60 * 1000; // 1 minuto
        startBlockTimer(lockoutTime);
      }
    } finally {
      const logData = {
        laudo: `Cadastro solicitação de paciente - CPF: ${formData.cpf}, Status: ${statusCode}`,
        ip: ip,
        login: username,
      };
    
      try {
        await api().log().saveLog(logData);
      } catch (logError) {
        setInProgress(false);
      }
    
      if (isMountedRef.current) {
        setInProgress(false);
      }
    }
  };    

  const handleDateChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');

    if (value.length > 2) {
      value = `${value.slice(0, 2)}/${value.slice(2)}`;
    }
    if (value.length > 5) {
      value = `${value.slice(0, 5)}/${value.slice(5)}`;
    }

    setDataNasc(value);
  };

  const applyCpfMask = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    const match = cleanedValue.match(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/);
    if (match) {
      return `${match[1]}${match[2] ? '.' + match[2] : ''}${match[3] ? '.' + match[3] : ''}${match[4] ? '-' + match[4] : ''}`;
    }

    return value;
  };

  const onChangeCap = (value) => {
    setRecaptchaToken(value);
  };

  return (
    <FormWrapper>
<Container>
<AlertBox >
    <FontAwesomeIcon icon={faExclamationCircle} style={{ marginRight: '10px', color: '#721c24' }} />
    Este formulário permite solicitar acesso aos exames de pacientes para responsáveis autorizados. É estritamente necessário anexar um documento de procuração válido, com assinatura digital, para que seja possível realizar a validação e autorizar o acesso.<small style={{ display: 'block', marginTop: '5px', color: '#6c757d' }}>
      Em conformidade com a Lei Geral de Proteção de Dados (LGPD).
    </small>
    <small style={{ display: 'block', color: '#6c757d' }}>
      Lei nº 13.709/2018.
    </small>
  </AlertBox>

  <Form onSubmit={handleSubmit}>
    <Row>

    <Col xs={12} md={6}>
      <FormGroup>
        <SpanText>
          Digite o nome do Paciente:
        </SpanText>
        <InputLogin
          type="text"
          placeholder="Digite o nome do Paciente"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </FormGroup>
    </Col>
    <Col xs={12} md={6}>
      <FormGroup>
        <SpanText>
          Digite o CPF do Paciente:
        </SpanText>
        <InputLogin
          type="text"
          placeholder="Digite o CPF do Paciente"
          value={applyCpfMask(cpf)}
          onChange={(e) => setCpf(e.target.value)}
          required
          maxLength="14"
        />
      </FormGroup>
    </Col>
  </Row>

  <Row>
    <Col xs={12} md={6}>
      <FormGroup>
        <SpanText>
          Nome da Mãe do Paciente:
        </SpanText>
        <InputLogin
          type="text"
          placeholder="Nome da Mãe do Paciente"
          value={mother_name}
          onChange={(e) => setMotherName(e.target.value)}
          required
        />
      </FormGroup>
    </Col>
    <Col xs={12} md={6}>
      <FormGroup>
        <SpanText>
          Data de Nascimento do Paciente:
        </SpanText>
        <InputLogin
          type="text"
          placeholder="Data de nascimento do Paciente"
          value={birthday}
          onChange={handleDateChange}
          maxLength="10"
          required
        />
      </FormGroup>
    </Col>
    <Col xs={12} md={6}>
      <FormGroup>
        <SpanText>
          Forneça um número de telefone para contato:
        </SpanText>
        <InputMask
          mask="(99) 99999-9999"
          value={tel}
          onChange={(e) => setTelefone(e.target.value)}
        >
          {(inputProps) => (
            <InputLogin {...inputProps} type="text" placeholder="Forneça um número de telefone disponível para contato" required />
          )}
        </InputMask>
      </FormGroup>
    </Col>
  </Row>

  <Row>
    <Col xs={12} md={12}>
      <FormGroup>
        <SpanText>
          Digite um endereço de e-mail para contato:
        </SpanText>
        <InputLogin
          type="email"
          placeholder="Forneça um e-mail disponível para contato"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </FormGroup>
    </Col>
  </Row>

  <Row><Col xs={12} md={12}>
  <FormGroup>
    <SpanText>
      Anexe o arquivo contendo o documento de procuração com assinatura digital (PDF):
    </SpanText>
    <InputLogin
      type="file"
      onChange={(e) => {
        const file = e.target.files[0];
        if (file && file.type === 'application/pdf') {
          setProcFile(file);
        } else {
          alert('Por favor, envie um arquivo no formato PDF.');
          e.target.value = ''; // Reseta o campo de arquivo se não for PDF
        }
      }}
    />
  </FormGroup>
</Col>

  </Row>

  <Row>
    <Col xs={12} md={12}>
      <ReCAPTCHA
        sitekey="6LfFouQqAAAAAPpmUiTZc3SJDhlMiayo6zljuU32"
        onChange={onChangeCap}
      />
    </Col>
  </Row>

  <Row>
    <Col xs={12} md={12}>
      <ButtonLogin type="submit" disabled={inProgress}>
        {inProgress ? <Spinner size="sm" color="light" /> : 'Enviar solicitação'}
        {!inProgress && <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '10px' }} />}
      </ButtonLogin>
    </Col>
  </Row>
</Form>

        <CloseButton onClick={onClose}> FECHAR
          <FontAwesomeIcon icon={faWindowClose} />
        </CloseButton>
      </Container>
    </FormWrapper>
  );
};

export default RegisterOtherView;
