import React, { useEffect, useState } from 'react'; 
import { Card, Respects, ButtonOther, Footer } from './styles'; // Importe o Footer
import Reports from '../../views/Reports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers } from '@fortawesome/free-solid-svg-icons';
import Slide from '../../components/Slide';
import cookie from '../../services/cookie/index';
import RegisterOtherView from '../../views/RegisterOtherUser';
import { useLocation } from 'react-router-dom';

export default function Home() {
  const [Name, setName] = useState("");
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let isMounted = true; // Flag para verificar se o componente está montado

    const getName = async () => {
      let name = await cookie().get("Name");
      if (isMounted) { // Verifica se o componente ainda está montado antes de atualizar o estado
        setName(name);
      }
    };

    getName();

    // Cleanup: Quando o componente for desmontado, o flag é alterado para false
    return () => {
      isMounted = false;
    };
  }, []); // O useEffect será chamado apenas uma vez quando o componente for montado

  useEffect(() => {
    if (location.state?.successMessage) {
      alert(location.state.successMessage);
    }
  }, [location.state]);

  const handleOpenRegisterModal = () => {
    setShowRegisterModal(true);
  };

  const handleCloseRegisterModal = () => {
    setShowRegisterModal(false);
  };

  return (
    <div>
      <Slide title={<><FontAwesomeIcon icon={faHome} /> Home</>} />

      <div style={{ 
  display: 'flex', 
  justifyContent: 'space-between', 
  alignItems: 'center', 
  marginTop: '20px', 
  flexWrap: 'wrap',  /* Permite que os itens 'quebrem' para uma nova linha se necessário */
  gap: '15px'        /* Espaçamento entre os elementos */
}}>
  <Respects>Olá, <b>{Name}</b>!</Respects>
  <ButtonOther onClick={handleOpenRegisterModal}>
    Sou representante legal do paciente
    <FontAwesomeIcon icon={faUsers} style={{ marginLeft: '10px' }} />
  </ButtonOther>
</div>
      {showRegisterModal && <RegisterOtherView onClose={handleCloseRegisterModal} />}

      <Card>
        <Reports />
      </Card>

      {/* Rodapé */}
      <Footer>
        Precisa de ajuda? Você pode entrar em contato pelo telefone ou WhatsApp através do número <strong>+55 (47) 99186-5855</strong>.
      </Footer>
    </div>
  );
}
