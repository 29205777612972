import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

ReactDOM.render(
  // <React.StrictMode> // Temporariamente comentado
  <App />,
  // </React.StrictMode>, // Temporariamente comentado
  document.getElementById('root')
);
