import React, { useState, useEffect } from 'react';
import { ModalWrapper, ButtonGroup, InputAuth, SendText, ButtonNext, ButtonSend } from './styles';
import api from '../../services/client/api'; 
import cookie from '../../services/cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope,  faPaperPlane, faPhone } from '@fortawesome/free-solid-svg-icons';

const UpdateContact = ({ onClose, tel, mail, token }) => {
  const [preferredContact, setPreferredContact] = useState(null);
  const [emailChoosed, setEmailChoosed] = useState(false);  // Estado para controlar se o e-mail foi escolhido
  const [phoneChoosed, setPhoneChoosed] = useState(false); 
  const [timer, setTimer] = useState(0);
  const [sendingCode, setSendingCode] = useState(false);
  const [code, setCode] = useState('');
  const [resendMessage, setResendMessage] = useState('');
  const [, setError] = useState('');


  const sendMailCode = async () => {
    setSendingCode(true);

    const userName = await cookie().get("username");
    let userIp = cookie().get('userIP'); 
  
    try {
      // Enviar código de recuperação por e-mail
      
      const response = await api().user().sendMailVerification(mail);
  
      if (response.status === 200) {
        setTimer(60); // Começar o timer para o tempo de bloqueio ou reenvio
        setResendMessage('Tente novamente.');
        alert('E-mail enviado com sucesso, verifique o código e insira abaixo.');
      }
  
    } catch (error) {
      const errorMessage = error.response ? error.response.status : 'Erro de rede';
      setError(`Erro ao enviar código de recuperação: ${errorMessage}. Atualize a página e tente novamente.`);
    } finally {
      setSendingCode(false); // Marca como não enviando o código
    } try {
      await api().log().saveLog({
        laudo: `Envio de E-mail para confirmação de contato - E-mail: ${mail}`,
        ip: userIp,
        login: userName || 'Desconhecido',
      });
    } catch (logError) {
      // Tratamento do erro de log
    }
  };
  const sendSmsCode = async () => {
    setSendingCode(true);
  
    const userName = await cookie().get("username");
    let userIp = cookie().get('userIP'); 
  
    try {
      // Verificar e adicionar o código do Brasil (55) se não estiver presente
      if (!tel.startsWith('55')) {
        tel = '55' + tel;
      }
   
      // Enviar código de recuperação por telefone
      const response = await api().user().sendPhoneVerification(tel);
  
      if (response.status === 200) {
        setTimer(60); // Começar o timer para o tempo de bloqueio ou reenvio
        setResendMessage('Tente novamente.');
        alert('SMS enviado com sucesso, verifique o código e insira abaixo.');
      } else {
// Exemplo de exibição de erro na interface
alert("Falha no envio do SMS, tente novamente mais tarde.");

      }
  
    } catch (error) {
      const errorMessage = error.response ? error.response.status : 'Falha no envio do SMS, tente novamente mais tarde.';
      setError(`Erro ao enviar código de recuperação via SMS: ${errorMessage}. Atualize a página e tente novamente.`);
    } finally {
      setSendingCode(false); // Marca como não enviando o código
    }
    try {
      await api().log().saveLog({
        laudo: `Envio de sms para confirmação de contato - Telefone: ${tel}`,
        ip: userIp,
        login: userName || 'Desconhecido',
      });
    } catch (logError) {
      // Tratamento do erro de log
    }
  };
  
  


  const handleCodeChange = async (e) => {
    const value = e.target.value.toUpperCase();
    setCode(value);

    const userName = await cookie().get("username");
    let userIp = cookie().get('userIP'); 
  

    if (/^[A-Za-z0-9]{6}$/.test(value)) {
      try {
        let verificationType; // Variável que vai armazenar o valor

        if (emailChoosed) {
          // Se email foi escolhido, atribui o valor 1
          verificationType = 1;
        } else if (phoneChoosed) {
          // Se telefone foi escolhido, atribui o valor 2 (ou 1, se preferir)
          verificationType = 2;
        }
        
        const response = await api().user().checkVerificationCode(value, token, verificationType);
        
    
        if (response.status === 200) {
          // Armazena o valor no localStorage com base na escolha
          const verificationValue = emailChoosed ? 1 : phoneChoosed ? 2 : null;
          if (verificationValue) {
            localStorage.setItem('verification_form', JSON.stringify(verificationValue));
          }
          alert('Contato verificado com sucesso!');
          await api().log().saveLog({
            laudo: `Confirmação de contato com sucesso - Forma de verificação: ${verificationValue}`,
            ip: userIp,
            login: userName || 'Desconhecido',
          });
          onClose()
        } else {
          setError('Código inválido ou erro na resposta.');
        }
      } catch (error) {
        setError('Erro ao validar, Código inválido.');
      }
    } else {
      setError('O código deve ter exatamente 6 caracteres.');
    }
  };

  // Efeito para verificar se os valores de tel e mail não são nulos
  useEffect(() => {
    if (mail && tel) {
      setPreferredContact('email'); // Definir o e-mail como padrão (pode ser alterado pelo usuário)
    } else {
      // Verificar se o email é nulo
      if (!mail) {
        setPhoneChoosed(true); // Se o email for nulo, define que o telefone foi escolhido
      }
      // Verificar se o telefone é nulo
      if (!tel) {
        setEmailChoosed(true); // Se o telefone for nulo, define que o email foi escolhido
      }
    }
  }, [mail, tel]);
  
  const handleChange = (event) => {
    setPreferredContact(event.target.value);
  };

  const maskEmail = (email) => {
    const [localPart, domain] = email.split('@');
  
    // Máscara para a parte local (antes do @), mostrando 2 primeiros e 1 último caractere
    const maskedLocalPart = localPart.slice(0, 2) + '*'.repeat(localPart.length - 3) + localPart.slice(-1);
  
    // Máscara para o domínio (após o @), mostrando o primeiro caractere e o restante em asteriscos
    const [domainName, domainExt] = domain.split('.');
    const maskedDomain = domainName[0] + '*'.repeat(domainName.length - 1) + '.' + domainExt;
  
    return `${maskedLocalPart}@${maskedDomain}`;
  };
  

  const maskPhone = (phone) => {
    // Máscara do telefone: (**) *****-*089
    return phone ? `(**) *****-*${phone.slice(-3)}` : '';
  };
  

  //com parenteses no ddd (47)
/*   const maskPhone = (phone) => {
    // Máscara do telefone: DDD com parênteses, primeiro número visível, depois asteriscos, e últimos 2 números visíveis
    return phone ? `(${phone.slice(0, 2)}) ${phone.slice(2, 3)}****-**${phone.slice(-2)}` : '';
  }; */
    
  

  
  // Função para controlar a escolha do e-mail
  const handleContinue = () => {
    if (preferredContact === 'email') {
      setEmailChoosed(true); // Marca o e-mail como escolhido
    } else if (preferredContact === 'phone') {
      setPhoneChoosed(true); // Marca o e-mail como escolhido
    }
  };

  useEffect(() => {
    // Se o timer for maior que 0, cria um intervalo para diminuir a cada 1 segundo
    let interval;
  
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 1) {
            return prevTimer - 1;
          } else {
            clearInterval(interval); // Quando o timer chegar a 0, limpa o intervalo
            return 0;
          }
        });
      }, 1000); // diminui a cada 1 segundo
    }
  
    // Limpeza do intervalo quando o componente for desmontado ou o timer for resetado
    return () => clearInterval(interval);
  
  }, [timer]); // O useEffect depende do timer
  
  const isSendCodeButtonDisabled = sendingCode || timer > 0;


  return (
    <ModalWrapper>
  
      {!emailChoosed && !phoneChoosed && (
        <>
          <h3 style={{ fontWeight: 650, color: '#0C2B6E', textAlign: 'center', paddingBottom: 10 }}>
            Você prefere receber a confirmação por E-mail ou SMS?
          </h3>
  
          {mail && tel && (
            <div style={{ textAlign: 'center' }}>
              <div>
              <label style={{ fontSize: '18px' }}>
              <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '5px' }} />
              <input
                type="radio"
                value="email"
                checked={preferredContact === 'email'}
                onChange={handleChange}
                disabled={emailChoosed}
                style={{ width: '19px', height: '19px', marginRight:'5px' }} // Aumentando o tamanho da bolinha
              />
              E-mail: <b> {maskEmail(mail)}</b>
            </label>

              </div>
  
              <div>
              <label style={{ fontSize: '18px' }}>
                  <FontAwesomeIcon icon={faPhone} style={{marginRight:'5px' }}/>
                  
                  <input
                  
                    type="radio"
                    value="phone"
                    checked={preferredContact === 'phone'}
                    onChange={handleChange}
                    disabled={emailChoosed}
                    style={{ width: '19px', height: '19px', marginRight:'5px' }}
                  />
                  Telefone: <b> {maskPhone(tel)}</b>
                </label>
              </div>
  
              <ButtonGroup>
                <ButtonNext color="primary" onClick={handleContinue}>
                  Continuar
                </ButtonNext>
              </ButtonGroup>
            </div>
          )}
        </>
      )}
  
      {phoneChoosed && (
        <>
   <SendText>Clique no botão para enviar o código via SMS para o seu celular</SendText>
   <label style={{ fontSize: '18px' }}>
                  <FontAwesomeIcon icon={faPhone} style={{marginRight:'5px' }}/>
                  Telefone: <b>{maskPhone(tel)}</b></label>
{resendMessage && (
            <ButtonGroup>
              <div style={{ fontSize: '17px', color: '#333', textAlign: 'center' }}>
                {timer > 0
                  ? `Caso não tenha recebido o código, aguarde `
                  : resendMessage}
                {timer > 0 && <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{timer}</span>}
                {timer > 0 ? ` segundos para enviar novamente.` : ''}
              </div>
            </ButtonGroup>
          )}
          <ButtonGroup>
            <ButtonSend color="primary" onClick={sendSmsCode} disabled={isSendCodeButtonDisabled}>
            <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '5px' }} />  Enviar Código
            </ButtonSend>
          </ButtonGroup>
  
       
  
          <InputAuth
            maxLength="6"
            type="text"
            placeholder="Digite o código de verificação"
            value={code}
            onChange={handleCodeChange}
            disabled={!mail}
          />
        </>
      )}
  
      {emailChoosed && (
        <>

        <SendText>Clique no botão para enviar o código ao seu email</SendText>
        <label style={{ fontSize: '18px' }}>
        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '5px' }} />E-mail: <b>{maskEmail(mail)}</b></label>
        {resendMessage && (
            <ButtonGroup>
              <div style={{ fontSize: '17px', color: '#333', textAlign: 'center' }}>
                {timer > 0
                  ? `Caso não tenha recebido o código, aguarde `
                  : resendMessage}
                {timer > 0 && <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{timer}</span>}
                {timer > 0 ? ` segundos para enviar novamente.` : ''}
              </div>
            </ButtonGroup>
          )}
          <ButtonGroup>
            <ButtonSend color="primary" onClick={sendMailCode} disabled={isSendCodeButtonDisabled}>
            <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '5px' }} />  Enviar Código
            </ButtonSend>
          </ButtonGroup>
  
          
  
          <InputAuth
            maxLength="6"
            type="text"
            placeholder="Digite o código de verificação"
            value={code}
            onChange={handleCodeChange}
            disabled={!mail}
          />
        </>
      )}
    </ModalWrapper>
  );
  
  
};

export default UpdateContact;
