import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faHistory, faMicroscope, faChevronDown, faChevronUp, faWindowClose, faBell, faTimesCircle, faTimes, faCheckCircle   } from '@fortawesome/free-solid-svg-icons';
import { MedicalInformation } from '@mui/icons-material';

import { ButtonNext, StepContainer, StepText, StepTexth4, StepTexth5, ModalWrapper, ButtonShow } from './styles';
import api from '../../services/client/api';
import cookie from '../../services/cookie/index';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';

const Justification = ({ onClose, cod_request, cod_event, cod_report, expected_date }) => {
  const [justificationData, setJustificationData] = useState(null);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true); // Controla o estado de minimização
  const [timelineStatus, setTimelineStatus] = useState([false, false, false, false]); // Status de cada item na timeline
  const [timeLineStep, setTimeLineStep] = useState(null); // Armazena o valor de timeLineStep
  const [pendingMessage, setPendingMessage] = useState(""); // Armazena o valor de timeLineStep
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar o modal
  const [timeLineVertical, setTimeLineVertical] = useState(false); // Timeline para dispositivos menores
  const [timeLineHorizontal, setTimeLineHorizontal] = useState(true); // Timeline normal


  useEffect(() => {
    const fetchTimeline = async () => {
      const token = await cookie().get("token");

      try {
        const response = await api().timeline().getTimeLineStep({
          token,
          cod_event
        });       
        // 35 GENERICO, descomente para mostrar
       // response.time_line_step = 35

        //35 ESPECIFICO, comente para mostrar generico,  descomente para especifica
       // response.description = ""  
      
        if (response === "evento nao encontrado") {
          // Se houver um erro, atualize o estado com a mensagem de erro
        
          return; // Sai da função se houver erro
        }
        if (response.time_line_step === 35 && response.description == null) {
          response.description = "Este exame apresenta pendências administrativas que precisam ser resolvidas antes de liberar o acesso. Por favor, entre em contato pelo número [+55 (47) 99186-5855] para mais informações. "
          setPendingMessage(response.description);
          setIsModalOpen(true); // Abre o modal quando a mensagem for preenchida
          return;
        }
      
        if (response.time_line_step === 35)  {
          setPendingMessage(response.description);
          setIsModalOpen(true); // Abre o modal quando a mensagem for preenchida
          return;
        }

        const fetchedTimeLineStep = response.time_line_step;
        setTimeLineStep(fetchedTimeLineStep); // Atualiza o estado com o valor de timeLineStep

        // Atualizando o estado de timelineStatus com base no valor de timeLineStep
        let updatedStatus = [false, false, false, false]; // Inicializa todos os itens como inativos

        // Lógica para determinar quais itens devem ser ativos
        if (fetchedTimeLineStep === 31) {
          updatedStatus = [1, 0, 0, 0];
        } else if (fetchedTimeLineStep === 32) {
          updatedStatus = [1, 1, 0, 0];
        } else if (fetchedTimeLineStep === 33) {
          updatedStatus = [1, 1, 1, 0];
        } else if (fetchedTimeLineStep === 34) {
          updatedStatus = [1, 1, 1, 1];
        } else if (fetchedTimeLineStep === 35) {
          updatedStatus = [0, 0, 0, 0];
        }
        

        setTimelineStatus(updatedStatus);

      } catch (err) {
        console.error("Erro ao buscar a timeline:", err);
        setError('Erro ao buscar a timeline, atualize a página e tente novamente.');
      } finally {
        setLoading(false);
      }
    };

    fetchTimeline();
  }, [cod_event]); 

  
  
  useEffect(() => {
    if (pendingMessage) {
   
    }
  }, [pendingMessage]); // Esse useEffect será acionado sempre que pendingMessage mudar

  // Função para determinar o nome da etapa com base no timeLineStep
  const stepStatus = () => {
    if (timeLineStep === null) {
      return "Carregando..."; // Caso ainda não tenha carregado o timeLineStep
    }

    switch (timeLineStep) {
      case 31:
        return "Preparo para o processamento";
      case 32:
        return "Processamento do exame";
      case 33:
        return "Análise Microscópica";
      case 34:
        return "Entrega do laudo";
/*       case 35:
          return "Pendência administrativa"; */
      default:
        return ""; // Caso o timeLineStep não seja encontrado
    }
  };

  useEffect(() => {

    let isMounted = true; // Flag para verificar se o componente está montado
  
    const fetchJustification = async () => {
      const token = await cookie().get("token");
      const userName = await cookie().get("username");
      let userIp = cookie().get('userIP'); 
  
      try {
        const response = await api().justificate().getJustification({
          token,
          cod_request,
          cod_event ,
          cod_report,
          expected_date
        });
  
        if (isMounted) { // Só atualiza o estado se o componente ainda estiver montado
          if (response) {
            setJustificationData(response);
          } else {
            setError('Em breve mais informações sobre o exame.');
          }
        }
      } catch (err) {
        if (isMounted) {
          setError('Em breve mais informações sobre o exame.');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
        try {
          await api().log().saveLog({
            laudo: `Buscar justificativa para laudo indisponível - Código da requisição: ${cod_request}, Evento: ${cod_event}`,
            ip: userIp,
            login: userName || 'Desconhecido',
          });
        } catch (logError) {
          // Tratamento do erro de log
        }
      }
    };
  
    fetchJustification();
  
    // Cleanup: Define a flag para false quando o componente for desmontado
    return () => {
      isMounted = false;
    };
  }, [cod_request, cod_event, cod_report, expected_date]);

  useEffect(() => {
    // Função para verificar o tamanho da tela e ajustar as timelines
    const timeLineUpdate = () => {
      if (window.innerWidth <= 620) {
        setTimeLineVertical(true); // Tela muito pequena, usa timeline vertical
        setTimeLineHorizontal(false); // Desabilita timeline horizontal
      } else {
        setTimeLineVertical(false); // Usar timeline horizontal
        setTimeLineHorizontal(true);
      }
    };

    // Adiciona o event listener para mudanças no tamanho da tela
    window.addEventListener('resize', timeLineUpdate);

    // Chama a função para ajustar a timeline ao carregar o componente
    timeLineUpdate();

    // Limpeza ao desmontar
    return () => window.removeEventListener('resize', timeLineUpdate);
  }, []);



  return (
    <ModalWrapper>
      {/* Botão de Fechar no canto superior direito */}
      {isModalOpen && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.86)', // Fundo semitransparente
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            width: '80%',
            maxWidth: '1000px',
            textAlign: 'center',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.31)'
          }}>
            <h5 style={{ color: '#0C2B6E' }}>{pendingMessage}</h5>
            <Button onClick={onClose} style={{
              backgroundColor: '#0C2B6E',
              color: 'white',
              border: 'none',
              padding: '6px 20px',
              cursor: 'pointer',
              borderRadius: '5px'
            }}>
              Fechar
              <FontAwesomeIcon icon={faTimes} style={{ color: 'white', marginLeft: 5, fontSize: 20 }} />
            </Button>
          </div>
        </div>
      )}
    <Button
      onClick={onClose}
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        padding: '5px',
        fontSize: '18px',
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
      }}
    >
      <FontAwesomeIcon icon={faTimesCircle} style={{ color: '#0C2B6E' }} />
    </Button>
    {/* Timeline de status do exame */}
    <h2 style={{ paddingTop: '4%', fontWeight: 650, color: '#0C2B6E' }}>Status do exame</h2>
    {timeLineHorizontal && (
        <Timeline
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '30px',
            marginTop: '20px',
            position: 'relative',
          }}
        >
          {/* Linha de conexão horizontal */}
          <TimelineConnector
            sx={{
              position: 'absolute',
              top: '50%',
              left: '0',
              right: '0',
              zIndex: -1,
              width: '100%',
              height: '2px',
              color: 'grey',
            }}
          />
          
          {/* Itens da Timeline Horizontal */}
          {[0, 1, 2, 3].map((index) => (
            <TimelineItem key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <TimelineSeparator sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <TimelineDot color={timelineStatus[index] ? 'primary' : 'grey'}>
                  {/* Ícones conforme o index */}
                  {index === 0 && <MedicalInformation />}
                  {index === 1 && <FontAwesomeIcon icon={faHistory} style={{ fontSize: '22px' }} />}
                  {index === 2 && <FontAwesomeIcon icon={faMicroscope} style={{ fontSize: '24px' }} />}
                  {index === 3 && <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '25px' }} />}
                </TimelineDot>
              </TimelineSeparator>
              <div style={{ borderLeft: '2px dashed grey', height: '40px', margin: '0 auto' }} />
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography color={timelineStatus[index] ? 'black' : 'grey'}>
                  <b>{['Preparo', 'Processamento', 'Microscopia', 'Concluído'][index]}</b>
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      )}
{timeLineVertical && (
  <Timeline
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center', // Alinha todos os itens ao centro
      paddingBottom: '20px',
      marginTop: '10px',
      position: 'relative',
    }}
  >
    {/* Linha de conexão vertical */}
    <TimelineConnector
      sx={{
        position: 'absolute',
        top: '0',
        left: '50%', // Centraliza a linha vertical no meio
        zIndex: -1,
        width: '2px',
        height: '100%',
        color: 'grey',
      }}
    />

    {/* Itens da Timeline Vertical */}
    {[0, 1, 2, 3].map((index) => (
      <TimelineItem
        key={index}
        sx={{
          display: 'flex',
          flexDirection: 'row', // Alinha ícone, linha tracejada e texto na horizontal
          alignItems: 'center', // Alinha os itens verticalmente ao centro
          position: 'relative', // Necessário para o posicionamento do ícone
          marginBottom: '20px', // Distância entre os itens
        }}
      >
        <TimelineSeparator
          sx={{
            display: 'flex',
            flexDirection: 'column', // Mantém os ícones na linha vertical
            alignItems: 'center',
            position: 'absolute', // Posiciona o ícone na linha central
            left: '50%', // Centraliza o ícone horizontalmente
            transform: 'translateX(-50%)', // Ajusta para o centro exato
          }}
        >
          <TimelineDot color={timelineStatus[index] ? 'primary' : 'grey'}>
            {/* Ícones conforme o index */}
            {index === 0 && <MedicalInformation />}
            {index === 1 && <FontAwesomeIcon icon={faHistory} style={{ fontSize: '22px' }} />}
            {index === 2 && <FontAwesomeIcon icon={faMicroscope} style={{ fontSize: '24px' }} />}
            {index === 3 && <FontAwesomeIcon icon={faCheckCircle  } style={{ fontSize: '25px' }} />}
          </TimelineDot>
        </TimelineSeparator>

        {/* Linha tracejada à direita */}
        <div style={{
          borderBottom: '2px dashed grey',
          width: '40px', // Define a largura da linha tracejada
          marginLeft: '120px', // Distância entre o ícone e a linha
        }} />

        {/* Texto à direita */}
        <TimelineContent sx={{ paddingLeft: '5px'}}> {/* Alinhando o texto à direita */}
          <Typography color={timelineStatus[index] ? 'black' : 'grey'}>
            <b style={{fontSize:'13px',}}>{['Preparo', 'Processamento', 'Microscopia', 'Concluído'][index]}</b>
          </Typography>
        </TimelineContent>
      </TimelineItem>
    ))}
  </Timeline>
)}




   {/* Status de Carregamento */}
      {loading && (
        <StepContainer>
          <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'orange', fontSize: '50px' }} />
          <StepText>Carregando justificativa...</StepText>
        </StepContainer>
      )}

              <StepContainer >
                <StepTexth5 style={{marginTop:25}}>
                  <b>Seu exame encontra-se na etapa de: </b>{stepStatus()}
                </StepTexth5>
              </StepContainer>

      {/* Exibição de Erros */}
      {!loading && error && (
        <StepContainer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      
          <StepText>{error}</StepText>
          <FontAwesomeIcon icon={faBell} style={{ color:'#00AFEF', fontSize: '25px',}} />
        </StepContainer>
      )}
   

{!loading && !error && justificationData && (
        <>
          <StepContainer>
          <StepTexth4 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

  <ButtonShow onClick={() => setIsCollapsed(!isCollapsed)}>   Detalhes do processo 
    <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} style={{ fontSize: '18px', color: '#fff' }} />
  </ButtonShow>
</StepTexth4>

          </StepContainer>
      

          {!isCollapsed && (
            <>
           
              {justificationData.greeting_text?.trim() && (
                <StepContainer>
                  <StepText>{justificationData.greeting_text}</StepText>
                </StepContainer>
              )}

              {justificationData.message_text?.trim() && (
                <StepContainer>
                  <StepText>{justificationData.message_text}</StepText>
                </StepContainer>
              )}

              {justificationData.commitment?.trim() && (
                <StepContainer>
                  <StepText><b>{justificationData.commitment}</b></StepText>
                </StepContainer>
              )}

              {justificationData.instruction?.trim() && (
                <>
                  <StepContainer>
                    <StepTexth5><u><b>Instruções:</b></u></StepTexth5>
                  </StepContainer>
                  <StepContainer>
                    <StepText>{justificationData.instruction}</StepText>
                  </StepContainer>
                </>
              )}

              {justificationData.contact_details?.trim() && (
                <StepContainer>
                  <StepText>{justificationData.contact_details}</StepText>
                </StepContainer>
              )}

              {justificationData.farewell?.trim() && (
                <StepContainer>
                  <StepText>{justificationData.farewell}</StepText>
                </StepContainer>
              )}

              {justificationData.contact?.trim() && (
                <StepContainer>
                  <StepText>
                    Caso tenha dúvidas, nossa equipe está à disposição. Você pode entrar em contato pelo telefone [número] ou por e-mail [endereço].
                  </StepText>
                </StepContainer>
              )}
            </>
          )}
        </>
      )}

      <StepContainer>
        <ButtonNext color="secondary" onClick={onClose}>
          Fechar  <FontAwesomeIcon icon={faWindowClose} style={{marginLeft: 5, fontSize: '20px', color: '#fff' }} />
        </ButtonNext>
      </StepContainer>
    </ModalWrapper>
  );
};

export default Justification; 