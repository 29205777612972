// src/pages/Login/styles.js

import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh);
  width: 100%;
  display: flex;
  position: relative;

  @media (max-width: 900px) {
    display: block;
  }
`;

export const Div = styled.div``;

export const Footer = styled.footer`
  background-color: #f8f9fa;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: #6c757d;
  border-top: 1px solid #ddd;

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const LeftDiv = styled.div`
  flex: 2;
  height: 100%;
  background: url(${(props) => props.background});
  background-size: 100%;
  padding: 100px;
  
  position: relative;

  h1 {
    position: absolute;
    width: calc(100% - 200px);
    height: 150px;
    left: 100px;
    bottom: 100px;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 50px;
    color: #FFF;
    text-shadow: -2px 2px 8px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 900px) {
    padding: 20px;
    height: 300px;
    background-size: 100%;

    h1 {
      width: calc(100% - 100px);
      left: 50px;
      bottom: 50px;
      font-size: 30px;
      line-height: 30px;
    }
  }

  @media (max-width: 470px) {
    background-size: auto 100%;
    height: 400px;

    h1 {
      top: 100px;
    }
  }
`;

export const RightDiv = styled.div`
  background: #FFF;
  text-align: center;
  flex: 1;
  height: 100%;
  padding: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: #0C2B6E;
    font-family: 'Raleway', sans-serif;
    margin: 50px 0;
    font-weight: 700;
  }

 

  @media (max-width: 900px) {
    padding: 40px;
    height: 700px;
    h1 {
      margin: 10px 0;
    }
  }
`;

export const NotificationBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding: 20px;
  width: 600px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-family: 'Raleway', sans-serif;
  text-align: center;

  p {
    color: #0C2B6E;
    font-weight: 400;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #0C2B6E;
`;


