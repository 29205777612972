import React from 'react';
import { Container, CenterDiv, } from './styles.js';
import RegisterOtherUserView from '../../views/RegisterOtherUser/index.jsx';



function RegisterOtherUser() {
  return (
    <Container>
      <CenterDiv>

        <RegisterOtherUserView />
    
      </CenterDiv>
    </Container>
  );
}

export default RegisterOtherUser;
