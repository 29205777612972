import styled from 'styled-components';

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    font-size: 21px;
    text-align: center;
   
  }

     h6 {
    cursor: pointer;
    text-align: center;
   
  }


  `;




export const Liveness = styled.img`
position: relative; // Certifique-se de que o posicionamento relativo está ativo
width:270px;

 @media (max-width: 480px) {
    width:220px;
   
}
`;


export const CloseButtonDown = styled.button`
  color: #F8F8F8;
  padding: 8px 20px;
  border-radius: 27px;
  border: none;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 18px;
  height: 55px;
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  margin-top: 5px;
  width: 100%;
  max-width: 200px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  width: 100%;
`;

// Media Queries para dispositivos móveis
export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: auto;
  max-width: 730px;
  max-height: 750px;
  overflow-y: auto;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4);
  z-index: 1001;

  @media (max-width: 768px) {
    width: 95%;
    padding: 15px;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: auto;
    padding: 10px;
  }
`;





