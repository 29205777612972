import styled from 'styled-components';
import { Button } from 'reactstrap';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px; /* largura fixa */
  height: 400px; /* altura fixa */
  
  max-width: 700px; 
  max-height: 85vh;
  overflow-y: auto;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;

  display: flex;
  flex-direction: column; /* Elementos dispostos em coluna */
  align-items: center; /* Centraliza horizontalmente */
  justify-content: center; /* Centraliza verticalmente */

  /* Media Query para telas menores */
  @media (max-width: 768px) {
    width: 90%; /* Para telas menores, a largura será 90% */
    height: auto; /* Altura ajustável para telas menores */
    padding: 15px; /* Menos padding */
  }
`;

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  
  /* Ajuste para telas menores */
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 10px;
  }
`;

export const StepText = styled.h5`
  margin: 0;
  text-align: center;

  /* Ajuste no tamanho da fonte para telas menores */
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const WarningText = styled.div`
  color: #333;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center; /* Alinha o ícone com o texto */
`;

export const ButtonNext = styled(Button)`
  width: 50%;
  padding: 10px 25px;
  border-radius: 30px;
  border: none;
  font-size: 18px;
  font-weight: 600; /* Deixa a fonte mais grossa (semi-negrito) */
  background-color: #25D366; /* Cor verde do WhatsApp */
  margin-top: 20px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Cor do texto branca */
  cursor: pointer; /* Cursor de mão ao passar o mouse */
  
  /* Alterando a cor ao passar o cursor */
  &:hover {
    color: black; /* Muda a cor do texto para preto */
    text-decoration: none; /* Remover sublinhado, se houver */
  }

  /* Ajuste no tamanho do botão para telas menores */
  @media (max-width: 768px) {
    width: 80%; /* Ocupa 80% da largura */
    padding: 12px 20px; /* Padding reduzido */
    font-size: 16px; /* Tamanho da fonte menor */
  }

  a {
    color: inherit; /* Herda a cor do botão */
    &:hover {
      color: black; /* Cor preta quando o mouse passar sobre o link */
    }
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  margin: 10px; 
  font-size: 19px;
  font-weight: bold;
  cursor: pointer;
  top: 10px;
  right: 10px;
  color: #0C2B6E;

  /* Ajustes para botões em telas pequenas */
  @media (max-width: 768px) {
    font-size: 16px; /* Tamanho da fonte menor */
    margin: 5px;
  }
`;
