import styled from 'styled-components';

import { Button, Input } from 'reactstrap';



export const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 900px;
  min-height: 50vh;
  background-color: #f8f9fa;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.31);
  z-index: 1000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  /* Centralizando todo o conteúdo */

  @media (max-width: 768px) {
    width: 90%;
    max-width: 90%;
    padding: 15px;
  }

  @media (max-width: 480px) {
    width: 95%;
    padding: 10px;
  }
`;

export const InputAuth = styled(Input)`
  margin-bottom: 20px;
  height: 50px;
  border-radius: 25px;
      width: 40%;
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;  /* Certifique-se de que o conteúdo é centralizado verticalmente */
  width: 100%;  /* Se necessário, adicione essa linha */
`;

export const SendText = styled.h4`
  font-size: 20px;
  margin-bottom: 10px;
   font-weight: 650px;
  text-align: center;
  padding: 5px;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const ButtonNext = styled(Button)`
  width: 85%;
  padding: 10px;
  border-radius: 25px;
  font-size: 18px;
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  border: none;
  font-weight: 650; /* Corrigido: removido o "px" */
`;


export const ButtonSend = styled(Button)`
  width: 30%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 25px;
  font-size: 18px;
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  border: none;
   font-weight: 650; 
`;