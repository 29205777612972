import styled from 'styled-components';
import { Button, Input } from 'reactstrap';

export const ButtonLogin = styled(Button)`
  width: 100%;
  border-radius: 40px;
  text-align: left;
  padding: 10px 25px;
  border: none;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 20px;
  height: 50px;
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  position: relative;

  svg {
    position: absolute;
    right: 25px;
    top: 15px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    height: 45px;
    padding: 8px 20px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza horizontalmente */
  justify-content: center; /* Centraliza verticalmente */
  height: 100vh; /* Ocupa toda a altura da tela */
  width: 100%; /* Ocupa toda a largura do pai */
  padding: 0 20px; /* Adiciona um pouco de espaçamento lateral para telas pequenas */
  position: relative; /* Adiciona um contêiner de posicionamento relativo */

  @media (max-width: 767px) {
    height: auto; /* Evita que o formulário ultrapasse a tela */
    padding: 20px 10px;
  }
`;


export const LogoImage = styled.img`
  width: 200px;   /* Agora o tamanho será ajustado com base no contêiner pai */
  height: auto;  /* Mantém a proporção da imagem */
  position: relative;
  top: 25px;
  @media (max-width: 400px) {
    width: 100%;   /* Ajusta o tamanho em telas menores */
    max-width: 150px; /* Logo menor em dispositivos móveis */
  }
`;


export const InputLogin = styled(Input)`
  height: 50px;
  width: 100%; /* 100% da largura do container pai */
  max-width: 400px; /* Limita a largura máxima */
  border-radius: 40px;
  text-align: left;
  padding: 10px 25px;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;

  @media (max-width: 767px) {
    font-size: 14px; /* Reduz o tamanho da fonte em telas pequenas */
    padding: 10px 20px; /* Ajusta o padding para ser mais confortável */
  }
`;

export const RegisterLink = styled.span`
  display: flex;
  align-items: center; /* Garante que o ícone e o texto fiquem alinhados verticalmente */
  justify-content: center;
  cursor: pointer;
  color: #0C2B6E;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 17px;
  text-decoration: underline;
  gap: 8px; /* Adiciona um espaço consistente entre o texto e o ícone */
  
  margin-top: 0; /* Removido para garantir que os links fiquem alinhados */
  
  span {
    display: flex;
    align-items: center; /* Alinha o ícone com o texto */
  }

    @media (max-width: 1330px) {
    font-size: 15px;
    flex-direction: column; /* Coloca o ícone abaixo do texto */
    gap: 4px; /* Ajuste o gap para dar um pouco de espaçamento entre o texto e o ícone */
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row; /* Alinha os links horizontalmente */
  justify-content: center; /* Alinha os links ao centro */
  width: 100%;
  margin-top: 40px;
  gap: 25px; /* Adiciona espaçamento entre os links */
  align-items: center; /* Alinha os links verticalmente no centro */

  @media (max-width: 767px) {
    flex-direction: column; /* Coloca os links um abaixo do outro em telas pequenas */
    gap: 15px; /* Ajuste do espaçamento entre os links */
  }
`;

export const ForgotPassLink = styled.span`
  display: flex;
  align-items: center; /* Garante que o ícone e o texto fiquem alinhados verticalmente */
  justify-content: center;
  cursor: pointer;
  color: #0C2B6E;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 17px;
  text-decoration: underline;
  gap: 5px; /* Adiciona um espaço consistente entre o texto e o ícone */

  margin-top: 0; /* Ajustado para garantir que fiquem alinhados */
  
  span {
    display: flex;
    align-items: center; /* Alinha o ícone com o texto */
  }

  @media (max-width: 1330px) {
    font-size: 15px;
    flex-direction: column; /* Coloca o ícone abaixo do texto */
    gap: 4px; /* Ajuste o gap para dar um pouco de espaçamento entre o texto e o ícone */
  }
`;


export const EyeIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: #555;

  svg {
    margin-right: 5px;
  }
`;


