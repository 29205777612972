import ApiConnection from './connection';

const getJustification = async ({token, cod_request, cod_report, cod_event, expected_date }) => {
    try {
      // Enviar os dados no corpo da requisição
      const response = await ApiConnection.post('progressreport/get-justificate', {
        cod_request: cod_request, // Inclui codRequest no corpo
        cod_report: cod_report,   // Inclui codReport no corpo
        cod_event: cod_event,
        expected_date: expected_date,    
      },  { headers: { Authorization: `Bearer ${token}` }});
  
      // Retorna os dados da resposta
      return response.data;
    } catch (error) {
      // Trata erros e retorna a mensagem de erro adequada
      const errorMessage = error.response?.data?.error || 'Erro ao buscar justificativa';
      throw new Error(errorMessage);
    }
  };

export default function justificate() {
  return {
    getJustification, // Exporta a função getJustification
  };
}
