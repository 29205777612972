import report from './report';
import user from './user';
import bioconnect from './bioconn';
import log from './log';
import patient from './patient';
import justificate from './Justificate';
import timeline from './timeline';
import userPatient from './userPatient'
const api = () => {

  return {
    report,
    userPatient,
    justificate,
    patient,
    user,
    bioconnect,
    log,
    timeline
  }
}

export default api;