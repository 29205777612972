import styled from 'styled-components';

export const Container = styled.div`
h1 {
    color: #0C2B6E;
    font-family: 'Raleway', sans-serif;
    margin: 50px 0;
    font-weight: 700;
  }
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f0f2f5;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
`;

export const CenterDiv = styled.div`
  background: #FFF;
  text-align: center;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  
  @media (max-width: 400px) {
  padding: 5px;
  }
`;