import React, { useState } from 'react';
import { Container } from './styles.js';  // Supondo que você tenha esse arquivo de estilo
import SearchIpView from '../../views/SearchIp/index.jsx';

const SearchIp = () => {
  const [ipObtido, setIpObtido] = useState(false);

  const handleIpFetched = () => {
    setIpObtido(true);
  };

  return (
    <Container>
      <SearchIpView onIpFetched={handleIpFetched} />
      {ipObtido}
    </Container>
  );
};

export default SearchIp;
