import styled from 'styled-components';
import { Button } from 'reactstrap';

export const StepTexth4 = styled.h4`
  margin: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

export const StepTexth5 = styled.h5`
  margin: 0;
  font-size: 22px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

export const StepText = styled.p`
  font-size: 16px;
  margin: 0;
  text-align: center;
  padding: 5px;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const ButtonShow = styled(Button)`
  padding: 8px 20px;
  border-radius: 25px;
  border: none;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 18px;
  height: 50px;
  background-color: #0C2B6E;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease, transform 0.3s ease;

  &:hover {
    transform: scale(1.10);
  }

  & svg {
    transition: transform 0.3s ease;
    margin-left: 10px;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 6px 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 5px 12px;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px; /* Aumentar a largura */
  max-width: 900px;
  height: auto;
  min-height: 70vh;
  max-height: 85vh;
  overflow-y: auto;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.31);
  z-index: 1000;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 768px) {
    width: 90%;
    max-width: 90%;
    padding: 15px;
  }

  @media (max-width: 480px) {
    width: 95%;
    padding: 10px;
  }
`;


export const StepContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    margin: 8px 0;
  }

  @media (max-width: 480px) {
    margin: 5px 0;
  }
`;


export const ButtonNext = styled(Button)`
  width: 15%;
  padding: 10px 25px;
  border-radius: 4px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  background-color: #0C2B6E;
  margin-top: 20px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 40%;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    width: 60%;
    font-size: 14px;
  }
`;

