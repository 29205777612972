import React, { } from 'react';
import {  ModalContainer, StepContainer, CloseButtonDown, ButtonContainer, Liveness } from './styles';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LivenessReturn from '../../assets/datavalid4.png'; // Substitua pelos caminhos das suas imagens

const StepLiveness4 = ({ onClose, onBack }) => {
 
  return (
   <ModalContainer>
         <StepContainer>
               <h4 style={{marginLeft:'10px'}}>
            Realize a biometria e assim que concluir <br /> retorne e clique no botão abaixo
               </h4>  
             </StepContainer>
    
 
             <StepContainer>
    
                   <Liveness
                     src={LivenessReturn}
                     alt="LivenessReturn"
                   />
                 </StepContainer>
                 <ButtonContainer>
             
                     <CloseButtonDown onClick={onClose}>
                       Continuar
                       <FontAwesomeIcon icon={faArrowAltCircleRight} style={{ marginLeft: 5 }} />
                     </CloseButtonDown>
              
                   </ButtonContainer>
                   <StepContainer>
                     <h6 onClick={onBack}>
                     <u><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Voltar instrução</u>
                     </h6>
                     </StepContainer>
                 
     </ModalContainer>
     
  );
};

export default StepLiveness4;
