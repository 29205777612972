import ApiConnection from './connection';



const getPatientData = async ({ cpf: cpf_user }) => {
  try {
    const response = await ApiConnection.post('/patients/get-patient-by-cpf', { cpf_patient: cpf_user });
    return response.data;
  } catch (error) {

    return null; // Retorna null para manter a consistência do retorno
  }
};

export default function patient() {
  return {
    getPatientData,
  };
}
