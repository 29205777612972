import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Isso é necessário para que a logo se posicione corretamente dentro do container */
`;

