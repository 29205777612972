import React, { useState, useCallback, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import GeneratePinView from '../../views/GeneratePin/index.jsx';
import DownloadDv from '../../components/DownloadDv/index.jsx';  
import { Container, LogoImage, BackLink, Footer, Overlay, InputCpf, ButtonNext, ModalCpf } from './styles';
import logo from '../../assets/LogoNeg2.png';
import logoPos2 from '../../assets/LogoPos2.png'; // Importe a nova logo
import api from '../../services/client/api';
import cookie from '../../services/cookie/index.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faUser, faArrowRight } from '@fortawesome/free-solid-svg-icons'; 
import { useHistory } from 'react-router-dom'; // Importando o hook useHistory para redirecionamento
import { isCpfValid } from '../../utils/cpfValidate.js';
import useBlockedIP from '../../utils/useBlockedIP.js';

function GeneratePin() {
  const [showDownloadDv, setShowDownloadDv] = useState(true); 
  const [showGeneratePinView, setShowGeneratePinView] = useState(false);
  const [showCpfForm, setShowCpfForm] = useState(true); // Controla a exibição do formulário de CPF
  const [cpf, setCpf] = useState('');
  const history = useHistory(); // Hook para redirecionamento de página
  const [inProgress, setInProgress] = useState(false);
  const [ip, setIp] = useState('');
  const [isBlocked, setIsBlocked] = useState(false); // Estado para controle de bloqueio
  const [, setLoginAttempts] = useState(0); // 
  const [isMounted, setIsMounted] = useState(true);
  const [timer, setTimer] = useState(0);
  const [logoSrc, setLogoSrc] = useState(logo); 
  const [showLogo, setShowLogo] = useState(true);
  const [showFooter, setShowFooter] = useState(true);

  useBlockedIP(); // Verifica o IP bloqueado

  useEffect(() => {
    const savedIp = cookie().get("userIP"); 
    const blockedIp = cookie().get("blockedIP"); // Obtém o IP bloqueado do cookie
    if (savedIp) {
        setIp(savedIp); 
        if (blockedIp === savedIp) { // Verifica se o IP está bloqueado
            const blockTime = localStorage.getItem('blockTime');
            if (blockTime && new Date().getTime() < blockTime) {
                setIsBlocked(true);
                history.replace('/blocked-page'); // Redireciona para a página de bloqueio
            }
        }
    } else {
        history.replace('/search-ip'); // Redireciona para a página de busca de IP
    }
  }, [history]);

  const startBlockTimer = useCallback((lockoutTime) => {
    const now = new Date().getTime();
    const blockUntil = now + lockoutTime;
    localStorage.setItem('blockTime', blockUntil);  // Salva o tempo de bloqueio no localStorage
    cookie().set("blockedIP", ip);  // Salva o IP do usuário no cookie
    setIsBlocked(true);
    setTimer(lockoutTime / 1000);  // Define o temporizador em segundos
    history.replace('/blocked-page');  // Redireciona para a página de bloqueio
  }, [history, ip]);

  useEffect(() => {
    const checkBlock = () => {
      const blockTime = localStorage.getItem('blockTime');
      const now = new Date().getTime();
      if (blockTime && now < blockTime) {
        setIsBlocked(true);
        setTimer(Math.ceil((blockTime - now) / 1000));
        history.replace('/blocked-page');  // Redireciona para a página de bloqueio
      }
    };
    checkBlock();
    return () => {
      setIsMounted(false);
    };
  }, [history]);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer <= 1) {
            clearInterval(interval); // Limpa o intervalo
            localStorage.removeItem('blockTime'); // Limpa o tempo de bloqueio
            setIsBlocked(false); // Reinicia o bloqueio
            setLoginAttempts(0)
            localStorage.clear()
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);



  const handleCloseDownloadDv = () => {
    setShowDownloadDv(false);
    setShowGeneratePinView(true);
  };

  useEffect(() => {
    if (showGeneratePinView) {
      setShowLogo(false);
      setShowFooter(false);
    }
  }, [showGeneratePinView]);
  

  const handleCpfSubmit = async () => {

    let userIp = cookie().get('userIP'); 
  
    if (isBlocked) {
      history.replace('/blocked-page');  // Redireciona para a página de bloqueio
      return;
    }
  
    if (inProgress) return; // Evita requisições simultâneas
  
    setInProgress(true);


    if (!isCpfValid(cpf)) {
      alert('CPF inválido.');
      setInProgress(false);
      return;
    }

     // Função para verificar se o item está expirado
function isExpired(expirationTimestamp) {
  const currentTime = new Date().getTime();
  return currentTime > expirationTimestamp;
}

// Função para calcular o tempo restante até a expiração
function timeRemaining(expirationTimestamp) {
  const currentTime = new Date().getTime();
  const timeDiff = expirationTimestamp - currentTime;

  if (timeDiff <= 0) {
    return "Já expirou";  // Caso o tempo tenha expirado
  }

  const hours = Math.floor(timeDiff / (1000 * 60 * 60)); // Horas
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60)); // Minutos
  const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000); // Segundos

  return `${hours}h ${minutes}m ${seconds}s`; // Retorna o tempo restante em formato legível
}

// Recuperar o item do localStorage
const livenessData = localStorage.getItem('livenessAproved');

// Verificar se o item existe e não expirou
if (livenessData) {
  const parsedData = JSON.parse(livenessData);

  // Verificar se o item expirou
  if (isExpired(parsedData.expiration)) {
    localStorage.removeItem('livenessAproved'); // Exclui o item do localStorage
    
  } else {
    // Verificar se o CPF corresponde e se o usuário atingiu o limite de tentativas
    if (parsedData.cpf === cpf.replace(/\D/g, '') && parsedData.approved === true && parsedData.attempts >= 3) {

      // Calcular o tempo restante para expirar
      const remainingTime = timeRemaining(parsedData.expiration);
      
      // Exibir o tempo restante no alerta
      alert(`Você atingiu o máximo de tentativas de realizar a prova de vida, tente novamente em ${remainingTime}.`);
      
      setInProgress(false);  // Aqui você pode controlar o estado de progresso
      return;
    } else {

    }
  }
} else {

}

    try {
      if (cpf) {
        const response = await api().userPatient().VerificarCpf(
           cpf.replace(/\D/g, '')
        );
        if (isMounted) {


        // Verificar se o CPF já está cadastrado
        if (response.output === true) {
          const storedAttempts = Number(localStorage.getItem('loginAttempts')) || 0;
 
          const newLoginAttempts = storedAttempts + 1;
          localStorage.setItem('loginAttempts', newLoginAttempts); // Persiste no localStorage

          setLoginAttempts(newLoginAttempts); // Atualiza o estado de tentativas

          if (newLoginAttempts >= 9) {
            const lockoutTime = 1 * 60 * 1000; // 1 minuto
            startBlockTimer(lockoutTime); // Inicia o temporizador
            history.replace('/blocked-page'); // Redireciona para a página de bloqueio
          }
          window.scrollTo({
            top: document.body.scrollHeight, // A posição para onde a página deve rolar (final da página)
            behavior: 'smooth' // Animação suave de rolagem
          });
          alert("CPF já cadastrado. Informe suas credenciais na página de login.");
          history.replace('/login')

        } else {
          // Se o CPF não estiver cadastrado, desabilita o modal de CPF
          localStorage.setItem('user_data', JSON.stringify({ cpf: cpf.replace(/\D/g, '') }));

          setShowCpfForm(false);
          setLogoSrc(logoPos2); 
        }
      }
    }
    } catch (error) {
      console.error("Erro ao verificar CPF:", error);
    }finally {
      if (isMounted) {
  
      }
      try {
        await api().log().saveLog({
          laudo: `Buscar disponibilidade do CPF - CPF: ${cpf}`,
          ip: userIp,
          login: cpf.replace(/\D/g, ''),
        });
      } catch (logError) {

      }
    
      setInProgress(false); // Libera a requisição após o término
    }
  };

  const handleLoginRedirect = () => {
    history.push('/login'); // Redireciona para a página de login
  };





  const applyCpfMask = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    const match = cleanedValue.match(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/);
    if (match) {
      return `${match[1]}${match[2] ? '.' + match[2] : ''}${match[3] ? '.' + match[3] : ''}${match[4] ? '-' + match[4] : ''}`;
    }

    return value;
  };

  const isFormValid = cpf.length >= 14;

  const shouldHideButton = !isFormValid || isBlocked || inProgress;


  return (
    <div>
      <Container >
        <Overlay visible={showCpfForm} /> 

  
        {/* Exibe a caixa de CPF */}
        {showCpfForm && (
          <ModalCpf >
            <InputCpf 
              type="text" 
              placeholder="Digite seu CPF" 
              value={applyCpfMask(cpf)}
              maxLength="14"
              required
              onChange={(e) => setCpf(e.target.value)} // Atualiza o estado do CPF
            />

            {!shouldHideButton && (
            <ButtonNext onClick={handleCpfSubmit}>

            {inProgress ? <Spinner size="sm" color="light" /> : 'Continuar'}
            {!inProgress && <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '10px' }} />}

            </ButtonNext>
              )}
            <BackLink onClick={handleLoginRedirect}>
              Fazer Login<FontAwesomeIcon icon={faUser}  style={{marginLeft: 5}}/>
            </BackLink>
          </ModalCpf>
        )}

        {/* Exibe o GeneratePinView apenas após fechar o DownloadDv */}
        {showGeneratePinView && <GeneratePinView />}

        {/* Exibe o DownloadDv enquanto necessário */}
        {showDownloadDv && <DownloadDv onClose={handleCloseDownloadDv} />}

        {/* Exibe a logo na frente de tudo */}
        {showLogo && (
        <LogoImage src={logoSrc} alt="Logo CEDAP" />
        )}
      
      </Container>

      {/* Exibe o footer na frente de tudo */}
      {showFooter && (
      <Footer>
        Precisa de ajuda? Você pode entrar em contato pelo telefone ou WhatsApp através do número <strong>+55 (47) 3422-9607</strong>.
      </Footer>
      )}
    </div>
  );
}

export default GeneratePin;
