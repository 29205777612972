import React, { useState, useEffect, useCallback } from 'react'; 
import { useHistory } from 'react-router-dom';
import { Alert, Spinner, Row, Col, FormGroup } from 'reactstrap';
import api from '../../services/client/api';
import { Container, InputAuth, Form, ButtonAuth, InstructionText, EyeIcon, LogoImage } from './styles';
import { faKey, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cookie from '../../services/cookie';
import logo from '../../assets/LogoPos2.png';

const ReplacePassView = () => {
    const history = useHistory();
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [error, setError] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);
    const [timer, setTimer] = useState(0);
    const [successAttempts, setSuccessAttempts] = useState(0); // Contador de sucessos consecutivos
    const [, setIp] = useState('');

    const username = cookie().get("username");

    useEffect(() => {
        const forcePasswordChange = cookie().get("forcePasswordChange");
        const parsedCookie = forcePasswordChange ? JSON.parse(forcePasswordChange) : null;

        if (!parsedCookie || parsedCookie.force !== true) {
            history.replace('/');
        }

        const storedIp = cookie().get("userIP");
        if (storedIp) {
            setIp(storedIp);
        }
        if(!storedIp){
            history.replace('/search-ip');
        }

        // Verifica se o usuário está bloqueado
        const blockTime = localStorage.getItem('blockTime');
        const now = new Date().getTime();
        if (blockTime && now < blockTime) {
            setIsBlocked(true);
            setTimer(Math.ceil((blockTime - now) / 1000));
            history.replace('/blocked-page');
        }
    }, [history]);

    const startBlockTimer = useCallback((lockoutTime) => {
        const now = new Date().getTime();
        const blockUntil = now + lockoutTime;

        localStorage.setItem('blockTime', blockUntil);
        cookie().set("blockedIP", cookie().get('userIP'));
        setIsBlocked(true);
        setTimer(lockoutTime / 1000);
        history.replace('/blocked-page');
    }, [history]);

    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        localStorage.removeItem('blockTime');
                        setIsBlocked(false);
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [timer]);

    const handleChangePassword = async (e) => {
        e.preventDefault();
        
        if (isBlocked) {
            (history.replace('/blocked-page'));
            return;
        }

        if (!password || password.length < 8) {
            setError('A senha deve ter no mínimo 8 caracteres.');
            return;
        }

        if (password !== passwordConfirmation) {
            setError('As senhas não correspondem.');
            return;
        }

        setInProgress(true);
        setError('');

        if (!username) {
            setError('Username do usuário não encontrado.');
            setInProgress(false);
            return;
        }

        const ip = cookie().get("userIP");
        let statusCode;

        try {
            const response = await api().user().updatePass(username, { password, passwordConfirmation });
            statusCode = response.status;

            if (statusCode === 200) {
                // Incrementa o contador de sucessos
                const newSuccessAttempts = successAttempts + 1;
                setSuccessAttempts(newSuccessAttempts);

                // Verifica se atingiu o limite de sucessos consecutivos
                if (newSuccessAttempts >= 5) {
                    const lockoutTime = 5 * 60 * 1000; // 5 minutos de bloqueio
                    startBlockTimer(lockoutTime);
                } else {
                    cookie().del("forcePasswordChange");
                    cookie().del("username");
                    window.alert('Senha alterada com sucesso! Realize o login para acessar.');
                    history.replace('/login');
                }
            } else {
                setError(response.message || 'Erro ao alterar a senha. Tente novamente.');
            }
        } catch (err) {
            statusCode = err.response ? err.response.status : 'Erro de rede';
            setError('Erro ao alterar a senha. Tente novamente.');

            // Redefine o contador de sucessos em caso de falha
            setSuccessAttempts(0);
        } finally {
            const logData = {
                laudo: `Alteração de senha - Usuário: ${username}, Status: ${statusCode}`,
                ip: ip,
                login: username,
            };

            try {
                await api().log().saveLog(logData);
            } catch (logError) {

            }

        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleChangePassword(e);
        }
    };

    return (
        <Container>
            <Form onSubmit={handleChangePassword}>
                <h3>Defina uma nova senha</h3>
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <div style={{ position: 'relative' }}>
                                <InputAuth
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Digite a nova senha"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyPress={handleKeyPress} 
                                    required
                                />
                                <EyeIcon onClick={() => setShowPassword(!showPassword)}>
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </EyeIcon>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <FormGroup>
                            <div style={{ position: 'relative' }}>
                                <InputAuth
                                    type={showConfirmPassword ? "text" : "password"}
                                    placeholder="Confirme a senha"
                                    value={passwordConfirmation}
                                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                    required
                                />
                                <EyeIcon onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                    <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                                </EyeIcon>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <InstructionText>
                    Escolha uma senha forte utilizando:
                    <ul>
                        <li>A senha deve conter no mínimo 8 caracteres</li>
                        <li>Caracteres especiais (@, $, #...)</li>
                        <li>Letras maiúsculas e minúsculas (A, a)</li>
                        <li>Números e letras (a123)</li>
                    </ul>
                </InstructionText>
                <Row>
                    <Col>
                        <ButtonAuth
                            color="primary"
                            type="submit"
                            onClick={handleChangePassword}
                            disabled={inProgress}
                        >
                            {inProgress ? <Spinner size="sm" /> : 'Alterar Senha'}
                            <FontAwesomeIcon icon={faKey} style={{ marginLeft: '12px' }} />
                        </ButtonAuth>
                        {error && <Alert color="danger">{error}</Alert>}
                    </Col>
                </Row>
            </Form>
           <LogoImage src={logo} alt="Logo CEDAP" />
        </Container>
    );
};

export default ReplacePassView;
