import ApiConnection from './connection';

const login = async (userData) => {
  try {
    const response = await ApiConnection.post('users/auth', userData);
    return { output: response.data, status: response.status, message: response.message };
  } catch (e) {
    return { status: e.response.status, message: e.response.data.message };
  }
};


const registerPatient = async (userData) => {
  try {
    const response = await ApiConnection.post('users/register', userData);
    return { output: response.data, status: response.status };
  } catch (error) {
    const errorMessage = error.response?.data?.error || 'Erro ao cadastrar paciente';
    throw new Error(errorMessage);
  }
};
const infoVerification = async (userData) => {
  try {
    const response = await ApiConnection.post('users/info-verify', userData);

    return response.data;
  } catch (error) {
  
    alert('Informações não encontradas, forneça os dados informados no momento de cadastro.', error.response?.data || error.message);
    throw new Error(error.response?.data?.error || 'Erro ao verificar informações');
  }
};

const sendPhoneVerification = async (tel) => {
  try {
    const response = await ApiConnection.post('users/send-sms-verification', {tel});
    return{ output: response.data, status: response.status, message: response.message }// Aqui você pode retornar alguma mensagem ou indicativo de sucesso
  } catch (error) {
    throw new Error(error.response.data.message || 'Erro ao enviar email de verificação');
  }
};

const sendMailVerification = async (email, username) => {
  try {
    const response = await ApiConnection.post('users/send-mail-verification', { email, username });
    return{ output: response.data, status: response.status, message: response.message }// Aqui você pode retornar alguma mensagem ou indicativo de sucesso
  } catch (error) {
    throw new Error(error.response.data.message || 'Erro ao enviar email de verificação');
  }
};

const sendMailRecovery = async (email, username) => {
  try {
    const response = await ApiConnection.post('users/send-mail-recovery', { email, username });
    return{ output: response.data, status: response.status, message: response.message }// Aqui você pode retornar alguma mensagem ou indicativo de sucesso
  } catch (error) {
    throw new Error(error.response.data.message || 'Erro ao enviar email de verificação');
  }
};


const checkCode = async (verificationCodeMail) => {
  try {
    // Faz a chamada para a API
    const response = await ApiConnection.post(
      'users/check-code',
      { verificationCodeMail }
    );

    return response; // Retorna a resposta inteira
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Erro ao verificar código de verificação');
  }
};
const checkVerificationCode = async (verificationCodeMail, token, verificationType) => {
  try {
    // Faz a chamada para a API
    const response = await ApiConnection.post(
      'users/check-verification-code',
      { verificationCodeMail, token, verificationType }
    );

    return response; // Retorna a resposta inteira
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Erro ao verificar código de verificação');
  }
};

const getAllByPage = async (token) => {
  try {
    const response = await ApiConnection.get('users', {
      headers: { Authorization: `Bearer ${token}` }
    });
    return { output: response.data, status: response.status };
  } catch (e) {
    return { status: e.response.status };
  }
};

const getByID = async (token, id) => {
  try {
    const response = await ApiConnection.get(`users/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return { output: response.data, status: response.status };
  } catch (e) {
    return { status: e.response.status };
  }
};

const create = async (token, userData) => {
  try {
    const response = await ApiConnection.post('users', userData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return { output: response.data, status: response.status };
  } catch (e) {
    return { status: e.response.status };
  }
};

const update = async (token, userData, userID) => {
  try {
    const response = await ApiConnection.put(`users/${userID}`, userData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return { output: response.data, status: response.status };
  } catch (e) {
    return { status: e.response.status };
  }
};

const ban = async (token, userID) => {
  try {
    const response = await ApiConnection.put(`users/${userID}/ban`, {}, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return { output: response.data, status: response.status };
  } catch (e) {
    return { status: e.response.status };
  }
};

const active = async (token, userID) => {
  try {
    const response = await ApiConnection.put(`users/${userID}/active`, {}, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return { output: response.data, status: response.status };
  } catch (e) {
    return { status: e.response.status };
  }
};

const updatePass = async (username, data, ip) => {
  try {
    // Adicionando ip no corpo da requisição (se necessário)
    const payload = { ...data, ip };

    // Fazendo a requisição ao backend
    const response = await ApiConnection.post(`users/${username}/password`, payload);
    
    // Retornando o resultado da resposta em caso de sucesso
    return { output: response.data, status: response.status };
  } catch (e) {
    // Tratando o erro e garantindo que retornamos um objeto válido
    return { status: e.response?.status || 500, message: e.response?.data?.message || 'Erro desconhecido' };
  }
};


// Buscar usuário por nome de usuário
const getUserByUserName = async (username, ip) => {
  if (username.trim() === '') {
    return {};
  }

  try {
    const response = await ApiConnection.post('users/get-user-by-username', { username, ip });
    return { output: response.data, status: response.status, message: response.message }
  } catch (error) {

    throw new Error(error.response?.data?.message || 'Erro ao buscar usuário');
  }
};

const tempBlockAccount = async (userID) => {
  try {
    const response = await ApiConnection.post('users/set-blocked', { userID });
    return { output: response.data, status: response.status };
  } catch (e) {
    return { status: e.response.status };
  }
};

export default function user() {
  return {
    login,
    tempBlockAccount,
    registerPatient,
    getByID,
    getAllByPage,
    create,
    ban,
    active,
    update,
    sendMailVerification,
    sendPhoneVerification,
    checkCode,
    updatePass,
    infoVerification,
    getUserByUserName,
    checkVerificationCode,
    sendMailRecovery
  };
}
