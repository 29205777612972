import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Spinner, FormGroup, Form, Container, Row, Col } from 'reactstrap';
import { ButtonLogin, RegisterLink, InputLogin, EyeIcon1, EyeIcon2, InstructionText } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight, faUser, faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from "react-google-recaptcha";
import api from '../../services/client/api';
import { validarTelefone,  validarData } from '../../utils/validation.js';
import moment from 'moment';
import { isCpfValid } from '../../utils/cpfValidate.js';
import cookie from '../../services/cookie';

const RegisterView = () => {
  const location = useLocation();
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tel_user, setTelefone] = useState('');
  const [dataNasc, setDataNasc] = useState('');
  const [mother_name_user, setMotherName] = useState('');
  const [cpf_user, setCpf] = useState(location.state?.cpf || '');
  const [inProgress, setInProgress] = useState(false);
  const isMountedRef = useRef(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [ip, setIp] = useState('');
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [isTelefoneValido, setIsTelefoneValido] = useState(true);


  useEffect(() => {
    const storedIp = cookie().get("userIP");
    if (storedIp) {
      setIp(storedIp);
    } else {
      alert('IP não encontrado. Por favor, tente novamente.');
    }
 
    const fetchUserData = async () => {
      if (!cpf_user) return; // Verifique se cpf_user está definido
  
      try {

        const userData = await api().patient().getPatientData({ cpf: cpf_user });
  
        // Checando se o componente ainda está montado antes de atualizar o estado
        if (isMountedRef.current) {
          if (userData) {


            // Preenchendo os campos do formulário com os dados retornados
            setName(userData.name_patient || '');
            setDataNasc(userData.birthday_patient ? moment.utc(userData.birthday_patient).format('DD/MM/YYYY') : '');
            setMotherName(userData.mother_name_patient || '');
            setEmail(userData.email_patient || '');
            setTelefone(userData.tel_patient || '');

            setIsFieldsDisabled(true);
          }
        }
      } catch {
        // Ignore qualquer tipo de erro e o fluxo segue
      }
    };
  
    if (cpf_user) {
      fetchUserData();
    }
  
    // Cleanup para evitar atualizações de estado em um componente desmontado
    return () => {
      isMountedRef.current = false; 
    };
  }, [cpf_user]);
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setInProgress(true);
    let statusCode;
    let errorMessage = '';

    if (!recaptchaToken) {
      alert('Por favor, resolva o reCAPTCHA.');
      setInProgress(false);
      return;
    }

    if (password.length < 8) {
      alert('A senha deve ter no mínimo 8 caracteres.');
      setInProgress(false);
      return;
    }
    if (username.length < 6) {
      alert('O nome de acesso deve ter no mínimo 6 caracteres.');
      setInProgress(false);
      return;
    }

    if (password !== confirmPassword) {
      alert('As senhas não coincidem. Por favor, verifique e tente novamente.');
      setInProgress(false);
      return;
    }

    if (!isCpfValid(cpf_user)) {
      alert('CPF inválido.');
      setInProgress(false);
      return;
    }

  if (!validarTelefone(tel_user)) {
    setIsTelefoneValido(false); // Marca como inválido
   alert('Número de telefone inválido. Verifique e tente novamente.');
    setInProgress(false);
    return;
  }
  setIsTelefoneValido(true); // Marca como válido quando passa


      const dataNascFormatada = moment(dataNasc, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss[Z]');
      if (!validarData(dataNasc)) {
        alert('Data inválida. Verifique o formato e os valores.');
        setInProgress(false);
        return;
      }
      

    if (!ip) {
      alert('IP não encontrado. Por favor, tente novamente.');
      setInProgress(false);
      return;
    }

    const formData = {
      name,
      username,
      email,
      password,
      birthday_user: dataNascFormatada,
      cpf_user,
      tel_user: tel_user.replace(/\D/g, ''),
      mother_name_user,
      recaptcha_token: recaptchaToken,
    };
   
    try {

      const response = await api().user().registerPatient(formData);
      statusCode = response.status;

      if (isMountedRef.current) {
        if (response.status === 201 && response.output.user ) {
          cookie().del("user_data");
          await cookie().set("username", username);
          await cookie().set("token", response.output.token);
          await cookie().set("Name", response.output.user.name);
          await cookie().set("role", response.output.user.role);
          history.replace('/');

        } else {

        }
      }
    } catch (error) {
      statusCode = error.response ? error.response.status : 'Erro de rede';
      errorMessage = error.message || 'Erro desconhecido';

      if (errorMessage.includes('Username already exists')) {
        alert('Nome de usuário já existe. Por favor, escolha outro.');
      } else if (errorMessage.includes('Dados inválidos')) {
        alert('Dados inválidos. Por favor, verifique os campos e tente novamente.');
      } else if (errorMessage.includes('Internal server error')) {
        alert('Ocorreu um erro interno no servidor. Tente novamente mais tarde.');
      } else {
        alert('Ocorreu um erro ao tentar cadastrar o paciente. Tente novamente mais tarde.');
      }
    } finally {
      if (isMountedRef.current) {
        setInProgress(false);

        const logData = {
          laudo: `Cadastro de usuário - CPF: ${cpf_user.replace(/\D/g, '')}, Status: ${statusCode}, ErrorMessage: ${errorMessage}`,
          ip: ip,
          login: username,
        };

        try {
          await api().log().saveLog(logData);
        } catch (logError) {

        }
      }
    }
  };

  const handleDateChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');

    if (value.length > 2) {
      value = `${value.slice(0, 2)}/${value.slice(2)}`;
    }
    if (value.length > 5) {
      value = `${value.slice(0, 5)}/${value.slice(5)}`;
    }

    setDataNasc(value);
  };

   const applyPhoneMask = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    const match = cleanedValue.match(/^(\d{0,2})(\d{0,5})(\d{0,4})$/);

    if (match) {
      return `${match[1] ? '(' + match[1] : ''}${match[2] ? ') ' + match[2] : ''}${match[3] ? '-' + match[3] : ''}`;
    }

    return value;
  };
 
  const applyCpfMask = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    const match = cleanedValue.match(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/);
    if (match) {
      return `${match[1]}${match[2] ? '.' + match[2] : ''}${match[3] ? '.' + match[3] : ''}${match[4] ? '-' + match[4] : ''}`;
    }

    return value;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    // Valida o telefone sempre que o valor de 'tel_user' mudar
    if (tel_user.length > 0) {
      setIsTelefoneValido(validarTelefone(tel_user)); // Verifica a validade
    } else {
      setIsTelefoneValido(true); // Se o campo estiver vazio, não marca erro
    }
  }, [tel_user]);  // O efeito será disparado sempre que 'tel_user' mudar
  

  const gerarSugestaoUsername = (nomeCompleto) => {
    if (!nomeCompleto) return ''; // Se o campo nome estiver vazio, não sugerir nada
  
    const nomes = nomeCompleto.split(' '); // Divide o nome completo em partes
    const primeiroNome = nomes[0]; // Pega o primeiro nome
    const sobrenome = nomes[nomes.length - 1]; // Pega o último sobrenome
    const primeiraLetra = primeiroNome.charAt(0).toLowerCase(); // Pega a primeira letra do primeiro nome
  
    // Retorna o formato sugerido
    return `${primeiraLetra}.${sobrenome.toLowerCase()}`;
  };
  
  // Estado para armazenar a sugestão de username
  const [sugestaoUsername, setSugestaoUsername] = useState('');
  
  // Efeito para atualizar a sugestão de username com base no nome fornecido
  useEffect(() => {
    const sugestao = gerarSugestaoUsername(name);
    setSugestaoUsername(sugestao); // Atualiza a sugestão
  }, [name]);

  const onChangeCap = (value) => {
    setRecaptchaToken(value);
  };

  return (
    <Container>
      <div style={{ marginTop: '20px', marginBottom: '20px', fontWeight: 'bold' }}>
        Dados cadastrais <FontAwesomeIcon icon={faAddressCard} />
      </div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup>
              <span style={{
                display: 'block',
                marginBottom: '0px',
                marginLeft: '20px',
                fontWeight: 650,
                fontSize: '15px',
                color: '#6c757d',
                textAlign: 'left',
              }}>
                Digite seu nome:
              </span>
              <InputLogin
                type="text"
                placeholder="Digite seu nome"
                maxLength="120"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyPress={handleKeyPress} // Adicionado aqui
                disabled={isFieldsDisabled} 
                required
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup>
              <span style={{
                display: 'block',
                marginBottom: '0px',
                marginLeft: '20px',
                fontWeight: 650,
                fontSize: '15px',
                color: '#6c757d',
                textAlign: 'left',
              }}>
                Digite seu CPF:
              </span>
              <InputLogin
                type="text"
                placeholder="Digite o CPF"
                value={applyCpfMask(cpf_user)}
                onChange={(e) => setCpf(e.target.value)}
                onKeyPress={handleKeyPress} // Adicionado aqui
                required
                disabled
                maxLength="14"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup>
              <span style={{
                display: 'block',
                marginBottom: '0px',
                marginLeft: '20px',
                fontWeight: 650,
                fontSize: '15px',
                color: '#6c757d',
                textAlign: 'left',
              }}>
                Digite sua data de nascimento:
              </span>
              <InputLogin
                type="text"
                placeholder="Data de nascimento"
                value={dataNasc}
                onChange={handleDateChange}
                onKeyPress={handleKeyPress} // Adicionado aqui
                maxLength="10"
                required
                disabled={isFieldsDisabled} 
              />
            </FormGroup>
          </Col>


          <Col xs={12} md={6}>
            <FormGroup>
              <span style={{
                display: 'block',
                marginBottom: '0px',
                marginLeft: '20px',
                fontWeight: 650,
                fontSize: '15px',
                color: '#6c757d',
                textAlign: 'left',
              }}>
              Nome completo da genitora:
              </span>
              <InputLogin
                type="text"
                placeholder="Nome da Mãe"
                maxLength="120"
                value={mother_name_user}
                onChange={(e) => setMotherName(e.target.value)}
                onKeyPress={handleKeyPress} // Adicionado aqui
                required
                disabled={isFieldsDisabled} 
              />
            </FormGroup>
          </Col>
          
          

          <Col xs={12} md={6}>
  <FormGroup>
    <span style={{
      display: 'block',
      marginBottom: '0px',
      marginLeft: '20px',
      fontWeight: 650,
      fontSize: '15px',
      color: '#6c757d',
      textAlign: 'left',
    }}>
      Número de telefone (celular com DDD):
    </span>
    <InputLogin
      type="text"
      placeholder="Digite seu telefone"
      value={applyPhoneMask(tel_user)}
      onChange={(e) => setTelefone(e.target.value)}
      onKeyPress={handleKeyPress}
      maxLength="15"
      required
      style={{
        borderColor: !isTelefoneValido && tel_user.length > 0 ? 'red' : '#ccc',  // Aplica borda vermelha se inválido e com valor
        backgroundColor: !isTelefoneValido && tel_user.length > 0 ? '#f8d7da' : 'white',  // Fundo vermelho claro se inválido
      }}
    />
  </FormGroup>
</Col>



        </Row>
        <Row>
          <Col xs={12} md={12}>
            <FormGroup>
              <span style={{
                display: 'block',
                marginBottom: '0px',
                marginLeft: '20px',
                fontWeight: 650,
                fontSize: '15px',
                color: '#6c757d',
                textAlign: 'left',
              }}>
                Digite seu endereço de e-mail:
              </span>
              <InputLogin
                type="email"
                placeholder="Digite seu email"
                value={email}
                maxLength="250"
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={handleKeyPress} // Adicionado aqui
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <div style={{ marginTop: '20px', marginBottom: '20px', fontWeight: 'bold' }}>
  Defina um nome para o login 
  <FontAwesomeIcon
    icon={faUser}
    style={{ verticalAlign: 'middle', marginLeft: '5px' }} // Alinhamento vertical ajustado
  />
</div>

<Row>
  <Col xs={12} md={12}>
    <FormGroup>
      <div
        style={{
          display: 'block', 
          marginBottom: '0px',
          marginLeft: '20px',
          fontWeight: 650,
          fontSize: '15px',
          color: '#6c757d',
          textAlign: 'left',
        }}
      >
        Defina o login:
      </div>
      
      <InputLogin
        type="text"
        maxLength="25"
        // Se a sugestão existir, coloque-a como placeholder
        placeholder={sugestaoUsername ? `Sugestão: ${sugestaoUsername}` : "Defina o login"}
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        onKeyPress={handleKeyPress}
        required
      />
      
      {/* Remover o texto extra abaixo do input */}
    </FormGroup>
  </Col>
</Row>



        <Row style={{ backgroundColor: '#e9ecef', padding: '20px', borderRadius: '5px', marginTop: '20px' }}>
          <div style={{ marginTop: '20px', marginBottom: '20px', fontWeight: 'bold', textAlign: 'center', width: '100%' }}>
            Defina uma Senha e Confirme <FontAwesomeIcon
              icon={faLock}
              style={{ verticalAlign: 'middle' }} // Alinhamento vertical ajustado
            />
          </div>

          <Row>
            <Col xs={12} md={10}>
            <FormGroup>
  <div style={{ position: 'relative' }}>
    <span style={{
      display: 'block',
      marginBottom: '0px',
      marginLeft: '20px',
      fontWeight: 650,
      fontSize: '15px',
      color: '#6c757d',
      textAlign: 'left',
    }}>
      Defina sua senha:
    </span>
    <InputLogin
      type={showPassword ? "text" : "password"}
      placeholder="Defina sua senha"
      value={password}
      maxLength="64"
      onChange={(e) => setPassword(e.target.value)}
      onKeyPress={handleKeyPress}
      required
    />
    <EyeIcon1 onClick={() => setShowPassword(!showPassword)}>
      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
    </EyeIcon1>
  </div>
</FormGroup>


            </Col>

            <Col xs={12} md={10}>
              <FormGroup>
                <div style={{ position: 'relative' }}>
                <span style={{
      display: 'block',
      marginBottom: '0px',
      marginLeft: '20px',
      fontWeight: 650,
      fontSize: '15px',
      color: '#6c757d',
      textAlign: 'left',
    }}>
                    Declare novamente a senha:
                    
                  </span> <EyeIcon2 onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                  </EyeIcon2>
                  <InputLogin
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirme sua senha"
                    value={confirmPassword}
                    maxLength="64"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyPress={handleKeyPress} // Adicionado aqui
                    required
                  />
                 
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Col xs={12}>
            <InstructionText>
              Escolha uma senha forte utilizando:
              <ul>
                <li>A senha deve conter no mínimo 8 caracteres</li>
                <li>Caracteres especiais (@, $, #...)</li>
                <li>Letras maiúsculas e minúsculas (A, a)</li>
                <li>Números e letras (a123)</li>
              </ul>
            </InstructionText>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <ReCAPTCHA
              sitekey="6LfFouQqAAAAAPpmUiTZc3SJDhlMiayo6zljuU32"
              onChange={onChangeCap}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <ButtonLogin type="submit" disabled={inProgress}>
              {inProgress ? <Spinner size="sm" color="light" /> : 'Cadastrar'}
              {!inProgress && <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '10px' }} />}
            </ButtonLogin>
          </Col>
        </Row>
      </Form>

      <Row>
        <Col>
          <RegisterLink onClick={() => { setInProgress(true); (history.replace("/login")) }}>
            <FontAwesomeIcon icon={faUser} />  Fazer Login
          </RegisterLink>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterView;
