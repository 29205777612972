import React from 'react';
import { Container, CenterDiv, LogoImage,  } from './styles.js';
import RegisterView from '../../views/Register/index.jsx';
import logo from '../../assets/LogoPos2.png';


function Register() {
  return (
    <Container>
      <CenterDiv>
        <h1>Cadastro de Usuário</h1>
        <RegisterView />
        
        <LogoImage src={logo} alt="Logo CEDAP" />
       
      </CenterDiv>
    </Container>
  );
}

export default Register;
