import React, { } from 'react';
import {  ModalContainer, StepContainer, CloseButtonDown, ButtonContainer, AcceptTerms } from './styles';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClickAccept from '../../assets/datavalid3-4.png'; // Substitua pelos caminhos das suas imagens

const StepLiveness3 = ({ onClose, onBack }) => {
 
  return (
    <ModalContainer>
        <StepContainer>
              <h4 style={{marginLeft:'10px'}}>
            Leia os termos de uso e clique em concordar
              </h4>  
            </StepContainer>
   

            <StepContainer>
   
                  <AcceptTerms
                    src={ClickAccept}
                    alt="ClickAccept"
                  />
                </StepContainer>
                <ButtonContainer>
            
                    <CloseButtonDown onClick={onClose}>
                      Continuar
                      <FontAwesomeIcon icon={faArrowAltCircleRight} style={{ marginLeft: 5 }} />
                    </CloseButtonDown>
             
                  </ButtonContainer>
                  <StepContainer>
                    <h6 onClick={onBack}>
                    <u><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Voltar instrução</u>
                    </h6>
                    </StepContainer>
                
    </ModalContainer>
    
  );
};

export default StepLiveness3;
