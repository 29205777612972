
import { Container} from './styles.js';

import ForgotPassView from '../../views/ForgotPass/index.jsx';

function ForgotPass() {
  return (
      <Container>
          
          <ForgotPassView/>
         
      </Container>
  );
}

export default ForgotPass;
