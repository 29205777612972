import styled from 'styled-components';



export const Footer = styled.footer`
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  font-size: 14px;
  color: #6c757d;
  border-top: 1px solid #ddd;
  margin-top: 50px;

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;


export const Button = styled.button`
  padding: 10px 50px;
  border-radius: 50px;
  background: ${(props) => props.active ? 'linear-gradient(268.2deg,#006EB0 -5.28%,#00AFEF 100%,#007DC3 100%)' : '#fff'};
  color: ${(props) => props.active ? '#FFF' : '#333'};
  border: 1px solid #bbb;
  margin: 0 20px 70px 20px;
  box-shadow: 3px 3px 2px rgba(0,0,0,0.09);
  font-size: ${(props) => props.active ? 23 : 18}px;
  font-weight: ${(props) => props.active ? 600 : 400};

  :focus {
    outline: 0;
  }

  @media (max-width: 990px) {
    font-size: 16px;
    margin: 0 5px 40px 5px;
    padding: 10px 20px;
  }
`;



export const Card = styled.div`
  padding: 70px;
  width: calc(100% - 140px);
  margin: 50px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
  border: 1px solid #ddd;

  h1 {
    color: #0C2B6E;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin-bottom: 30px;
  }

  @media (max-width: 990px) {
    width: 100%;
    margin: 0;
    padding: 30px;
    border-radius: 0;
    box-shadow: none;
    border: none;
  }
`;

export const Respects = styled.h3`
  display: inline-block; /* Altera para inline-block para melhor alinhamento com o Flexbox */
  margin: 0; /* Remove margin-auto para melhor alinhamento */
  margin-left:30px;
  
  @media (max-width: 990px) {
    width: 100%;
    padding: 10px;
  }
`;

export const ButtonOther = styled(Button)`
  color: white;
  padding: 8px 20px;
  border-radius: 35px;
  border: none;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 18px;
  height: 50px;
  background: linear-gradient(268.2deg, #002F59 -5.28%, #00488A 100%, #003F74 100%);
  margin-right: 30px; /* Pequena margem direita */
  margin-top: 30px;

  @media (max-width: 990px) {
    font-size: 16px;   /* Ajusta o tamanho da fonte */
    padding: 10px 15px; /* Ajusta o padding */
    margin-right: 10px;
  margin-left:30px;
  
    width: 100%;  /* Faz o botão ocupar toda a largura disponível */
    max-width: 350px; /* Limita a largura máxima */
  }
      @media (max-width: 400px) {
    font-size: 16px;   /* Ajusta o tamanho da fonte */
    padding: 10px 15px; /* Ajusta o padding */
    margin-right: 10px;
  margin-left:30px;
  height: 60px;
    width: 100%;  /* Faz o botão ocupar toda a largura disponível */
    max-width: 350px; /* Limita a largura máxima */
  }
`;



