import React, { useState, useEffect, useCallback } from 'react';
import Loader from '../../components/Loader'
import api from '../../services/client/api';
import { useHistory } from 'react-router-dom';
import cookie from '../../services/cookie';
import { deleteCookies } from '../../utils/functions';
import useBlockedIP from '../../utils/useBlockedIP.js';

function LivenessResultView() {
  const [, setValidationError] = useState(''); // Estado para armazenar o erro
  const [inProgress, setInProgress] = useState(false); // Estado para controlar o progresso
  const [cpf, setCpf] = useState('');
  const [pin, setPin] = useState('');
  const history = useHistory();
  const [ip, setIp] = useState('');
  const [isBlocked, setIsBlocked] = useState(false); // Estado para controle de bloqueio
  const [, setLoginAttempts] = useState(0); // 
  const [isMounted, setIsMounted] = useState(true);
  const [timer, setTimer] = useState(0);

  useBlockedIP(); // Verifica o IP bloqueado

  useEffect(() => {
    const savedIp = cookie().get("userIP"); 
    const blockedIp = cookie().get("blockedIP"); // Obtém o IP bloqueado do cookie
    if (savedIp) {
        setIp(savedIp); 
        if (blockedIp === savedIp) { // Verifica se o IP está bloqueado
            const blockTime = localStorage.getItem('blockTime');
            if (blockTime && new Date().getTime() < blockTime) {
                setIsBlocked(true);
                history.replace('/blocked-page'); // Redireciona para a página de bloqueio
            }
        }
    } else {
        history.replace('/search-ip'); // Redireciona para a página de busca de IP
    }
  }, [history]);

  const startBlockTimer = useCallback((lockoutTime) => {
    const now = new Date().getTime();
    const blockUntil = now + lockoutTime;
    localStorage.setItem('blockTime', blockUntil);  // Salva o tempo de bloqueio no localStorage
    cookie().set("blockedIP", ip);  // Salva o IP do usuário no cookie
    setIsBlocked(true);
    setTimer(lockoutTime / 1000);  // Define o temporizador em segundos
    history.replace('/blocked-page');  // Redireciona para a página de bloqueio
  }, [history, ip]);

  useEffect(() => {
    const checkBlock = () => {
      const blockTime = localStorage.getItem('blockTime');
      const now = new Date().getTime();
      if (blockTime && now < blockTime) {
        setIsBlocked(true);
        setTimer(Math.ceil((blockTime - now) / 1000));
        history.replace('/blocked-page');  // Redireciona para a página de bloqueio
      }
    };
    checkBlock();
    return () => {
      setIsMounted(false);
    };
  }, [history]);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer <= 1) {
            clearInterval(interval); // Limpa o intervalo
            localStorage.removeItem('blockTime'); // Limpa o tempo de bloqueio
            setIsBlocked(false); // Reinicia o bloqueio
            setLoginAttempts(0)
            localStorage.clear()
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  // Função para validar a biometria
  const validateBiometric = useCallback(async () => {
    let userIp = cookie().get('userIP'); 
  
    if (isBlocked) {
      history.replace('/blocked-page');  // Redireciona para a página de bloqueio
      return;
    }
  
    if (inProgress) return; // Evita requisições simultâneas
  
    setInProgress(true); // Marca que a requisição está em andamento
  
    try {
      if (cpf && pin) {
        const response = await api().bioconnect().getLivenessResult({
          cpf: cpf.replace(/\D/g, ''), // Remove caracteres não numéricos
          pin,
        });
    
        if (isMounted) {
          if (response?.response?.status === 'Aprovado') {
            const storedAttempts = Number(localStorage.getItem('loginAttempts')) || 0;
    
            const newLoginAttempts = storedAttempts + 1;
            localStorage.setItem('loginAttempts', newLoginAttempts); // Persiste no localStorage
            setLoginAttempts(newLoginAttempts); // Atualiza o estado de tentativas
    
            if (newLoginAttempts >= 8) {
              const lockoutTime = 1 * 60 * 1000; // 1 minuto
              startBlockTimer(lockoutTime); // Inicia o temporizador
              history.replace('/blocked-page'); // Redireciona para a página de bloqueio
            }
    
        
    
            history.replace({
              pathname: '/register',
              state: { cpf },
            });
          } else {
            setValidationError('A validação foi reprovada. Entre em contato com a equipe de suporte!'); // Exibe erro de reprovação
            window.scrollTo({
              top: document.body.scrollHeight, // A posição para onde a página deve rolar (final da página)
              behavior: 'smooth' // Animação suave de rolagem
            });
            // Atualizando tentativas de login a partir do localStorage
            const storedAttempts = Number(localStorage.getItem('loginAttempts')) || 0;
 
            const newLoginAttempts = storedAttempts + 1;
            localStorage.setItem('loginAttempts', newLoginAttempts); // Persiste no localStorage
  
            setLoginAttempts(newLoginAttempts); // Atualiza o estado de tentativas

            if (newLoginAttempts >= 8) {
              const lockoutTime = 1 * 60 * 1000; // 1 minuto
              startBlockTimer(lockoutTime); // Inicia o temporizador
              history.replace('/blocked-page'); // Redireciona para a página de bloqueio
            }
  
            alert('A validação foi reprovada. Tente novamente.');
            history.replace('/generate-pin');
          }
        }
      }
    } catch (error) {

      const storedAttempts = Number(localStorage.getItem('loginAttempts')) || 0;
      
      const newLoginAttempts = storedAttempts + 1;
      localStorage.setItem('loginAttempts', newLoginAttempts); // Persiste no localStorage
  
      setLoginAttempts(newLoginAttempts); // Atualiza o estado de tentativas

      if (newLoginAttempts >= 8) {
        const lockoutTime = 1 * 60 * 1000; // 1 minuto
        startBlockTimer(lockoutTime); // Inicia o temporizador
        history.replace('/blocked-page'); // Redireciona para a página de bloqueio
      }
      const errorMessage = error.message || 'Erro ao recuperar o resultado da validação. Por favor, tente novamente mais tarde.';

      setValidationError(errorMessage); // Exibe a mensagem de erro recebida
      alert(errorMessage); // Exibe um alerta com a mensagem
      deleteCookies();
      history.replace('/generate-pin');
    } finally {
      if (isMounted) {
  
      }
      try {
        await api().log().saveLog({
          cpf: cpf.replace(/\D/g, ''),
          laudo: `Buscar resultado da validação biometrica - CPF: ${cpf}`,
          ip: userIp,
          login: cpf,
        });
      } catch (logError) {

      }
    
      setInProgress(false); // Libera a requisição após o término
    }
  }, [cpf, pin, inProgress, history, isMounted, isBlocked, startBlockTimer]);
  
  useEffect(() => {
    const userData = localStorage.getItem('user_data'); // Obtendo os dados do localStorage
  
    if (userData) {
      const parsedData = JSON.parse(userData); // Fazendo o parse dos dados
  
      if (parsedData && parsedData.cpf && parsedData.pin) {
        setCpf(parsedData.cpf);
        setPin(parsedData.pin);
        validateBiometric(); // Chama a função de validação automaticamente
      } else {
        alert('Dados de validação biométrica não encontrados, repita o processo de biometria e tente novamente.');
        history.replace('/login');
      }
    } else {
      alert('Dados de validação biométrica não encontrados, repita o processo de biometria e tente novamente.');
      history.replace('/login');
    }
  }, [history, validateBiometric]);

  return (
    <>
      {inProgress && (
       
       <Loader/>
      )}

    </>
  );
}

export default LivenessResultView;
