import React, { useState, useEffect } from 'react';
import { ButtonNext, StepContainer, StepText, ModalWrapper, CellphoneImage, ButtonInstall, ButtonInstalled, ButtonNo, LogoDv, GoogleImg, AppleImg } from './styles';
import QRCode from 'react-qr-code';
import DataValidLogo from '../../assets/logodv.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight,  faDownload } from '@fortawesome/free-solid-svg-icons';
import GooglePlay from '../../assets/googleplay.png'; // Substitua pelos caminhos das suas imagens
import AppleStore from '../../assets/appstore.png'; // Substitua pelos caminhos das suas imagens

const DownloadDv = ({ onClose }) => {
  const [qrSize, setQrSize] = useState(130);
  const [isAppInstalled, setIsAppInstalled] = useState(null); // Estado para verificar a instalação
  const [showQrCode, setShowQrCode] = useState(false); // Estado para mostrar o QR Code
  const [showInstallButton, setShowInstallButton] = useState(false); // Estado para controlar a exibição do botão "Instalar"
  const [showYesNoButtons, setShowYesNoButtons] = useState(true); // Estado para controlar a exibição do botão "Instalar"
  const [showImages, setShowImages] = useState(true); // Controla a exibição das imagens
  const [qrLink, setQrLink] = useState(''); // Link que será mostrado no QR Code
  const [isMobileDevice, setMobileDevice] = useState(false);
  const [linkAccessed, setLinkAccessed] = useState(false); // Controla quando o link foi acessado


/*   const forceAndroidUserAgent = () => {
    const userAgent = "Mozilla/5.0 (Linux; Android 10; Pixel 4) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Mobile Safari/537.36";
    Object.defineProperty(navigator, 'userAgent', {
      value: userAgent,
      writable: false,
    });
  };
  
  // Chama a função para simular Android
  forceAndroidUserAgent();
   */
  // Agora você pode executar o código que depende do userAgent

  useEffect(() => {
    const userAgent = navigator.userAgent;
    // Verifica se é Android ou iOS
    const isMobile = /android|iPad|iPhone|iPod/i.test(userAgent);
    setMobileDevice(isMobile);
  }, []);


  const getMobileLink = () => {
    if (qrLink === 'google') {
      return "https://play.google.com/store/apps/details?id=br.gov.serpro.datavalid&hl=pt_BR&pli=1";
    } else if (qrLink === 'apple') {
      return "https://apps.apple.com/br/app/datavalid/id6502915751";
    }
    return "";
  };

  useEffect(() => {
    const updateQrSize = () => {
      if (window.innerWidth <= 480) {
        setQrSize(110);  // Tamanho maior para telas pequenas
      } else if (window.innerWidth <= 768) {
        setQrSize(120);  // Tamanho maior para telas médias
      } else {
        setQrSize(130);  // Tamanho maior para telas grandes
      }
    };

    window.addEventListener('resize', updateQrSize);
    updateQrSize();
    return () => window.removeEventListener('resize', updateQrSize);
  }, []);


  // Função para retornar o link conforme o sistema operacional
  const getDownloadLink = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      return "https://play.google.com/store/apps/details?id=br.gov.serpro.datavalid&hl=pt_BR&pli=1";
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      return "https://apps.apple.com/br/app/datavalid/id6502915751";
    } else {
      return "https://play.google.com/store/apps/details?id=br.gov.serpro.datavalid&hl=pt_BR&pli=1";
    }
  };



// funções que controlam estado
  const handleGoogleSelected = () => {
    if (isMobileDevice) {
      // Abre o link do Google Play em uma nova aba/janela
      window.open('https://play.google.com/store/apps/details?id=br.gov.serpro.datavalid&hl=pt_BR&pli=1', '_blank');
      setLinkAccessed(true);
    } else {
      // Caso não seja um dispositivo móvel, exibe o QR code
      setQrLink('google');
      setShowImages(false);
      setLinkAccessed(true); // Define que o link foi acessado
    }
  };
  
  const handleAppleSelected = () => {
    if (isMobileDevice) {
      // Abre o link da App Store em uma nova aba/janela
      window.open('https://apps.apple.com/br/app/datavalid/id6502915751', '_blank'); // Exemplo de link para a App Store
      setLinkAccessed(true);
    } else {
      // Caso não seja um dispositivo móvel, exibe o QR code
      setQrLink('apple');
      setShowImages(false);
      setLinkAccessed(true); // Define que o link foi acessado
    }
  };
  
  const handleInstallClick = () => {
    setShowQrCode(true); 
    setIsAppInstalled(false);
    setShowInstallButton(false);
  };

  const handleNoClick = () => {
    setShowInstallButton(true); 
    setShowYesNoButtons(false);
  };

  const handleAlreadyInstalledClick = () => {
    onClose(); // Chama a função de fechamento
  };
  //funções ja configuradas acima
  return (
    <ModalWrapper>
    <StepContainer style={{ textAlign: 'center', padding: '15px' }}>
      <StepContainer style={{ marginBottom: 8 }}>
        <h5 style={{ margin: 8, textAlign: 'center' }}>
          <b
            style={{
              cursor: 'pointer',
              fontSize: '24px',
              color: 'rgb(22, 35, 150)',
              marginRight: '5px',
            }}
            onClick={() => window.open(getDownloadLink(), '_blank')}
            onMouseEnter={(e) => (e.target.style.color = 'rgb(45, 72, 200)')}
            onMouseLeave={(e) => (e.target.style.color = 'rgb(20, 30, 117)')}
          >
            Datavalid <LogoDv src={DataValidLogo} alt="Logo DataValid" />
          </b>
        </h5>
      </StepContainer>

      <StepText style={{ marginBottom: '10px' }}>
        {isAppInstalled === null ? (
          <>Você já instalou o aplicativo Datavalid no seu celular?</>
        ) : linkAccessed ?  (
          <>Após o download, volte ao site para continuar.</> // Mudança de frase após acessar o link
        ) : (
          'Selecione qual seu dispositivo para baixar o app:'
        )}
      </StepText>

      {isAppInstalled === null && showYesNoButtons && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '40px',
            width: '100%',
            flexWrap: 'nowrap',
          }}
        >
          <ButtonNo onClick={handleNoClick}>Não</ButtonNo>
          <ButtonNext onClick={handleAlreadyInstalledClick}>Sim</ButtonNext>
        </div>
      )}

      {showInstallButton && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '50px' }}>
          <ButtonInstall onClick={handleInstallClick}>
            Instalar<FontAwesomeIcon icon={faDownload} style={{ fontSize: '18px', marginLeft: '5px' }} />
          </ButtonInstall>
        </div>
      )}

      {showQrCode && !isAppInstalled && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
       {/* outras partes do código */}
<CellphoneImage>
  <div className="content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    {showImages ? (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <GoogleImg
          src={GooglePlay}
          alt="GooglePlay"
          onClick={handleGoogleSelected}
        />
        <AppleImg
          src={AppleStore}
          alt="AppleStore"
          onClick={handleAppleSelected}
        />
      </div>
    ) : (
      <div>
        <StepText>Escaneie o QR code abaixo</StepText>
        <div style={{ padding: '25px', marginBottom: 30 }}>
          <QRCode
            value={getMobileLink()}
            size={qrSize}
            style={{ display: 'block' }}
          />
        </div>
      </div>
    )}
  </div>
</CellphoneImage>
{/* outras partes do código */}

          

<div>
      {isMobileDevice ? (
        <span style={{ marginTop: '10px', fontSize: '14px', color: '#555', textAlign: 'center' }}>
          Para acessar o link diretamente {' '}
          <a
            href={getDownloadLink()}
         
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            clique aqui
          </a>{' '}
        </span>
      ) : (
        <span style={{ marginTop: '10px', fontSize: '14px', color: '#555', textAlign: 'center' }}>
          Se você já instalou o aplicativo, clique no botão abaixo.
        </span>
      )}
    </div>
  
            {/* Colocando o botão fora do QR Code Box */}
            <ButtonInstalled
              onClick={handleAlreadyInstalledClick}
              style={{
                padding: '10px 15px',
                backgroundColor: '#28a745',
                color: '#fff',
                borderRadius: '20px',
                border: 'none',
                marginTop: '15px',
              }}
            >
              Já instalei
              <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '18px', marginLeft: '8px' }} />
            </ButtonInstalled>
          </div>
        )}
  
        {/* Exibe a próxima ação caso o app já esteja instalado */}
        {isAppInstalled && (
          <StepText style={{ marginTop: '10px' }}>
            Após a instalação, o processo de biometria pode ser iniciado.
          </StepText>
        )}
      </StepContainer>
    </ModalWrapper>
  
  );
  
};

export default DownloadDv;
