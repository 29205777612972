import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cookie from '../../services/cookie/index';
import { Box, Message, Timer } from './styles.js';
import api from '../../services/client/api'; // Importando a API para o log
import { deleteAllCookies } from '../../utils/functions.js';

function BlockedPageView() {
  const [timer, setTimer] = useState(0);
  const [ip, setIp] = useState(''); // Estado para o IP bloqueado
  const history = useHistory();

  useEffect(() => {
    const blockedIP = cookie().get('blockedIP');
    const userIP = cookie().get('userIP');
    const blockTime = localStorage.getItem('blockTime');
    const now = new Date().getTime();

    // Verifica se o bloqueio ainda é válido
    if (blockedIP !== userIP || !blockTime || now > blockTime) {
      localStorage.removeItem('blockTime'); // Remove o tempo de bloqueio expirado
      cookie().del('blockedIP'); // Remove o IP bloqueado
      deleteAllCookies();
      history.replace('/login'); // Redireciona ao login
    } else {
      setIp(blockedIP); // Configura o IP no estado
      setTimer(Math.ceil((blockTime - now) / 1000));

      // Log quando o IP é bloqueado
     
      // Chamada para salvar o log
      
      api().log().saveLog({
        laudo: `Usuário redirecionado para página de bloqueio, ip bloqueado: ${userIP}`,
        ip: userIP,
        login: userIP ,
      }).catch(logError => {
        console.error('Erro ao salvar log:', logError);
      });

      // Atualiza o timer
      const interval = setInterval(() => {
        const remainingTime = Math.max(0, Math.ceil((blockTime - new Date().getTime()) / 1000));
        setTimer(remainingTime);

        if (remainingTime <= 0) {
          clearInterval(interval); // Limpa o intervalo quando o tempo expirar
          localStorage.removeItem('blockTime'); // Limpa o bloqueio
          localStorage.removeItem('loginAttempts'); // Limpa as tentativas de login
          cookie().del('blockedIP');
          history.replace('/login'); // Redireciona ao login
        }
      }, 1000);

      // Limpa o intervalo quando o componente for desmontado
      return () => clearInterval(interval);
    }

    // Prevenir navegação manual para outra página
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ''; // Previne navegação sem o consentimento do código
    };

    const preventInteraction = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    // Adiciona bloqueios de interação
    document.body.addEventListener('click', preventInteraction);
    document.body.addEventListener('keydown', preventInteraction);
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove bloqueios ao desmontar o componente
    return () => {
      document.body.removeEventListener('click', preventInteraction);
      document.body.removeEventListener('keydown', preventInteraction);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    
  }, [history]);

  return (
    <Box>
      <Message>O IP {ip} foi temporariamente bloqueado devido a múltiplas tentativas.</Message>
      <Message>Aguarde até que o tempo expire para tentar novamente.</Message>
      <Timer>Tempo restante: {timer}s</Timer>
    </Box>
  );
}

export default BlockedPageView;
