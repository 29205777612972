import React, {useEffect, useState } from 'react';
import {  ModalContainer, StepContainer, CloseButtonDown, ButtonContainer, ChooseScanForm, PinHighlight } from './styles';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChooseQrCode from '../../assets/datavalid2.png'; // Substitua pelos caminhos das suas imagens
import QRCode from 'react-qr-code';

const StepLiveness1 = ({ onClose, onBack }) => {
  const [userData, setUserData] = useState(null);
  const [qrSize, setQrSize] = useState(150); // Tamanho inicial do QR code

  useEffect(() => {
    const data = localStorage.getItem('user_data'); // Obtendo os dados do localStorage
    try {
      if (data) {
        const parsedData = JSON.parse(data); // Fazendo o parse dos dados
        setUserData(parsedData); // Atualizando o estado com os dados
      }
    } catch (error) {
      console.error('Ocorreu um erro, atualize a página e tente novamente.', error);
    }
  }, []);

  useEffect(() => {
    // Ajuste do tamanho do QR code baseado na largura da tela
    const updateQrSize = () => {
      if (window.innerWidth <= 480) {
        setQrSize(110); // Menor tamanho para telas muito pequenas
      } else if (window.innerWidth <= 768) {
        setQrSize(130); // Tamanho intermediário para tablets
      } else {
        setQrSize(140); // Tamanho padrão
      }
    };

    // Adiciona o event listener para detectar a mudança de tamanho da tela
    window.addEventListener('resize', updateQrSize);

    // Chama a função de inicialização para definir o tamanho ao carregar
    updateQrSize();

    // Limpa o event listener quando o componente for desmontado
    return () => window.removeEventListener('resize', updateQrSize);
  }, []);


  if (!userData) {
    return (
      <div>
        <h4>Erro: Dados não encontrados. Por favor, atualize a página tente novamente.</h4>
      </div>
    );
  }

  if (!userData.cpf || !userData.pin || userData.cpf.trim() === '' || userData.pin.trim() === '') {
    return (
      <div>
        <h4>Erro: CPF não encontrado. Por favor, atualize a página e tente novamente.</h4>
      </div>
    );
  }

  const qrCodeData = `${userData.cpf}:${userData.pin}`;

  const copyPinToClipboard = () => {
    navigator.clipboard.writeText(userData.pin)
      .then(() => {
        alert('PIN copiado para a área de transferência!');
      })
      .catch((error) => {
        alert('Erro ao copiar o PIN: ' + error);
      });
  };

  return (
    <ModalContainer>
        <StepContainer>
              <h4 style={{marginLeft:'10px'}}>
            Você pode escolher <br /> escanear o QR Code ou digitar o PIN no seu celular
              </h4>  
            </StepContainer>
   

            <StepContainer>
   
                  <ChooseScanForm
                    src={ChooseQrCode}
                    alt="ChooseQrCode"
                  />

                           <PinHighlight>
                          <span style={{fontWeight:'700'}}>QR CODE :<br /></span>
                          <div style={{ marginTop: 5}} >
                            <QRCode value={qrCodeData} size={qrSize} title={"Escaneie o QR code na área PROVA DE VIDA no datavalid"} />
                          </div>
                          <div style={{ marginTop: 5}}>
                          <span style={{fontWeight:'700',}}>PIN: <br /></span>
                          <span className="pin-text" onClick={copyPinToClipboard}>
                            <u><strong>{userData.pin}</strong></u>
                          </span>
                          </div>
                        </PinHighlight>
            
                </StepContainer>

                  
            
                <ButtonContainer>
            
                    <CloseButtonDown onClick={onClose}>
                      Continuar
                      <FontAwesomeIcon icon={faArrowAltCircleRight} style={{ marginLeft: 5 }} />
                    </CloseButtonDown>
             
                  </ButtonContainer>
                  <StepContainer>
                    <h6 onClick={onBack}>
                    <u><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Voltar instrução</u>
                    </h6>
                    </StepContainer>
                
    </ModalContainer>
    
  );
};

export default StepLiveness1;
