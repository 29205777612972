
import React from 'react';

import { Container } from './styles';

import LivenessResultView from '../../views/LivenessResult/index.jsx';

function LivenessResult() {

  return (
    <Container>
        <LivenessResultView />
    </Container>
  );
}

export default LivenessResult;
