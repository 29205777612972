import React, { useState } from 'react';
import { Table, Spinner, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSearch, faSort, faChevronDown, faChevronUp, faHistory  } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../utils/functions';
import api from '../../services/client/api';
import cookie from '../../services/cookie/index';
import Justification from '../../components/Justification/index.jsx';  
import { useHistory } from 'react-router-dom';
import { deleteCookies } from '../../utils/functions';
import { ButtonRedFlag, ButtonOrder, ButtonShow } from './styles';

export default function ListReports({ reports }) {
  const [downloading, setDownloading] = useState({ numberReport: null });
  const [sortOrder, setSortOrder] = useState('desc');
  const [showJustification, setShowJustification] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isTableExpanded, setIsTableExpanded] = useState(true);

  let history = useHistory();
 



  const download = async (numberReport, typeReport) => {
    setDownloading({ numberReport: numberReport });

    const token = await cookie().get('token');
    const username = cookie().get('username');
    const ip = cookie().get('userIP');

    let statusCode;
    try {
      const response = await api().report().download(token, numberReport, typeReport);
      statusCode = response.status;

      if (statusCode === 401) {
        await deleteCookies();
        history.replace({ pathname: '/login' });
      }

      if (statusCode === 200) {
        window.open(response.output);
      }

    } catch (error) {
      statusCode = error.response ? error.response.status : "Erro na rede";
    }

    const logData = {
      laudo: `Download exame - Número: ${numberReport}, Tipo: ${typeReport}, Status: ${statusCode}`,
      ip: ip,
      login: username,
    };

    try {
      await api().log().saveLog(logData);
    } catch (logError) {
      // Handle log saving error
    }

    setDownloading({ numberReport: null });
  };

  if (reports.length < 1) {
    return <div>Nenhum resultado encontrado.</div>;
  }

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'desc' ? 'asc' : 'desc'));
  };

  const toggleTableVisibility = () => {
    setIsTableExpanded((prev) => !prev);
  };

  const sortedReports = reports.sort((a, b) => {
    return sortOrder === 'desc'
      ? new Date(b.request_date) - new Date(a.request_date)
      : new Date(a.request_date) - new Date(b.request_date);
  });

  const handleShowJustification = (report) => {
    setSelectedReport(report);
    setShowJustification(true);
  };

  const handleCloseJustification = () => {
    setShowJustification(false);
  };

  return (
    <div style={{ marginTop: '30px' }}>
      <h5>
        <FontAwesomeIcon icon={faSearch} /> {sortedReports.length} resultados:
      </h5>
  
      <ButtonShow color="secondary" onClick={toggleTableVisibility} style={{ marginBottom: '10px' }}>
        {isTableExpanded ? (
          <>
            Ocultar exames <FontAwesomeIcon icon={faChevronUp} />
          </>
        ) : (
          <>
            Mostrar exames <FontAwesomeIcon icon={faChevronDown} />
          </>
        )}
      </ButtonShow>
  
      {(isTableExpanded || sortedReports.length === 1) && (
        <>
          {/* Botão de ordenação movido para fora da tabela */}
          <div style={{ marginBottom: '10px' }}>
            <ButtonOrder color="info" onClick={toggleSortOrder}>
              <FontAwesomeIcon icon={faSort} /> Ordenando por{' '}
              {sortOrder === 'desc' ? 'mais recente' : 'mais antigo'}
            </ButtonOrder>
          </div>
  
          {/* Tabela */}
          <Table striped bordered responsive style={{ marginTop: '5px' }}>
  <thead>
    <tr>
      <th style={{ textAlign: 'center' }}>Data solicitação</th>
      <th style={{ textAlign: 'center' }}>Nº do Exame</th>
      <th style={{ textAlign: 'center' }}>Instituição de origem</th>
      <th style={{ textAlign: 'center' }}>Disponibilidade</th>
    </tr>
  </thead>
  <tbody>
    {sortedReports.map((report) => (
      <tr key={report.id_exam}>
        <td style={{ textAlign: 'center' }}>{formatDate(report.request_date)}</td>
        <th scope="row" style={{ textAlign: 'center' }}>{report.id_exam}</th>
        <td style={{ textAlign: 'center' }}>{report.institution}</td>
        <td style={{ textAlign: 'center' }}>
          {showJustification && selectedReport && selectedReport.id_exam === report.id_exam && (
            <Justification
              onClose={handleCloseJustification}
              cod_request={selectedReport.id_exam}
              cod_event={selectedReport.cod_event}
              cod_report={selectedReport.cod_report}
              expected_date={selectedReport.expected_date}
            />
          )}

          {report.lib_web === false ? (
            <ButtonRedFlag
              disabled={downloading.numberReport === report.id_exam}
              color="danger"
              onClick={() => handleShowJustification(report)}
            >
              {downloading.numberReport === report.id_exam ? (
                <>
                  <Spinner color="primary" /> Downloading
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faHistory } /> Status do Exame
                </>
              )}
            </ButtonRedFlag>
          ) : (
            <Button
              disabled={downloading.numberReport === report.id_exam}
              color="primary"
              onClick={() => download(report.id_exam, report.type)}
            >
              {downloading.numberReport === report.id_exam ? (
                <>
                  <Spinner color="primary" /> Downloading
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faDownload} /> Download
                </>
              )}
            </Button>
          )}
        </td>
      </tr>
    ))}
  </tbody>
</Table>

        </>
      )}
    </div>
  );
  
}
