import ApiConnection from './connection';



const getTimeLineStep = async ({ token, cod_event }) => {
  try {
    const response = await ApiConnection.post('/timeline/get-timeline-step', { cod_event: cod_event }, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    // Captura a mensagem de erro e a retorna para a interface
    if (error.response && error.response.data && error.response.data.message) {
      return error.response.data.message; // Retorna a mensagem de erro
    }
    return "Erro desconhecido. Tente novamente mais tarde."; // Caso não tenha erro esperado
  }
};

export default function timeline() {
  return {
    getTimeLineStep,
  };
}
