import styled from 'styled-components';
import { Button, Input } from 'reactstrap';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f2f5;
  padding: 20px;
  box-sizing: border-box;
  position: relative; /* Necessário para centralizar a logo corretamente */
`;

export const LogoImage = styled.img`
  position: absolute; /* Logo fixa na parte inferior */
  bottom: 10px; /* Distância da parte inferior */
  left: 50%; /* Centraliza a logo horizontalmente */
  transform: translateX(-50%); /* Centraliza a logo */
  width: 220px; /* Tamanho inicial da logo */

  /* Responsividade para telas menores */
  @media (max-width: 768px) {
    width: 180px; /* Ajusta o tamanho da logo para telas menores */
  }

  @media (max-width: 480px) {
    width: 150px; /* Ajusta o tamanho da logo para telas muito pequenas */
  }
`;

export const Form = styled.div`
  width: 100%;
  max-width: 400px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    color: #0C2B6E;
    font-family: 'Raleway', sans-serif;
    margin: 30px 0;
    font-weight: 400;
    font-size: 22px; /* Ajusta o tamanho da fonte para telas menores */
  }

  /* Ajuste dos títulos responsivos */
  @media (max-width: 768px) {
    padding: 15px; /* Diminui o padding em telas menores */
    max-width: 90%; /* Garante que o formulário ocupe mais espaço em telas menores */
  }

  @media (max-width: 480px) {
    h3 {
      font-size: 18px; /* Diminui o tamanho da fonte ainda mais em telas muito pequenas */
    }
  }
`;

export const InputAuth = styled(Input)`
  height: 50px;
  width: 100%;
  border-radius: 40px;
  text-align: left;
  padding: 10px 25px;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;

  /* Ajustes responsivos para o input */
  @media (max-width: 768px) {
    font-size: 14px; /* Diminui o tamanho da fonte para telas menores */
  }
`;

export const ButtonAuth = styled(Button)`
  width: 100%;
  padding: 10px;
  border-radius: 25px;
  font-size: 18px;
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  border: none;

  /* Ajuste da responsividade no botão */
  @media (max-width: 768px) {
    font-size: 16px; /* Diminui o tamanho da fonte do botão em telas menores */
  }
`;

export const InstructionText = styled.div`
  font-size: 14px;
  color: #555;
  margin-top: 10px;
  text-align: left;

  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: 5px;
  }

  /* Responsividade para o texto de instrução */
  @media (max-width: 768px) {
    font-size: 12px; /* Diminui o tamanho da fonte */
  }
`;

export const EyeIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #555;
`;