import React from 'react';
import {Div, Container, LeftDiv, RightDiv, Footer } from './styles';
import LoginView from '../../views/Login/index.jsx';
import bgLoginMedico from '../../assets/bg-login-medico.png';

import { deleteCookies } from '../../utils/functions';

function Login() {
 

  deleteCookies();

  return (
    
    <Div>
    <Container>
      

      <LeftDiv background={bgLoginMedico}>
        <h1>
          “<b>Excelência</b> em <b>saúde,</b><br/>
          porque sua <b>vida vale<br/>
          muito</b> para <b>nós!</b>”
        </h1>
      </LeftDiv>

      <RightDiv>
        <h1>Área Paciente</h1>
        <LoginView/>
      
      </RightDiv>

    </Container>
      <Footer>
            Precisa de ajuda? Você pode entrar em contato pelo telefone ou WhatsApp através do número <strong>+55 (47) 3422-9607</strong>.
          </Footer>
      
    </Div>
    
  );
  
}

export default Login;
