import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f0f2f5;
  padding: 20px;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 768px) {
    padding: 15px; /* Reduz o padding para telas menores */
  }

  @media (max-width: 480px) {
    padding: 10px; /* Diminui ainda mais o padding para dispositivos móveis */
    flex-direction: column; /* Garante que o layout continue na coluna */
  }
`;

