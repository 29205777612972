import styled from 'styled-components';
import { Button, Input } from 'reactstrap';
export const FormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;  // Ajustando para 90% da tela
  max-width: 800px;  // Ajuste para telas menores
  max-height: 80vh;
  overflow-y: auto;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;

  // Media query para telas menores
  @media (max-width: 768px) {
    width: 95%;
    padding: 15px;
  }
`;

export const AlertBox = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  font-weight: bold;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 8px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const SpanText = styled.span`
  display: block;
  margin-bottom: 0px;
  margin-left: 20px;
  font-weight: 650;
  font-size: 15px;
  color: #6c757d;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const InputLogin = styled(Input)`
  height: 50px;
  width: 100%;
  border-radius: 40px;
  text-align: left;
  padding: 10px 25px;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 20px;
  }
`;

export const ButtonLogin = styled(Button)`
  width: auto; /* Ajustar a largura para ser automática */
  padding: 10px 25px;
  border-radius: 40px;
  border: none;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 20px;
  height: 50px;
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  position: relative;
  margin-top: 20px; /* Espaço entre o botão e os campos de entrada */

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 8px 20px;
    width: 100%; /* Fazer o botão ocupar toda a largura em telas pequenas */
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #0C2B6E;
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
