import styled from 'styled-components';
import { Button, Input } from 'reactstrap';



export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f0f2f5;
  padding: 20px;
  box-sizing: border-box;
`;

export const Title = styled.h1`
  font-size: 35px;
  color: #0C2B6E;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 20px;
  text-align: center;  /* Centraliza o texto */
`;


export const Form = styled.div`
  width: 100%;
  max-width: 400px;
  background: #FFF;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const InputAuth = styled(Input)`
  margin-bottom: 20px;
  height: 50px;
  border-radius: 25px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;  /* Certifique-se de que o conteúdo é centralizado verticalmente */
  width: 100%;  /* Se necessário, adicione essa linha */
`;


export const ButtonAuth = styled(Button)`
  width: 70%;
  padding: 10px;
  border-radius: 25px;
  font-size: 18px;
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  border: none;
  
`;
export const ButtonFind = styled(Button)`
  width: 100%;  /* Garantir que o botão ocupe toda a largura do seu contêiner */
  max-width: 400px;  /* Limita o tamanho máximo do botão */
  padding: 10px;
  border-radius: 25px;
  font-size: 18px;
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  border: none;
  text-align: center;  /* Garante que o texto dentro do botão seja centralizado */
`;

export const RegisterLink = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  cursor: pointer;
  color: #0C2B6E;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  text-decoration: underline;

  svg {
    margin-right: 5px;
  }

  @media (max-width: 768px) {
    font-size: 16px;  /* Ajuste o tamanho da fonte para telas menores */
    margin-top: 20px;
  }
`;

export const LogoImage = styled.img`
  width: 220px; /* Largura da logo em telas grandes */

  /* Ajustes para telas menores */
  @media (max-width: 768px) {
    width: 150px; /* Ajusta o tamanho da logo para telas pequenas */
  }

  /* Centraliza a logo */
  margin-top: 25px;  /* Adiciona um espaçamento entre a logo e os links */
  align-self: center; /* Garante que a logo será centralizada no container */
`;
export const RecaptchaWrapper = styled.div`
  width: 100%;
  max-width: 400px; /* Limite de largura para telas grandes */
  margin: 0 auto; /* Centraliza o recaptcha horizontalmente */
  display: flex;
  justify-content: center; /* Garante que o recaptcha fique centralizado */
  align-items: center; /* Garante que o recaptcha fique centralizado verticalmente */
  
  /* Ajustes responsivos para telas menores */
  @media (max-width: 768px) {
    max-width: 100%; /* Permite que ocupe 100% da largura do contêiner pai em telas menores */
    margin: 10px auto; /* Centraliza com menos espaçamento em telas pequenas */
    padding: 0 20px; /* Adiciona um pequeno padding nas laterais */
  }

  iframe {
    width: 100% !important; /* Força o iframe a se ajustar 100% dentro do seu contêiner */
    max-width: 100% !important; /* Impede que o iframe ultrapasse a largura do contêiner */
    height: auto !important; /* Faz com que a altura do iframe se ajuste automaticamente */
    transform: scale(0.85); /* Escala o iframe em telas menores para evitar o corte */
    transform-origin: 0 0; /* Garante que o escalonamento comece no canto superior esquerdo */
    display: block; /* Garante que o iframe seja tratado como um bloco */
    margin: 0 auto; /* Centraliza o iframe */
  }

  /* Ajuste adicional em telas muito pequenas, se necessário */
  @media (max-width: 480px) {
    iframe {
      transform: scale(0.75); /* Escalonar ainda mais em telas muito pequenas */
      transform-origin: center center; /* Garante que a escala fique centralizada */
    }
  }
      @media (max-width: 300px) {
    iframe {
      transform: scale(0.65); /* Escalonar ainda mais em telas muito pequenas */
      transform-origin: center center; /* Garante que a escala fique centralizada */
    }
  }
`;



export const LinksContainer = styled.div`
  display: flex;
  gap: 25px;  // Ajuste o espaço entre os links conforme necessário
  margin-top: 40px;
  justify-content: center; /* Garante que os links estejam centralizados por padrão */

  @media (max-width: 768px) {
    flex-direction: column; /* Coloca os links em coluna em telas menores */
    gap: 15px;  /* Diminui o espaço entre os links quando estiverem empilhados */
  }
`;

export const LoginLink = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  cursor: pointer;
  color: #0C2B6E;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  text-decoration: underline;

  svg {
    margin-right: 5px;
  }

  @media (max-width: 768px) {
    font-size: 16px;  /* Ajuste o tamanho da fonte para telas menores */
    margin-top: 20px;
  }
`;
export const SpanText = styled.span`
  font-size: 18px;
  margin-bottom: 8px;  /* Ajusta a margem inferior para deixar um espaçamento entre o texto e o input */
  display: block; /* Faz com que o span ocupe toda a largura disponível e permita a centralização */
  text-align: center; /* Centraliza o texto horizontalmente */
  color: #333; /* Cor padrão para o texto, ajustável conforme necessário */
  font-weight: 500; /* Ajusta o peso da fonte para melhorar a legibilidade */

  @media (max-width: 768px) {
    font-size: 14px;  /* Diminui o tamanho da fonte em telas pequenas */
    margin-bottom: 6px;  /* Diminui a margem inferior em telas menores */
  }
`;
