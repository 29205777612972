import React from 'react';
import { Container, CenterDiv } from './styles.js';
import InfoVerifyView from '../../views/InfoVerify/index.jsx';



function InfoVerify() {
  return (
    <Container>
      <CenterDiv>

        <InfoVerifyView />

      </CenterDiv>
    </Container>
  );
}

export default InfoVerify;
