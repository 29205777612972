import styled from 'styled-components';
import { Button, Input } from 'reactstrap';

export const ButtonLogin = styled(Button)`
  width: auto; /* Ajustar a largura para ser automática */
  padding: 10px 25px;
  border-radius: 40px;
  border: none;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 20px;
  height: 50px;
  background: linear-gradient(268.2deg, #006EB0 -5.28%, #00AFEF 100%, #007DC3 100%);
  position: relative;
  margin-top: 20px; /* Espaço entre o botão e os campos de entrada */
  
      @media (max-width: 767px) {
      font-size: 16px;
      height: 45px;
      padding: 8px 20px;
  }
`;
export const InputLogin = styled(Input)`
  height: 50px;
  width: 100%;
  border-radius: 40px;
  text-align: left;
  padding: 10px 40px 10px 25px; /* Aumenta o padding à direita para dar espaço ao ícone */
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;


  /* Media Query para telas menores */
  @media (max-width: 768px) {
    padding-right: 35px;  /* Dá mais espaço para o ícone em telas menores */
  }

  @media (max-width: 480px) {
    padding-right: 30px;  /* Ajuste adicional para telas muito pequenas */
  }
`;


export const InputPass = styled(Input)`
  height: 50px;
  width: 100%;
  border-radius: 40px;
  text-align: left;
  padding: 10px 40px 10px 25px; /* Aumenta o padding à direita para dar espaço ao ícone */
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;

  /* Estilo para o ícone SVG diretamente no input */
  svg {
    position: fixed;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
    color: #555;
  }

  /* Media Query para telas menores */
  @media (max-width: 768px) {
    padding-right: 35px;  /* Dá mais espaço para o ícone em telas menores */
  }

  @media (max-width: 480px) {
    padding-right: 30px;  /* Ajuste adicional para telas muito pequenas */
  }
`;


export const EyeIcon1 = styled.div`
  position: absolute;
  top: 65%;
  right: 15px;  /* Ajusta a posição do ícone dentro do input */
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #555;

  /* Ajustes responsivos para telas menores */
  @media (max-width: 768px) {
    font-size: 17px;
    right: 10px; /* Ajusta a posição do ícone em telas menores */
  }

  @media (max-width: 365px) {
    top: 75%;
    font-size: 16px;
    right: 10px; /* Ajusta mais para telas pequenas */
  }
`;

export const EyeIcon2 = styled.div`
  position: absolute;
  top: 65%;
  right: 15px;  /* Ajusta a posição do ícone dentro do input */
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #555;

  /* Ajustes responsivos para telas menores */
  @media (max-width: 768px) {
  
    font-size: 17px;
    right: 10px; /* Ajusta a posição do ícone em telas menores */
  }

  @media (max-width: 365px) {
    top: 80%;
    font-size: 16px;
    right: 10px; /* Ajusta mais para telas pequenas */
  }
`;



export const RegisterLink = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  cursor: pointer;
  color: #0C2B6E;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  text-decoration: underline;

  svg {
    margin-right: 5px;
  }
`;


export const InstructionText = styled.div`
  font-size: 14px;
  color: #555;
  margin-top: 10px;
  text-align: left;

  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: 5px;
  }
`;
