import React from 'react';
import { Container} from './styles.js';
import BlockedPageView from '../../views/BlockedPage/index.jsx';

function BlockedPage() {

  return (
    <Container>
      <BlockedPageView/>
    </Container>
  );
}

export default BlockedPage;
